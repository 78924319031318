import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Appajirao = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">T. Appaji Rao</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img className="imagesb" src="../images/T_Appaji_Rao.jpg" alt="" />
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<ul className="ulstyle">
							<li>
								Registered Principle Consultant –FSMS, HACCP with NBQP- Quality
								Council of India.
							</li>
							<li>Approved Trainer for HACCP by DNV– Norway.</li>
							<li>Approved FoSTaC Trainer.</li>
							<li>FSMS Lead Auditor Trainer.</li>
							<li>Qualified FSSAI Food Hygiene Auditor in from FSSAI2021.</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Past</b>
					<ul className="ulstyle">
						<li>
							15 Years of work and over 20 years of consultancy and training
							experience in Dairy Value Chain.
						</li>
						<li>
							Consultant; Trainer; Auditor For Food Safety (Including BRC Food),
							QMS, EMS & OHSAS.
						</li>
						<li>
							Adl. G.M.(Production) of UHT Treated Milk and Aseptic Packing of
							Milk and Milk Products at AMRIT FOODS.
						</li>
						<li>
							Manager in Production ,Procurement & Sales of Milk and Milk
							Products (including packing of UHT Treated Milk and Milk.
						</li>
						<li>
							Conducted over 700 Audits as Specialist , Auditor and Lead Auditor
							for Certification Bodies.
						</li>
						<li>
							Provided Consultation to more than 250 organizations in attaining
							certifications towards ISO9001, ISO 14001, OHSAS 18001 and HACCP &
							ISO22000, FSSC22000.
						</li>
						<li>
							Provided Consultation for developing FSSAI Schedule 4 Requirements
							of GMP &GHP for Dairy industries for successful III Party Audits.
						</li>
						<li>
							Training on GMP, GHP & HACCP and auditing to the Auditors of
							Bangladesh Standards Institute, Dhaka to attain NABCB
							accreditation.
						</li>
					</ul>
					<b>Professional Qualifications</b>
					<ul className="ulstyle">
						<li>Certified Lead Auditor for Quality Management Systems.</li>
						<li>Certified Lead Auditor for Food Safety Systems-HACCP.</li>
						<li>Certified Lead Auditor for EMS 14001,OHSAS18001.</li>
						<li>Lead Auditor –Energy Management System.</li>
						<li>
							Certified Assessor for Laboratory Accreditation–ISO17025:2017&
							ILAC-G3.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Bachelor of Science Dairy Technology– 1983 from National Dairy
							Research Institute.
						</li>
					</ul>
				</div>
			</div>
			<Footer />{" "}
		</>
	);
};

export default Appajirao;
