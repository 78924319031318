import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Jobinfo() {
	const [job, setJob] = useState({});
	const [showContactModal, setShowCotactModal] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`getJobsById/${window.location.pathname.split("/")[2]}`).then(
			(res) => {
				console.log("res getAllJobsById", res.data);
				setJob(res.data.job);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			}
		);
		

	}, []);

	const handleView = async () => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		postDataAPI(`createjobseekerInterest`, { fssi: job.f_s_s_intersted }).then(
			(res, err) => {
				console.log("res inactivedoc", res.data);
				if (err) {
					dispatch({
						type: GLOBALTYPES.ALERT,
						payload: { error: err.response.data.msg },
					});
				}
				if (res.status === 200) {
					handleOpenContactModal();
				}
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				// setMycommunitiesArray(res.data.community);
			}
		);
		handleOpenContactModal();
	};

	const handleCloseContactModal = () => {
		setShowCotactModal(false);
		// setShowUploadModal(true);
	};
	const handleOpenContactModal = () => setShowCotactModal(true);
	return (
		<>
			<Navbar />
			<Modal
				show={showContactModal}
				onHide={handleCloseContactModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Contact details
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseContactModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<span style={{ fontSize: "13px" }}>Email: </span>
							<span style={{ fontWeight: "500" }}>{job.employer?.email}</span>
						</div>
						<div
							style={{
								padding: "10px",
							}}>
							<span style={{ fontSize: "13px" }}>Mobile: </span>
							<span style={{ fontWeight: "500" }}>{job.employer?.mobile}</span>
						</div>
						<div
							style={{
								padding: "10px",
							}}>
							<span style={{ fontSize: "13px" }}>Address: </span>
							<span style={{ fontWeight: "500" }}>{job.employer?.address}</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div key={job.id} className="col-12" style={{ padding: "10px" }}>
				<div className="card" style={{ padding: "5px" }}>
					<div className="row">
						<div className="col-9 listofcourses-format">
							<div
								style={{
									fontSize: "20px",
									fontWeight: "500",
									minHeight: "36px",
								}}>
								<label>{job.title}</label>
							</div>
							<div style={{ fontSize: "15px" }}>
								<div>
									Created on: <b>{job.formattedDateTime}</b>
								</div>
								<div>
									Food Safety Standard Interested: <b>{job.f_s_s_intersted}</b>
								</div>
								<div>
									Employment Type: <b>{job.employmenttype}</b>
								</div>
								<div>
									Job Type: <b>{job.jobtype}</b>
								</div>
								<div>
									Job Description <b>{job.description}</b>
								</div>
								<div>
									Work location: <b>{job.city + ", " + job.country}</b>
								</div>
								<div>
									Salary Range: <b>{job.salaryrange}</b>
								</div>
								<div>
									Description: <b>{job.description}</b>
								</div>
								<div>
									Skills: <b>{job.skills}</b>
								</div>
								<div>
									Experience Level: <b>{job.experiencelevel}</b>
								</div>
								<div>
									Deadline: <b>{job.deadline}</b>
								</div>
								<div>
									Submission link: <b>{job.submissionlink}</b>
								</div>
								<div>
									Additional Information: <b>{job.additionalinformation}</b>
								</div>
							</div>
						</div>
						<div className="col-3 listofcourses-format">
							<button
								onClick={() => handleView()}
								className="btn btn-info"
								style={{
									width: "150px",
								}}>
								View contact details
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
