import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Star from "./star";

export default function Instructorinfo() {
	const dispatch = useDispatch();

	const { profile, auth } = useSelector((state) => state);

	const [instructor, setInstructor] = useState({});
	const [show, setShow] = useState(false);
	const [feedback, setFeedback] = useState("");
	const handleClose = () => {
		setShow(false);
		setGradeIndex();
	};
	const handleShow = () => setShow(true);
	const [gradeIndex, setGradeIndex] = useState();
	const GRADES = ["Poor", "Average", "Good", "Very good", "Excellent"];
	const activeStar = {
		fill: "yellow",
	};

	const changeGradeIndex = (index) => {
		setGradeIndex(index);
	};

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		const fetchAllClasses = async () => {
			try {
				const res = await getDataAPI(
					`getInstructorsById/${window.location.pathname.split("/")[2]}`,
					auth.token
				);
				console.log("res getInstructorsById", res.data);
				setInstructor(res.data.instructor);
			} catch (err) {
				console.error("Error fetching classes:", err);
			}
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: false },
			});
		};

		if (auth.token) {
			fetchAllClasses();
		}
	}, [auth.token]);

	const handleSubmit = async (e) => {
		handleClose();
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		e.preventDefault();
		console.log(feedback);

		try {
			const res = await postDataAPI(
				"updateinstructorfeedback",
				{
					id: instructor.id,
					feedback: GRADES[gradeIndex],
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for submitting feedback");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
	};

	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<div className="js">
					<div
						className="x-style"
						onClick={handleClose}
						style={{ fontSize: "" }}>
						{" "}
						<span style={{ cursor: "pointer" }}>X</span>
					</div>
					<div className="js-boxIn">
						<div className="js-boxIn-content">
							<div className="ai-greenbox">
								<div>
									<h4 className="js-greenbox-heading">Add Feedback</h4>
								</div>
								<div>
									<form>
										{/* <div className="js-inputbox"> */}
										{/* <span className="js-inputbox-input"> */}
										{/* <input
													type="text"
													placeholder="Full Name"
													onChange={(e) => setname(e.target.value)}
												/> */}
										{/* <textarea
												className="w-100 border-none"
												rows={5}
												placeholder="Enter feedback"
												onChange={(e) =>
													setFeedback(e.target.value)
												}></textarea> */}
										{/* </span> */}
										{/* <div className="js-Icon">
												<FontAwesomeIcon icon={faUser} />
											</div> */}
										<div className="container-star">
											<h1 className="result">{GRADES[gradeIndex]}</h1>
											<div className="stars">
												{GRADES.map((grade, index) => (
													<Star
														index={index}
														key={grade}
														changeGradeIndex={changeGradeIndex}
														style={gradeIndex > index - 1 ? activeStar : {}}
													/>
												))}
											</div>
										</div>
										{/* </div> */}

										<button
											type="submit"
											className="js-submitbtn"
											onClick={handleSubmit}>
											SUBMIT
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<div className="att" style={{ padding: "0px 100px" }}>
				<div className="att-box">
					<h2>{instructor.name}</h2>
					<p>Email: {instructor.email}</p>
					<p>Mobile: {instructor.mobile}</p>
					<p>
						Courses Approved:{" "}
						{instructor.courses_approved !== undefined &&
							instructor.courses_approved !== "" &&
							JSON.parse(instructor.courses_approved).map((course, index) => {
								return index === 0
									? course.coursetitle
									: index + 1 === JSON.parse(instructor.courses_approved).length
									? course.coursetitle + "."
									: course.coursetitle + ", ";
							})}
					</p>
					<div
						className="att-heading h2 mt-3 d-flex"
						style={{ justifyContent: "space-between" }}>
						<p>Feedback</p>
						<button className="addinstructor-btn" onClick={handleShow}>
							+ ADD FEEDBACK
						</button>
					</div>
					{instructor.feedback !== null &&
						instructor.feedback?.length > 0 &&
						instructor.feedback?.map((feed, index) => {
							return (
								<p style={{ whiteSpace: "pre-line" }}>
									{index + 1}) {feed}
								</p>
							);
						})}
				</div>
			</div>
		</>
	);
}
