import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Menu.css";
import Footer from "./footer";
import Navbar from "./navbar";

const Register = () => {
	const [password, setPassword] = useState("");
	const [password1, setPassword1] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	return (
		<>
			<Navbar />
			<div id="testlogin" className="" style={{ backgroundColor: "white" }}>
				<div className="spacer"></div>
				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 blockj">
						<h1 className="heading">Account Information</h1>
						<form>
							<label className="email-label-2">
								Username <span style={{ color: "rgb(114, 28, 36)" }}>*</span>
							</label>
							<div className="email-input">
								<input className="email-input" type="email" />
							</div>

							<div className="row">
								<div className="col">
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
										}}>
										<label className="email-label-2">
											Password{" "}
											<span style={{ color: "rgb(114, 28, 36)" }}>*</span>
										</label>
										<span
											className="toggle-password d-flex align-items-center"
											onClick={() => setShowPassword(!showPassword)}>
											{showPassword ? (
												<>
													<FaEyeSlash className="me-1" />
													Hide Password
												</>
											) : (
												<>
													<FaEye className="me-1" />
													Show Password
												</>
											)}
										</span>
									</div>
									<div className="password-input">
										<input
											className="password-input"
											type={showPassword ? "text" : "password"}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</div>
								</div>
								<div className="col">
									<label className="email-label-2">
										Confirm Password{" "}
										<span style={{ color: "rgb(114, 28, 36)" }}>*</span>
									</label>
									<div className="email-input">
										<input
											className="password-input"
											type={showPassword ? "text" : "password"}
											value={password1}
											onChange={(e) => setPassword1(e.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<label className="email-label-2">
										Email Address{" "}
										<span style={{ color: "rgb(114, 28, 36)" }}>*</span>
									</label>
									<div className="email-input">
										<input className="email-input" type="email" />
									</div>
								</div>
								<div className="col">
									<label className="email-label-2">
										Confirm Email Address{" "}
										<span style={{ color: "rgb(114, 28, 36)" }}>*</span>
									</label>
									<div className="email-input">
										<input className="email-input" type="email" />
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="col-1"></div>
				</div>
				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 text-left joinj">
						<div className="d-flex align-items-center">
							<span className="Already-button">Already have an account ?</span>
							<Link to="/login">
								<button className="A-Login-button" type="button">
									Login here
								</button>
							</Link>
						</div>
					</div>
					<div className="col-1"></div>
				</div>

				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 text-left">
						<button className="Submit-button" type="button">
							Submit and Confirm
						</button>
						<div className="col-1"></div>
					</div>
				</div>
				<div className="spacer"></div>
			</div>
			<Footer />
		</>
	);
};
export default Register;
