import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const DharmeshVerma = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Dr Dharmesh Verma</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img className="imagesb" src="../images/DharmeshVerma.jpg" alt="" />
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<br />
						<b>Independent Consultant</b>
						<ul className="ulstyle">
							<li>
								Domain Expert in Basmati Rice Pre-Harvest processes,
								regenerative agriculture and machine learning for geospatial
								technology with over 25years of experience across government,
								private, and international organizations
							</li>
						</ul>
						<b>Past</b>
						<br />
						<b>Patanjali Organic Research Institute State Coordinator</b>
						<br />
						<b>Global Head-Technical</b>
						<br />
						<ul className="ulstyle">
							<li>
								CSourced Internal Customers, Stream lined business development
								and agronomic recommendations.
							</li>
							<li>
								Liaised for regulatory affairs with concerned government
								departments to bring good products under FCO.
							</li>
						</ul>
						<b>UPL Ltd- India</b>
						<br />
						<b>Senior Consultant–Remote Sensing & GIS Applications</b>
						<ul className="ulstyle">
							<li>
								Managed Basmati rice production estimation project for
								BEDF(APEDA) in five Basmati growing states of India using field
								and satellite images; delivered yield modeling using
								meteorological data and satellite image-derived indices.
							</li>
							<li>
								Expertise with a conceptual understanding of climate change,
								climates mart agriculture, organic farming, carbon
								sequestration, farmer training and multiple project management.
							</li>
							<li>
								Executed and managed pioneer projects on Basmati cultivation,
								varietal discrimination, acreage estimation and yield forecast
								for 85 districts of JK, Punjab, Haryana, Western UP and Himachal
								Pradesh.
							</li>
							<li>
								Trained several farmer trainers in organic farming including
								Basmati rice in Haryana under the NSDC project. Surveyed 30,000+
								Basmati growers in GI area over the span of a decade.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Professional Trainings/Courses</b>
					<ul className="ulstyle">
						<li>
							ERDAS-Imagine version 8.3 by Remote Sensing Instruments, Hyderabad
							at RSAC-UP, Lucknow.
						</li>
						<li>
							Statistical methods at the Institute of Applied Statistics &
							Development Studies, Lucknow.
						</li>
						<li>
							ERDAS Imagine 8.3 at Indian Institute of Remote Sensing, Dehradun.
						</li>
						<li>
							Crop modeling at Sugarcane Institute, Lucknow, conducted by CSSRI,
							Karnal.
						</li>
						<li>
							Genesis of salt-affected soils at CSSRI, Karnal, sponsored by
							UPCAR.
						</li>
					</ul>
					<b>EHonors & Awards</b>

					<ul className="ulstyle">
						<li>
							Chair, Technical Session in 7th International Conf. on
							Desert,Desertification and Dry lands.
						</li>
						<li>
							Chair, Asian Soil Partnership for Pillar-1, Global Soil
							Partnership,Food and Agriculture Organization of the United
							Nations (FAO).
						</li>
						<li>
							Outstanding Contribution Scientist, oil Conservation, Food and
							Agriculture Organization of the United Nations (FAO)
						</li>
						<li>Chair, Technical Session in 1st Global Cleanup Congress.</li>
						<li>
							Expert Panelist, NASA – South Asia Research Initiative Meeting.
						</li>
						<li>
							Chair, Technical Session – Climate Smart Agriculture & Soil.
						</li>
						<li>
							Outstanding Achievement Award – Private Sector, Agriculture &
							Innovation
						</li>
						<li>
							Chair, International Conf. on Agriculture & Applied Sciences –
							SAID
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>Ph.D.(Agricultural Chemistry).</li>
						<li>M.Sc.(Soil Survey)</li>
						<li>B.Sc.(Agriculture & Animal Husbandry).</li>
					</ul>
				</div>
			</div>
			<Footer />{" "}
		</>
	);
};

export default DharmeshVerma;
