import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const ChackoJacob = () => {
	return (
		<>
			{" "}
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Mr Chacko Jacob</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img className="imagesb" src="../images/chacko_jacob.jpg" alt="" />
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<br />
						<span>
							Co-founder and Chief Business Officer of MistEO. In this role,
							Chacko leads setting up strategic direction, overall business
							development and subject matter expert within for the agriculture
							business.
						</span>
						<br />
						<div style={{ marginTop: "10px" }}>
							<b>Past</b>
						</div>

						<span>
							Before founding MistEO, Chacko served as a Global Agribusiness
							Lead at IBM where he led the implementation of weather based
							solutions globally in the digital agriculture marketplace
							providing expertise in innovative technologies, such as precision
							agriculture, geospatial technologies and other information
							technology tools. He played a pivotal role in rolling out Watson
							Decision Support Platform for Agriculture and establishing several
							business partnerships for the IBM agriculture offerings.
						</span>
						<br />
						<ul className="ulstyle">
							<li>
								Bringing over 20 years of experience from within the agriculture
								industry, he has held various positions across the industry
								where he was responsible for implementing precision agriculture
								solutions and remote sensing technologies into major precision
								agriculture consulting firms and crop science companies.
							</li>
							<li>
								He worked in Syngenta in a global role prior to IBM. Chacko held
								senior management position at Farmers Edge where he oversaw the
								technology development and business partnerships, and assisted
								in the expansion of operations to various countries. He also
								held positions at Decisive Farming Corporation, PCI Geomatics,
								the Minnesota Department of Transportation, and Bayer Crop
								Science.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Masters of Science in Geographic Information Science (GIS) from
							the University of Minnesota.
						</li>
						<li>
							Bachelor of Science in Agriculture from the University of
							Allahabad.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ChackoJacob;
