import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function Createjob() {
	const [showJobModal, setShowJobModal] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [languages, setLanguages] = useState([]);

	return (
		<>
			<Modal
				show={showJobModal}
				onHide={() => setShowJobModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Job Posting
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer", textAlign: "right" }}
							onClick={() => setShowJobModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>

					<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
						<div style={{ fontSize: "13px" }}>Job title:</div>
						<input
							type="text"
							className="form-control"
							placeholder="Enter Course Title name"
							// onChange={(e) => setCourseTitle(e.target.value)}
						></input>
					</div>
					<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
						<div style={{ fontSize: "13px" }}>Subject:</div>
						<input
							type="text"
							className="form-control"
							placeholder="Enter Subject"
							// onChange={(e) => setSubject(e.target.value)}
						></input>
					</div>
					<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
						<div style={{ fontSize: "13px" }}>Source link:</div>
						<input
							type="text"
							className="form-control"
							placeholder="Enter Source link"
							// onChange={(e) => setSourceLink(e.target.value)}
						></input>
					</div>
					<div style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
						<div style={{ fontSize: "13px" }}>Language:</div>
						<select
							name="language"
							onChange={(e) => setSelectedLanguage(e.target.value)}>
							<option value="">Select</option>
							{languages.map((e) => {
								return (
									<option value={e.id} key={e.id}>
										{e.name}
									</option>
								);
							})}
						</select>
					</div>
					<div
						style={{
							textAlign: "center",
							paddingBottom: "10px",
							paddingTop: "20px",
						}}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							onClick={() => {
								// handlePushItemsIntoArray();
							}}>
							Upload
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Button
				style={{
					background: "#36BB7D",
					fontSize: "13px",
					borderRadius: "4px",
					border: "none",
					marginRight: "5px",
				}}
				onClick={() => setShowJobModal(true)}>
				+ Add Job Posting
			</Button>
		</>
	);
}
