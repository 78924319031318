import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const VenkatPindipolu = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Venkat Pindipolu</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/venkat-pindipolu.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<div style={{ paddingBottom: "10px" }}>
							<b>Present</b>
							<br />
							<span>
								Presently working as a co-founder for CarbonMint. CarbonMint is
								a web3 system that rewards the natural farming ecosystem, and
								helps people transition to it with incentives, better market
								access and evidence-based certification of natural farming
								operations
							</span>
							<br />
						</div>

						<b>Past</b>
						<br />
						<div style={{ paddingBottom: "10px" }}>
							<span>
								SAP Technical Architect/Consultant (IBM, CSC, Lemongrass,
								Agility Works, SAP Hybris), UK
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Technology Background</b>
					<ul className="ulstyle">
						<li>
							Over 20 years of experience in technical project management,
							architecture, infrastructure, managing & leading SAP BASIS team,
							client service delivery & engagement in a multi-client hosting
							environment, SAP Basis/Netweaver administration with migration &
							upgrades and software design.
						</li>
						<li>
							Domain and business knowledge of the following sectors – Public
							Sector, Utilities & Energy, Logistics, Manufacturing, Automotive,
							Hosting, Transport and Telecommunications.
						</li>
						<li>
							Several full life-cycle SAP implementations and OS/DB migrations.
						</li>
						<li>Database Administration – DB2, HANA, Oracle, & SQL Server.</li>
					</ul>
					<b>International Exposure</b>
					<ul className="ulstyle">
						<li>
							Worked in UK for almost 16 years at different levels in technology
							domain.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							M.Tech, Production Engineering (Indian Institute of Technology,
							Delhi, India).
						</li>
						<li>
							B.Tech, Mechanical Engineering (Kakatiya University, Warangal,
							India).
						</li>
						<li>
							Diploma, Mechanical Engineering (Government Polytechnic, Mahaboob
							Nagar, India)
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default VenkatPindipolu;
