import React, { useEffect, useState } from "react";
import { getDataAPI } from "../utils/fetchData";
import DataTable from "./datatable";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Skilldevelopement = () => {
	const [selfBody, setselfBody] = useState([]);
	const headers = [
		{
			prop: "number",
			title: "S.No.",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "coursename",
			title: "Course Name",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "standard",
			title: "Standard",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "organization",
			title: "Client Organization",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "category",
			title: "Trainee Category",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "from_date",
			title: "From",
			// isSortable: true,
			isFilterable: true,
		},
		{
			prop: "to_date",
			title: "To",
			// isSortable: true,
			isFilterable: true,
		},
		{
			prop: "state",
			title: "State",
			isSortable: true,
			isFilterable: true,
			cellProps: {
				style: (row) => ({
					maxWidth: "200px",
				}),
			},
		},
		// {
		// 	prop: "male",
		// 	title: "Male",
		// 	isSortable: true,
		// 	isFilterable: true,
		// },
		// {
		// 	prop: "female",
		// 	title: "Female",
		// 	isSortable: true,
		// 	isFilterable: true,
		// },
		{
			prop: "total",
			title: "Total Participants",
			isSortable: true,
			isFilterable: true,
		},
		// {
		// 	prop: "checkbox",
		// 	checkbox: { idProp: "name", className: "table-checkbox" },
		// 	alignment: { horizontal: "center" },
		// },
	];

	const selfHeaders = [
		{
			prop: "number",
			title: "S.No.",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "courseTitle",
			title: "Course Name",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "total",
			title: "Total Participants",
			isSortable: true,
			isFilterable: true,
		},
	];

	const body = [
		{
			number: 1,
			coursename: "FSMS",
			standard: "ISO 22000:2018",
			organization: "A.P State Organic Products Certification Authority",
			category: "Quality Manager",
			from_date: "12-Jan-2023",
			to_date: "12-Jan-2023",
			state: "Andhra Pradesh",
			male: "8",
			female: "1",
			total: "9",
		},
		{
			number: 2,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Agriculture Department",
			category: "Technical Advisor",
			from_date: "31-Jul-2023",
			to_date: "31-Jul-2023",
			state: "Andhra Pradesh",
			male: "17",
			female: "9",
			total: "26",
		},
		{
			number: 3,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Agriculture Department",
			category: "Field Officer",
			from_date: "02-Aug-2023",
			to_date: "02-Aug-2023",
			state: "Andhra Pradesh",
			male: "16",
			female: "10",
			total: "26",
		},
		{
			number: 4,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Agriculture Department",
			category: "Purchase Officer",
			from_date: "03-Aug-2023",
			to_date: "03-Aug-2023",
			state: "Andhra Pradesh",
			male: "18",
			female: "8",
			total: "26",
		},
		{
			number: 5,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Horticulture Department",
			category: "Technical Advisor",
			from_date: "07-Aug-2023",
			to_date: "07-Aug-2023",
			state: "Andhra Pradesh",
			male: "11",
			female: "1",
			total: "12",
		},
		{
			number: 6,
			coursename: "IndGAP",
			standard: "ISO 17065, ISO 19011 & HACCP",
			organization: "Agriculture Department",
			category: "Quality Manager",
			from_date: "08-Aug-2023",
			to_date: "09-Aug-2023",
			state: "Andhra Pradesh",
			male: "21",
			female: "5",
			total: "26",
		},
		{
			number: 7,
			coursename: "IndGAP",
			standard: "ISO 17065, ISO 19011 & HACCP",
			organization: "Agriculture Department",
			category: "Internal Inspector",
			from_date: "09-Aug-2023",
			to_date: "11-Aug-2023",
			state: "Andhra Pradesh",
			male: "18",
			female: "8",
			total: "26",
		},
		{
			number: 8,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Horticulture Department",
			category: "Field Officer",
			from_date: "10-Aug-2023",
			to_date: "10-Aug-2023",
			state: "Andhra Pradesh",
			male: "13",
			female: "0",
			total: "13",
		},
		{
			number: 9,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Horticulture Department",
			category: "Purchase Officer",
			from_date: "16-Aug-2023",
			to_date: "16-Aug-2023",
			state: "Andhra Pradesh",
			male: "10",
			female: "0",
			total: "10",
		},
		{
			number: 10,
			coursename: "IndGAP",
			standard: "ISO 17065, ISO 19011 & HACCP",
			organization: "Horticulture Department",
			category: "Quality Manager",
			from_date: "21-Aug-2023",
			to_date: "22-Aug-2023",
			state: "Andhra Pradesh",
			male: "10",
			female: "1",
			total: "11",
		},
		{
			number: 11,
			coursename: "IndGAP",
			standard: "ISO 17065, ISO 19011 & HACCP",
			organization: "Horticulture Department",
			category: "Internal Auditor",
			from_date: "23-Aug-2023",
			to_date: "29-Aug-2023",
			state: "Andhra Pradesh",
			male: "12",
			female: "6",
			total: "18",
		},
		{
			number: 12,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Andhra Pradesh",
			male: "11",
			female: "3",
			total: "14",
		},
		{
			// number: 13,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Chhattisgarh",
			male: "4",
			female: "0",
			total: "4",
		},
		{
			// number: 14,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Madhya Pradesh",
			male: "1",
			female: "0",
			total: "1",
		},
		{
			// number: 15,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Maharashtra",
			male: "2",
			female: "0",
			total: "2",
		},
		{
			// number: 16,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Odisha",
			male: "2",
			female: "0",
			total: "2",
		},
		{
			// number: 17,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Tamil Nadu",
			male: "1",
			female: "1",
			total: "2",
		},
		{
			// number: 18,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "10-Jun-2024",
			to_date: "14-Jun-2024",
			state: "Telangana",
			male: "5",
			female: "2",
			total: "7",
		},
		{
			number: 13,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization: "Grameen Foundation",
			category: "Women Agripreneurs",
			from_date: "23-Aug-2024",
			to_date: "27-Aug-2024",
			state: "Andhra Pradesh",
			male: "0",
			female: "50",
			total: "50",
		},
		{
			number: 14,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "25-Nov-2024",
			to_date: "29-Nov-2024",
			state: "Goa",
			male: "21",
			female: "1",
			total: "22",
		},
		{
			// number: 21,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Andhra Pradesh",
			male: "6",
			female: "1",
			total: "7",
		},
		{
			// number: 22,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Jammu & Kashmir",
			male: "1",
			female: "0",
			total: "1",
		},
		{
			// number: 23,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Maharashtra",
			male: "4",
			female: "0",
			total: "4",
		},
		{
			// number: 24,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Manipur",
			male: "2",
			female: "2",
			total: "4",
		},
		{
			// number: 25,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Tamil Nadu",
			male: "2",
			female: "0",
			total: "2",
		},
		{
			// number: 26,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Telangana",
			male: "2",
			female: "0",
			total: "2",
		},
		{
			number: 15,
			coursename: "IndGAP",
			standard: "ISO 17065",
			organization:
				"National Institute of Rural Development & Panchayati Raj Centre for Agrarian Studies (NIRD)",
			category: "Agriculture Official",
			from_date: "09-Dec-2024",
			to_date: "13-Dec-2024",
			state: "Uttarakhand",
			male: "0",
			female: "1",
			total: "1",
		},
	];

	useEffect(() => {
		getDataAPI(`getCount`).then((res) => {
			console.log("res getcoursecategory", res.data);
			setselfBody(res.data.courseregisters);
			// setCommunitiesArray(res.data.community);
		});
	}, []);

	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="tab-m" style={{}}>
				<div id="Tab">
					<ul className="nav1 nav-tabs1">
						<li className="active">
							<a href="#1" data-toggle="tab">
								Instructor Led Courses
							</a>
						</li>
						<li>
							<a href="#2" data-toggle="tab">
								Self Paced Courses
							</a>
						</li>
						{/* <li>
								<a href="#3" data-toggle="tab">
									eBooks
								</a>
							</li> */}
					</ul>
				</div>
			</div>
			<div className="tab-content ">
				<div className="tab-pane active" id="1">
					<div className="" style={{ padding: "100px 30px" }}>
						<DataTable headers={headers} body={body} />
					</div>
				</div>
				<div className="tab-pane" id="2">
					<div className="" style={{ padding: "100px 30px" }}>
						<DataTable headers={selfHeaders} body={selfBody} />
					</div>
				</div>
			</div>
			{/* <div
				className=""
				style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
				<div id="" className="" style={{}}>
					<div className="">
						<div className="row format" style={{ paddingTop: "00px" }}>
							<div className="dashboard-text">Instructor Led Development</div>

							<DataTable headers={headers} body={body} />
						</div>
					</div>
				</div>
			</div> */}
			<Footer />
		</>
	);
};
export default Skilldevelopement;
