import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF } from "../utils/fetchData";

const Attendence = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { profile, auth } = useSelector((state) => state);
	const [allClasses, setAllClasses] = useState({});
	const [selectedItems, setSelectedItems] = useState({}); // State for start session checkboxes
	const [inStockItems, setInStockItems] = useState({}); // State for end session checkboxes
	const [members, setMembers] = useState([]);
	const [instructor, setInstructor] = useState({});
	const [course, setCourse] = useState({});
	// Fetch all classes from API
	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		const fetchAllClasses = async () => {
			try {
				const res = await getDataAPI(
					`getScheduleClassById/${window.location.pathname.split("/")[2]}`,
					localStorage.getItem("access_token")
				);
				console.log("res getScheduleClassById", res.data);
				setAllClasses(res.data.scheduleclass);
				setCourse(JSON.parse(res.data.scheduleclass.course));
				setInstructor(JSON.parse(res.data.scheduleclass.instructor));
				setMembers(JSON.parse(res.data.scheduleclass.listofmembers));
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { loading: false },
				});
			} catch (err) {
				console.error("Error fetching classes:", err);
			}
		};

		// if (auth.token) {
		fetchAllClasses();
		// }
	}, []);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	return (
		<>
			<div className="att" style={{ padding: "0px 100px" }}>
				<div className="att-box">
					<h2>{allClasses.name}</h2>
					<p>Organisation: {allClasses.organisationname}</p>
					<p>Class type: {allClasses.classtype}</p>
					<p>Date and time: {allClasses.formattedDateTime}</p>
					<p>No of members: {allClasses.noofmembers}</p>
					<p>Course title: {course.coursetitle}</p>
					<p>Instructor: {instructor.name}</p>
					<p>Classroom URL: {allClasses.URL}</p>
					<p>
						Proposal:{" "}
						<span
							onClick={() => fileConversion(allClasses.proposal)}
							style={{ cursor: "pointer" }}>
							<img
								alt="pdf"
								style={{
									width: "40px",
									height: "50px",
								}}
								src="../images/PDF.svg"></img>
						</span>
					</p>
					<div className="att-heading h2 mt-3">
						<p>Attendance</p>
					</div>
					<div className="att-table">
						<table style={{ width: "100%", tableLayout: "fixed" }}>
							<thead>
								<tr
									style={{
										backgroundColor: "rgb(167, 213, 136)",
										fontSize: "15px",
										fontWeight: "700",
										letterSpacing: "0.5px",
									}}>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>S. No.</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Name</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Email</strong>
										</p>
									</th>
									{/* <th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Course</strong>
										</p>
									</th> */}
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Start Session</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>End Session</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Certificate of Completion</strong>
										</p>
									</th>
									<th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Certificate of Attendance</strong>
										</p>
									</th>
									{/* <th>
										<p style={{ textAlign: "center", paddingTop: "10px" }}>
											<strong>Percentage</strong>
										</p>
									</th> */}
								</tr>
							</thead>
							<tbody>
								{allClasses !== "" ? (
									<>
										{members.map((scheduleClasses, index) => (
											<tr
												key={index}
												style={
													index % 2 === 0 ? styles.evenRow : styles.oddRow
												}>
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													{index + 1}
												</td>
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													{scheduleClasses.Name}
												</td>
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													{scheduleClasses.Email}
												</td>
												{/* <td>{scheduleClasses.courseName}</td> */}
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													<input
														type="checkbox"
														// checked={selectedItems[scheduleClasses.id] || false} // Ensure the checkbox state is based on `selectedItems`
														checked={
															scheduleClasses.start === true ? true : false
														}
														// onClick={(e) =>
														// 	handleSelectChangeStart(
														// 		e,
														// 		scheduleClasses.id,
														// 		index
														// 	)
														// }
													/>
												</td>
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													<input
														type="checkbox"
														// disabled
														// checked={inStockItems[scheduleClasses.id] || false} // Ensure the checkbox state is based on `inStockItems`
														// onChange={() =>
														// 	handleInStockChange(scheduleClasses.id)
														// }
														checked={
															scheduleClasses.end === true ? true : false
														}
														// onClick={(e) =>
														// 	handleSelectChangeEnd(e, scheduleClasses.id, index)
														// }
													/>
												</td>
												{/* <td style={{ textAlign: "center", paddingTop: "10px" }}>
												{scheduleClasses.percentage}
											</td> */}
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													{/* {Number(scheduleClasses.percentage) >= 60
													? "Yes"
													: "No"} */}
													<input
														type="checkbox"
														checked={
															Number(scheduleClasses.percentage) >= 60
																? true
																: false
														}
													/>
												</td>
												<td style={{ textAlign: "center", paddingTop: "10px" }}>
													<input
														type="checkbox"
														checked={
															(!Number(scheduleClasses.percentage) >= 60 ||
																scheduleClasses.percentage === undefined) &&
															scheduleClasses.end === true &&
															scheduleClasses.start === true
																? true
																: false
														}
													/>
												</td>
											</tr>
										))}
										<tr>
											<td colSpan="5"></td>
											<td style={{ textAlign: "center", paddingTop: "10px" }}>
												{allClasses.completedCount}
											</td>
											<td style={{ textAlign: "center", paddingTop: "10px" }}>
												{allClasses.attendedCount}
											</td>
										</tr>
									</>
								) : (
									<tr>
										<td colSpan="5">
											No present classes available at the moment.
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

const styles = {
	evenRow: {
		backgroundColor: "#f9f9f9",
	},
	oddRow: {
		backgroundColor: "#e9e9e9", // Slightly darker color for odd rows
	},
};

export default Attendence;
