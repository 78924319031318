import React, { useEffect, useState } from "react";
import { Accordion, Card, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import BookAction from "./bookAction";
import "./communitystyles.css";
import DigitalLibraryRegister from "./digitalLibraryRegister";
import DocAction from "./docAction";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import LoginRequest from "./loginRequest";
import Navbar from "./navbar";
import VideoAction from "./videoAction";

const Digitallibrary = () => {
	const { profile, auth } = useSelector((state) => state);
	// console.log("auth", auth);
	const dispatch = useDispatch();
	const [showUploadEbooksModal, setShowUploadEbooksModal] = useState(false);
	const [showUploadDocModal, setShowUploadDocModal] = useState(false);
	const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
	const [showUploadLinkModal, setShowUploadLinkModal] = useState(false);
	const [ebBookName, setEbBookName] = useState("");
	const [ebISBN, setEbISBN] = useState("");
	// const [ebSubject, setEbSubject] = useState("");
	const [ebSource, setEbSource] = useState("");
	const [ebAuthor, setEbAuthor] = useState("");
	const [ebPublisher, setEbPublisher] = useState("");
	const [ebCost, setEbCost] = useState("");
	const [eblink, setEbLink] = useState("");
	const [ebFile, setEbFile] = useState("");
	const [docName, setDocName] = useState("");
	// const [docSubject, setDocSubject] = useState("");
	const [docSource, setDocSource] = useState("");
	const [numberOfPages, setNumberOfPages] = useState(0);
	const [docLink, setDocLink] = useState("");
	const [docFile, setDocFile] = useState("");
	const [videoName, setVideoName] = useState("");
	// const [videoSubject, setVideoSubject] = useState("");
	const [videoSource, setVideoSource] = useState("");
	const [duration, setDuration] = useState(0);
	const [videoLink, setVideoLink] = useState("");
	const [videoFile, setVideoFile] = useState("");
	const [ebBookArray, setEbookArray] = useState([]);
	const [ebBookCategoryArray, setEbookCategoryArray] = useState([]);
	const [linkName, setLinkName] = useState("");
	const [linkLink, setLinkLink] = useState("");
	const [linkSource, setLinkSource] = useState("");
	const [docArray, setDocArray] = useState([]);
	const [docCategoryArray, setDocCategoryArray] = useState([]);
	const [videoArray, setVideoArray] = useState([]);
	const [videoCategoryArray, setVideoCategoryArray] = useState([]);
	// const [linkArray, setLinkArray] = useState([]);
	const [userRole, setUserRole] = useState("");
	const [dlCategorieArray, setDlCategorieArray] = useState([]);
	const [dlSubCategorieArray, setDlSubCategorieArray] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [showCategorieModal, setShowCategorieModal] = useState(false);
	const [showSubCategorieModal, setShowSubCategorieModal] = useState(false);
	const [courseName, setCourseName] = useState("");
	const [subCourseName, setSubCourseName] = useState("");
	const [courseDescription, setCourseDescription] = useState("");
	const [subCourseDescription, setSubCourseDescription] = useState("");
	const [bookSearchValue, setBookSearchValue] = useState("");
	const [docSearchValue, setDocSearchValue] = useState("");
	const [videoSearchValue, setVideoSearchValue] = useState("");
	// const [linkSearchValue, setLinkSearchValue] = useState("");
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [language, setLanguage] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [selectedDisplayVideoLanguage, setSelectedDisplayVideoLanguage] =
		useState("");
	const [selectedDisplayDocLanguage, setSelectedDisplayDocLanguage] =
		useState("");
	const [selectedDisplayBookLanguage, setSelectedDisplayBookLanguage] =
		useState("");
	const [languages, setLanguages] = useState([]);
	const [books, setBooks] = useState([]);
	const [docs, setDocs] = useState([]);
	const [videos, setVideos] = useState([]);
	// const [links, setLinks] = useState([]);
	const [loginModel, setLoginModel] = useState(false);

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		if (auth.user) {
			getDataAPI(
				`getAllBooks/${auth.user.id}/${window.location.pathname.split("/")[2]}`,
				auth.token
			).then((res) => {
				console.log("res get books", res.data.books);
				// setCommunitiesArray(res.data.community);
				setEbookArray(res.data.books);
				setBooks(res.data.books);
				setEbookCategoryArray(res.data.categories);
			});
			getDataAPI(
				`getAllDocuments/${window.location.pathname.split("/")[2]}`,
				auth.token
			).then((res) => {
				console.log("res get docs", res.data);
				// setCommunitiesArray(res.data.community);
				setDocArray(res.data.documents);
				setDocs(res.data.documents);
				setDocCategoryArray(res.data.categories);
			});
			getDataAPI(
				`getAllVideos/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res get videos", res.data);
				// setCommunitiesArray(res.data.community);
				setVideoArray(res.data.videos);
				setVideos(res.data.videos);
				setVideoCategoryArray(res.data.categories);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			});
			// getDataAPI(
			// 	`getAllLinks/${window.location.pathname.split("/")[2]}`,
			// 	auth.token
			// ).then((res) => {
			// 	console.log("res get links", res.data);
			// 	// setCommunitiesArray(res.data.community);
			// 	setLinkArray(res.data.links);
			// 	setLinks(res.data.links);
			// });
			getDataAPI(
				`getcommunityuser/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);
			});
			getDataAPI(`getdlcategory`).then((res) => {
				console.log("dlcategoryarray", res.data.categories);
				// setCommunitiesArray(res.data.community);
				setDlCategorieArray(res.data.categories);
			});
			getDataAPI(`getlanguages`, auth.token).then((res) => {
				console.log("res getlanguages", res.data);
				// setCommunitiesArray(res.data.community);
				setLanguages(res.data.languages);
			});
		} else {
			getDataAPI(
				`getAllBooksWithoutLogin/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res get books", res.data);
				// setCommunitiesArray(res.data.community);
				setEbookArray(res.data.books);
				setBooks(res.data.books);
				setEbookCategoryArray(res.data.categories);
			});
			getDataAPI(
				`getAllDocumentsWithoutLogin/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res get docs", res.data);
				// setCommunitiesArray(res.data.community);
				setDocArray(res.data.documents);
				setDocs(res.data.documents);
				setDocCategoryArray(res.data.categories);
			});
			getDataAPI(
				`getAllVideosWithoutLogin/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res get videos", res.data);
				// setCommunitiesArray(res.data.community);
				setVideoArray(res.data.videos);
				setVideos(res.data.videos);
				setVideoCategoryArray(res.data.categories);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			});
			// getDataAPI(
			// 	`getAllLinksWithoutLogin/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
			// 	auth.token
			// ).then((res) => {
			// 	console.log("res get links", res.data);
			// 	// setCommunitiesArray(res.data.community);
			// 	setLinkArray(res.data.links);
			// 	setLinks(res.data.links);
			// });
			setUserRole("user");
			getDataAPI(`getdlcategory`).then((res) => {
				console.log("res getdlcategory", res.data);
				// setCommunitiesArray(res.data.community);
				setDlCategorieArray(res.data.categories);
			});
			getDataAPI(`getlanguages`).then((res) => {
				console.log("res getlanguages", res.data);
				// setCommunitiesArray(res.data.community);
				setLanguages(res.data.languages);
			});
		}
	}, [auth]);

	useEffect(() => {
		if (docArray.length > 0) {
			var temp = Object.groupBy(docArray, ({ subcategoryid }) => subcategoryid);
			console.log("temp", temp);
			// var temp2 = Object.groupBy(temp, ({ category }) => category);
			// console.log("temp2", temp2);
		}
	}, [docArray]);

	useEffect(() => {
		if (selectedCategory !== "") {
			getDataAPI(`getdlsubcategory/${selectedCategory}`).then((res) => {
				console.log("res getdl subcategory", res.data);
				// setCommunitiesArray(res.data.community);
				setDlSubCategorieArray(res.data.subcategories);
			});
		}
	}, [selectedCategory]);

	useEffect(() => {
		if (bookSearchValue === "") {
			setEbookArray(books);
			return;
		}
		const filterBySearch = books.filter((item) => {
			if (
				JSON.stringify(item.name)
					.toLowerCase()
					.includes(bookSearchValue.toLowerCase()) ||
				JSON.stringify(item.publisher)
					.toLowerCase()
					.includes(bookSearchValue.toLowerCase()) ||
				JSON.stringify(item.subject)
					.toLowerCase()
					.includes(bookSearchValue.toLowerCase()) ||
				JSON.stringify(item.source)
					.toLowerCase()
					.includes(bookSearchValue.toLowerCase()) ||
				JSON.stringify(item.language)
					.toLowerCase()
					.includes(bookSearchValue.toLowerCase())
			) {
				return item;
			}
		});

		setEbookArray(filterBySearch);
		// console.log("catgor", ebBookCategoryArray, dlCategorieArray)
	}, [bookSearchValue]);

	useEffect(() => {
		if (docSearchValue === "") {
			setDocArray(docs);
			return;
		}
		const filterBySearch = docs.filter((item) => {
			if (
				JSON.stringify(item.name)
					.toLowerCase()
					.includes(docSearchValue.toLowerCase()) ||
				JSON.stringify(item.subject)
					.toLowerCase()
					.includes(docSearchValue.toLowerCase()) ||
				JSON.stringify(item.source)
					.toLowerCase()
					.includes(docSearchValue.toLowerCase()) ||
				JSON.stringify(item.language)
					.toLowerCase()
					.includes(docSearchValue.toLowerCase())
			) {
				return item;
			}
		});

		setDocArray(filterBySearch);
	}, [docSearchValue]);

	useEffect(() => {
		if (videoSearchValue === "") {
			setVideoArray(videos);
			return;
		}
		const filterBySearch = videos.filter((item) => {
			if (
				JSON.stringify(item.name)
					.toLowerCase()
					.includes(videoSearchValue.toLowerCase()) ||
				JSON.stringify(item.subject)
					.toLowerCase()
					.includes(videoSearchValue.toLowerCase()) ||
				JSON.stringify(item.source)
					.toLowerCase()
					.includes(videoSearchValue.toLowerCase()) ||
				JSON.stringify(item.language)
					.toLowerCase()
					.includes(videoSearchValue.toLowerCase())
			) {
				return item;
			}
		});

		setVideoArray(filterBySearch);
	}, [videoSearchValue]);

	useEffect(() => {
		if (selectedDisplayVideoLanguage === "") {
			setVideoArray(videos);
			return;
		}
		const filterBySearch = videos.filter((item) => {
			if (item.languageid === selectedDisplayVideoLanguage) {
				return item;
			}
		});

		setVideoArray(filterBySearch);
	}, [selectedDisplayVideoLanguage]);

	useEffect(() => {
		if (selectedDisplayDocLanguage === "") {
			setDocArray(docs);
			return;
		}
		const filterBySearch = docs.filter((item) => {
			if (item.languageid === selectedDisplayDocLanguage) {
				return item;
			}
		});

		setDocArray(filterBySearch);
	}, [selectedDisplayDocLanguage]);

	useEffect(() => {
		if (selectedDisplayBookLanguage === "") {
			setEbookArray(books);
			return;
		}
		const filterBySearch = books.filter((item) => {
			if (item.languageid === selectedDisplayBookLanguage) {
				return item;
			}
		});

		setEbookArray(filterBySearch);
	}, [selectedDisplayBookLanguage]);

	// useEffect(() => {
	// 	if (linkSearchValue === "") {
	// 		setLinkArray(links);
	// 		return;
	// 	}
	// 	const filterBySearch = links.filter((item) => {
	// 		if (
	// 			JSON.stringify(item.name)
	// 				.toLowerCase()
	// 				.includes(linkSearchValue.toLowerCase()) ||
	// 			JSON.stringify(item.date)
	// 				.toLowerCase()
	// 				.includes(linkSearchValue.toLowerCase()) ||
	// 			JSON.stringify(item.language)
	// 				.toLowerCase()
	// 				.includes(linkSearchValue.toLowerCase())
	// 		) {
	// 			return item;
	// 		}
	// 	});

	// 	setLinkArray(filterBySearch);
	// }, [linkSearchValue]);

	const handleCloseLanguageModal = () => {
		setShowLanguageModal(false);
		// setShowUploadModal(true);
	};

	const handleUploadlanguage = async () => {
		console.log(language);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI(
				"createlanguage",
				{
					name: language,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setLanguages((prev) => [...prev, res.data.language]);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}

		// courseCategorieArray.push(item);
		setShowLanguageModal(false);
		// setShowUploadModal(true);
	};

	const addtomybooksclick = async (bookid) => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		console.log("bookid", bookid);
		try {
			const res = await postDataAPI(
				"createusersaved",
				{
					userid: auth.user.id,
					bookid: bookid,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				window.location.reload();
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handlePushItemIntoCourseCategorieArray = async () => {
		console.log(courseName);
dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		try {
			const res = await postDataAPI(
				"createdlcategory",
				{
					name: courseName,
					description: courseDescription,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				// setTimeout(() => {
				// 	// window.location.reload();
				// }, 1000);
				setDlCategorieArray((prev) => [...prev, res.data.category]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}

		// courseCategorieArray.push(item);
		setShowCategorieModal(false);
		// setShowUploadModal(true);
	};

	const handlePushItemIntoCourseSubCategorieArray = async () => {
		console.log(subCourseName);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI(
				"createdlsubcategory",
				{
					name: subCourseName,
					description: subCourseDescription,
					categoryid: selectedCategory,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// setTimeout(() => {
				// 	// window.location.reload();
				// }, 1000);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				setDlSubCategorieArray((prev) => [...prev, res.data.subcategory]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}

		// courseCategorieArray.push(item);
		setShowSubCategorieModal(false);
		// setShowUploadModal(true);
	};

	const addtomyvideosclick = async (videoid) => {
		if (auth.token) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: true },
			});
			console.log("videoid", videoid);
			try {
				const res = await postDataAPI(
					"createusersaved",
					{
						userid: auth.user.id,
						videoid: videoid,
					},
					auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					window.location.reload();
				}
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
				
			}
		} else {
			setLoginModel(true);
		}
	};

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	const handleCloseUploadEbooksModal = () => {
		setShowUploadEbooksModal(false);
	};
	const handleCloseDocModal = () => {
		setShowUploadDocModal(false);
	};
	const handleCloseVideoModal = () => {
		setShowUploadVideoModal(false);
	};
	const handleCloseLinkModal = () => {
		setShowUploadLinkModal(false);
	};

	const handleUploadEbook = async () => {
		handleCloseUploadEbooksModal();
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		let item = {
			ebookname: ebBookName,
			ebauthor: ebAuthor,
			ebcategory: selectedCategory,
			ebsubcategory: selectedSubCategory,
			ebcost: ebCost,
			ebisbn: ebISBN,
			ebpublisher: ebPublisher,
			// ebsubject: ebSubject,
			ebsource: ebSource,
			eblink: eblink,
			ebfile: ebFile,
			communityid: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
			language: selectedLanguage,
		};
		console.log("item", item);
		const formData = new FormData();
		formData.append("files", ebFile);

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("createbook", formData, auth.token);
			console.log("res", res.data);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		setEbookArray((prev) => [...prev, item]);
	};

	const handleUploadDoc = async () => {
		handleCloseDocModal();
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		let item = {
			name: docName,
			categoryid: selectedCategory,
			subcategoryid: selectedSubCategory,
			numberofpages: numberOfPages,
			// subject: docSubject,
			source: docSource,
			link: docLink,
			languageid: selectedLanguage,
			file: docFile,
			communityid: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
		};
		console.log("item", item);
		const formData = new FormData();
		formData.append("files", docFile);

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("createdocument", formData, auth.token);
			console.log("res", res);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		setDocArray((prev) => [...prev, item]);
	};

	const handleUploadVideo = async () => {
		handleCloseVideoModal();
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		let item = {
			name: videoName,
			categoryid: selectedCategory,
			subcategoryid: selectedSubCategory,
			// subject: videoSubject,
			source: videoSource,
			duration: duration,
			link: videoLink,
			languageid: selectedLanguage,
			// file: videoFile,
			communityid: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
		};
		console.log("item", item);
		const formData = new FormData();
		formData.append("files", videoFile);

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("createvideo", formData, auth.token);
			console.log("res", res);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		setVideoArray((prev) => [...prev, item]);
	};

	// const handleUploadLink = async () => {
	// 	handleCloseLinkModal();
	// 	dispatch({
	// 		type: GLOBALTYPES.ALERT,
	// 		payload: { loading: true },
	// 	});
	// 	let item = {
	// 		name: linkName,
	// 		link: linkLink,
	// 		source: linkSource,
	// 		languageid: selectedLanguage,
	// 		communityid: window.location.pathname.split("/")[2],
	// 	};
	// 	console.log("item", item);
	// 	const formData = new FormData();

	// 	for (var key in item) {
	// 		formData.append(
	// 			key,
	// 			typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
	// 		);
	// 	}
	// 	try {
	// 		const res = await postDataAPI("createlink", formData, auth.token);
	// 		console.log("res", res);
	// 		if (res.status === 200) {
	// 			setTimeout(() => {
	// 				window.location.reload();
	// 			}, 1000);
	// 		}
	// 	} catch (err) {
	// 		dispatch({
	// 			type: GLOBALTYPES.ALERT,
	// 			payload: { error: err.response.data.msg },
	// 		});
	// 	}
	// 	setLinkArray((prev) => [...prev, item]);
	// };

	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		setShowUploadLinkModal(true);
	};
	const FirstTab = (props) => {
		return (
			<></>
			// <div>
			// 	<Button
			// 		onClick={buttonClick}
			// 		style={{
			// 			backgroundColor: colour,
			// 			border: "none",
			// 			borderRadius: "4px",
			// 			marginTop: "-6px",
			// 			color: "#000",
			// 		}}
			// 		onMouseOver={() => {
			// 			setColour("#F4F7F9");
			// 		}}
			// 		onMouseOut={() => {
			// 			setColour("#fff");
			// 		}}>
			// 		<img src="../images/Upload.svg"></img>&nbsp;&nbsp;Upload
			// 	</Button>
			// </div>
		);
	};

	return (
		<>
			<Modal
				show={loginModel}
				onHide={() => setLoginModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<LoginRequest />
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadDocModal}
				onHide={handleCloseDocModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Files & Docs
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseDocModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 mt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Document Name"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocCategory(e.target.value)}
									/>
								</div>
							</div> */}

							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="number"
										className="form-control"
										id="copytext"
										placeholder="Number of pages"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setNumberOfPages(e.target.value)}
									/>
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocLink(e.target.value)}
									/>
								</div>
							</div>

							<div>File Source:</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="application/*"
										className="form-control"
										id="copytext"
										onChange={(e) => setDocFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadDoc()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadVideoModal}
				onHide={handleCloseVideoModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Video
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseVideoModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 mt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoCategory(e.target.value)}
									/>
								</div>
							</div> */}

							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="number"
										className="form-control"
										id="copytext"
										placeholder="Duration in minutes"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDuration(e.target.value)}
									/>
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoLink(e.target.value)}
									/>
								</div>
							</div>

							{/* <div>Source:</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="video/*"
										className="form-control"
										id="copytext"
										onChange={(e) => setVideoFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div> */}
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadVideo()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadEbooksModal}
				onHide={handleCloseUploadEbooksModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Ebook
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseUploadEbooksModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 pt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebBookName}
										placeholder="Book Name"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbBookName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebCategory}
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbCategory(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebISBN}
										placeholder="ISBN"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbISBN(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebSubject}
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebSource}
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebAuthor}
										placeholder="Author"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbAuthor(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										value={ebPublisher}
										id="copytext"
										placeholder="Publisher"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbPublisher(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										value={ebCost}
										id="copytext"
										placeholder="Cost Rs."
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbCost(e.target.value)}
									/>
								</div>
								<div style={{ fontSize: "13px", fontWeight: 500 }}>
									(Note: In case of free course, leave the cost field empty!!!)
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										id="copytext"
										onChange={(e) => setEbFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div>
							<div style={{ textAlign: "center" }}>OR</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={eblink}
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbLink(e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadEbook()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<div className=" " style={{ backgroundColor: "#ffffff" }}>
				<Navbar />
				<Homecarousel />
				<div className="tab-m" style={{}}>
					<div id="Tab">
						<ul className="nav1 nav-tabs1">
							<li className="active">
								<a href="#1" data-toggle="tab">
									Videos
								</a>
							</li>
							<li>
								<a href="#2" data-toggle="tab">
									Files & Docs
								</a>
							</li>
							<li>
								<a href="#3" data-toggle="tab">
									eBooks
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="tab-content ">
					<div className="tab-pane active" id="1">
						<div
							className="container"
							style={{ paddingBottom: "100px", paddingTop: "50px" }}>
							<div
								className="row"
								style={{
									// backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									margin: "0",
									fontWeight: "600",
									fontSize: "16px",
									// borderTopLeftRadius: "15px",
									// borderTopRightRadius: "15px",
									justifyContent: "flex-end",
								}}>
								<div
									className="col-5"
									style={{ display: "flex", alignItems: "center" }}>
									{/* Videos */}
								</div>
								<div className="col-2">
									{userRole === "Admin" && (
										<Button
											onClick={() => setShowUploadVideoModal(true)}
											style={{
												backgroundColor: "#F4F7F9",
												border: "none",
												borderRadius: "4px",
												marginTop: "-6px",
												color: "#000",
											}}
											// onMouseOver={() => {
											//   setColour("#F4F7F9");
											// }}
											// onMouseOut={() => {
											//   setColour("#fff");
											// }}
										>
											<img alt="upload" src="../images/Upload.svg"></img>
											&nbsp;&nbsp;Upload
										</Button>
									)}
								</div>
								<div
									className="col-3"
									style={{
										// width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="../images/sort_icon.svg"
													alt=""
												/>
											</button> */}
									<div className="d-flex float-end">
										<div style={{ fontSize: "13px" }}>Language:</div>
										<select
											name="language"
											style={{ marginLeft: "5px" }}
											onChange={(e) =>
												setSelectedDisplayVideoLanguage(e.target.value)
											}>
											<option value="">Select</option>
											{languages.map((e) => {
												return (
													<option value={e.id} key={e.id}>
														{e.name}
													</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="col-2 uc-searchBar">
									<input
										type="text"
										placeholder=" Search"
										onChange={(e) => setVideoSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							{videoArray.length === 0 ? (
								selectedDisplayVideoLanguage === "" ? (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF">
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
														textAlign: "center",
													}}>
													No Videos added
												</h5>
											</div>
										</ul>
									</div>
								) : (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF" style={{ textAlign: "center" }}>
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
														textAlign: "center",
													}}>
													Videos in selected language are comming soon!!
												</h5>
											</div>
										</ul>
									</div>
								)
							) : (
								<>
									{videoCategoryArray.map((c) => {
										return (
											<>
												{dlCategorieArray.map((cc) => {
													if (
														c === cc.name
														// &&
														// JSON.stringify(videoArray).includes(cc.id)
													) {
														return (
															<Accordion
																key={cc.id}
																style={{
																	marginTop: "10px",
																	marginBottom: "10px",
																}}>
																<Accordion.Item eventKey={c}>
																	<Accordion.Header
																		style={{
																			borderBottom: "1px solid #ddd",
																		}}>
																		<div
																			style={{
																				fontWeight: "500",
																				fontSize: "18px",
																			}}>
																			{c}
																		</div>
																	</Accordion.Header>

																	<Accordion.Body
																		style={{
																			padding: "15px",
																			// borderTop: "1px solid #ddd",
																			// display: "flex",
																		}}>
																		{cc.subcategories.map((s) => {
																			if (
																				JSON.stringify(videoArray).includes(
																					s.id
																				)
																			) {
																				return (
																					<Accordion
																						key={s.id}
																						style={{
																							marginTop: "10px",
																							marginBottom: "10px",
																						}}>
																						<Accordion.Item eventKey={s.name}>
																							<Accordion.Header
																								style={{
																									borderBottom:
																										"1px solid #ddd",
																								}}>
																								<div
																									style={{
																										fontWeight: 500,
																										fontSize: "18px",
																									}}>
																									{s.name}
																								</div>
																							</Accordion.Header>

																							<Accordion.Body
																								className="row col-12"
																								style={{
																									padding: "15px",
																									// borderTop:
																									// 	"1px solid #ddd",
																									// display: "flex",
																								}}>
																								{videoArray.map((e, index) => {
																									if (
																										e.subcategory.id === s.id
																									) {
																										return (
																											// 									<div
																											// 										key={e.id}
																											// 										className="col-4 mb-2 d-flex"
																											// 										style={{
																											// 											padding: "0 5px",
																											// 										}}>
																											// 										<div
																											// 											className="card1 card"
																											// 											style={{
																											// 												padding: "5px",
																											// 												justifyContent:
																											// 													"space-around",
																											// 												// margin: "5px",
																											// 												// cursor: "pointer",
																											// 												// visibility: showCard ? "visible" : "hidden",
																											// 											}}>
																											// 											{/* <img
																											// 															alt="video"
																											// 															src="https://www.youtube.com/watch?v=KxYUMKh4i4A"
																											// 															height="160px"></img> */}
																											// 											{/* <iframe
																											// 															src="https://www.youtube.com/watch?v=6jypIrxqhXo&part=snippet"
																											// 															title="video"
																											// 															height="160px"></iframe> */}
																											// 											<ReactPlayer
																											// 												width="100%"
																											// 												height="160px"
																											// 												controls={true}
																											// 												url={e.link}
																											// 											/>
																											// 											{/* <iframe
																											// 															width="100%"
																											// 															height="160px"
																											// 															src="https://www.youtube.com/embed/KxYUMKh4i4A?si=NWMHY1vwia8YUNEc"
																											// 															title="YouTube video player"
																											// 															frameborder="0"
																											// 															allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																											// 															referrerpolicy="strict-origin-when-cross-origin"
																											// 															allowFullScreen></iframe> */}
																											// 											<div>
																											// 												<span className="ebook">
																											// 													Name:
																											// 												</span>
																											// 												&nbsp;
																											// 												<span
																											// 													style={{
																											// 														textDecoration:
																											// 															"underline",
																											// 														fontWeight:
																											// 															"500",
																											// 													}}>
																											// 													{e.name}
																											// 												</span>
																											// 											</div>

																											// 											{/* <div>
																											// 														<span className="ebook">
																											// 															Language:
																											// 														</span>
																											// 														&nbsp;
																											// 														<span
																											// 															style={{
																											// 																textDecoration:
																											// 																	"underline",
																											// 																fontWeight:
																											// 																	"500",
																											// 															}}>
																											// 															{
																											// 																e.language
																											// 																	.name
																											// 															}
																											// 														</span>
																											// 													</div> */}

																											// 											{/* {e.subject !==
																											// 														"" && (
																											// 														<div>
																											// 															<span className="ebook">
																											// 																Subject:
																											// 															</span>
																											// 															&nbsp;
																											// 															<span
																											// 																style={{
																											// 																	textDecoration:
																											// 																		"underline",
																											// 																	fontWeight:
																											// 																		"500",
																											// 																}}>
																											// 																{e.subject}
																											// 															</span>
																											// 														</div>
																											// 													)} */}
																											// 											{e.source !== "" && (
																											// 												<div>
																											// 													<span className="ebook">
																											// 														Source:
																											// 													</span>
																											// 													&nbsp;
																											// 													<span
																											// 														style={{
																											// 															textDecoration:
																											// 																"underline",
																											// 															fontWeight:
																											// 																"500",
																											// 														}}>
																											// 														{e.source}
																											// 													</span>
																											// 												</div>
																											// 											)}
																											// 											<div>
																											// 												<span className="ebook">
																											// 													Duration:
																											// 												</span>
																											// 												&nbsp;
																											// 												<span
																											// 													style={{
																											// 														textDecoration:
																											// 															"underline",
																											// 														fontWeight:
																											// 															"500",
																											// 													}}>
																											// 													{e.duration} Mins
																											// 												</span>
																											// 											</div>
																											// 											{/* <div>
																											// 	<span className="ebook">cost:</span>
																											// 	&nbsp;
																											// 	<span
																											// 		style={{
																											// 			textDecoration: "underline",
																											// 			fontWeight: "500",
																											// 		}}>
																											// 		{e.cost === "" ? "Free" : e.cost}
																											// 	</span>
																											// </div> */}
																											// 											<div>
																											// 												<span className="ebook">
																											// 													Link:
																											// 												</span>
																											// 												&nbsp;
																											// 												{/* <span
																											// 		style={{
																											// 			textDecoration: "underline",
																											// 			fontWeight: "500",
																											// 		}}
																											// 	>
																											// 		{e.link}
																											// 	</span> */}
																											// 												<a
																											// 													href={
																											// 														(e.link?.includes(
																											// 															"https://"
																											// 														)
																											// 															? ""
																											// 															: "http://") +
																											// 														e.link
																											// 													}
																											// 													rel="no-referer noreferrer"
																											// 													target="_blank">
																											// 													{e.link}
																											// 												</a>
																											// 											</div>

																											// 											<div className="d-flex justify-content-center flex-column mt-2">
																											// 												<Button
																											// 													style={{
																											// 														background:
																											// 															"#36BB7D",
																											// 														fontSize:
																											// 															"13px",
																											// 														borderRadius:
																											// 															"4px",
																											// 														border: "none",
																											// 													}}
																											// 													onClick={() =>
																											// 														addtomyvideosclick(
																											// 															e.id
																											// 														)
																											// 													}>
																											// 													{/* <img
																											// alt="video"
																											// src="../images/Go-to-My-Courses-icon.svg"></img>{" "} */}
																											// 													{/* Play Video */}
																											// 													Add to my videos
																											// 												</Button>
																											// 												{userRole ===
																											// 													"Admin" && (
																											// 													<VideoAction
																											// 														video={e}
																											// 														categoriesArray={
																											// 															dlCategorieArray
																											// 														}
																											// 														languagesArray={
																											// 															languages
																											// 														}
																											// 														languages={() =>
																											// 															setShowLanguageModal(
																											// 																true
																											// 															)
																											// 														}
																											// 														categories={() =>
																											// 															setShowCategorieModal(
																											// 																true
																											// 															)
																											// 														}
																											// 														subcategories={() =>
																											// 															setShowSubCategorieModal(
																											// 																true
																											// 															)
																											// 														}
																											// 													/>
																											// 												)}
																											// 											</div>
																											// 										</div>
																											// 									</div>
																											<div className="col-3">
																												<Card
																													id=""
																													className="fadeup d-flex flex-column"
																													style={{
																														width: "100%",
																														height: "100%",
																													}}>
																													{/* <iframe
																														disabled="true"
																														width="100%"
																														height="215"
																														src={e.link}
																														title="YouTube video player"
																														frameborder="0"
																														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																														allowfullscreen="false"></iframe> */}
																													<ReactPlayer
																														width="100%"
																														height="160px"
																														controls={false}
																														url={e.link}
																													/>
																													<Card.Body className="digital-text">
																														<div>
																															<span
																																style={{
																																	fontWeight:
																																		"600",
																																	fontSize:
																																		"15px",
																																}}>
																																{" "}
																																{e.name}
																															</span>
																														</div>
																														<div>
																															Source:
																															<span>
																																{" "}
																																{e.source}
																															</span>
																														</div>
																														<div>
																															Duration:
																															<span>
																																{" "}
																																{e.duration}
																																Mins
																															</span>
																														</div>
																														<div>
																															Link:
																															{/* <a
																															href="www.google.com"
																															style={{
																																textDecoration:
																																	"none",
																															}}>
																															&nbsp;www.google.com
																														</a> */}{" "}
																															<DigitalLibraryRegister
																																link={e.link}
																																courseid={e.id}
																																type="video"
																															/>
																														</div>
																														{e.isVideoSaved ===
																															false && (
																															<Button
																																style={{
																																	background:
																																		"#36BB7D",
																																	fontSize:
																																		"13px",
																																	borderRadius:
																																		"4px",
																																	border:
																																		"none",
																																	marginTop:
																																		"5px",
																																}}
																																onClick={() =>
																																	addtomyvideosclick(
																																		e.id
																																	)
																																}>
																																{/* <img
																											alt="video"
																											src="../images/Go-to-My-Courses-icon.svg"></img>{" "} */}
																																{/* Play Video */}
																																Add to my videos
																															</Button>
																														)}
																														{userRole ===
																															"Admin" && (
																															<VideoAction
																																video={e}
																																categoriesArray={
																																	dlCategorieArray
																																}
																																languagesArray={
																																	languages
																																}
																																languages={() =>
																																	setShowLanguageModal(
																																		true
																																	)
																																}
																																categories={() =>
																																	setShowCategorieModal(
																																		true
																																	)
																																}
																																subcategories={() =>
																																	setShowSubCategorieModal(
																																		true
																																	)
																																}
																															/>
																														)}
																													</Card.Body>
																												</Card>
																											</div>
																										);
																									}
																								})}
																							</Accordion.Body>
																						</Accordion.Item>
																					</Accordion>
																				);
																			}
																		})}
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>
														);
													}
												})}
											</>
										);
									})}
								</>
							)}
						</div>
					</div>

					<div className="tab-pane" id="2">
						<div
							className="container"
							style={{ paddingBottom: "100px", paddingTop: "50px" }}>
							<div
								className="row"
								style={{
									// backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									margin: "0",
									fontWeight: "600",
									fontSize: "16px",
									// borderTopLeftRadius: "15px",
									// borderTopRightRadius: "15px",
									justifyContent: "flex-end",
								}}>
								<div
									className="col-5"
									style={{ display: "flex", alignItems: "center" }}>
									{/* Files & Docs */}
								</div>
								<div className="col-2">
									{userRole === "Admin" && (
										<Button
											onClick={() => setShowUploadDocModal(true)}
											style={{
												backgroundColor: "#F4F7F9",
												border: "none",
												borderRadius: "4px",
												marginTop: "-6px",
												color: "#000",
											}}
											// onMouseOver={() => {
											//   setColour("#F4F7F9");
											// }}
											// onMouseOut={() => {
											//   setColour("#fff");
											// }}
										>
											<img alt="upload" src="../images/Upload.svg"></img>
											&nbsp;&nbsp;Upload
										</Button>
									)}
								</div>
								<div
									className="col-3"
									style={{
										// width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="../images/sort_icon.svg"
													alt=""
												/>
											</button> */}
									<div className="d-flex float-end">
										<div style={{ fontSize: "13px" }}>Language:</div>
										<select
											name="language"
											style={{ marginLeft: "5px" }}
											onChange={(e) =>
												setSelectedDisplayDocLanguage(e.target.value)
											}>
											<option value="">Select</option>
											{languages.map((e) => {
												return (
													<option value={e.id} key={e.id}>
														{e.name}
													</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="col-2 uc-searchBar">
									<input
										type="text"
										placeholder=" Search"
										onChange={(e) => setDocSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							{/* <Accordion
								style={{
									marginTop: "10px",
									marginBottom: "10px",
								}}>
								<Accordion.Item>
									<Accordion.Header style={{}}>
										<div> Horticulture</div>
									</Accordion.Header>

									<Accordion.Body className="row" style={{}}>
										<Accordion
											style={{
												marginBottom: "10px",
											}}>
											<Accordion.Item>
												<Accordion.Header style={{}}>
													<div>GINGER POP</div>
												</Accordion.Header>

												<Accordion.Body className="" style={{}}>
													<div className="row">
														<div className="col-3">PDF</div>
														<div className="col-3">PDF</div>
														<div className="col-3">PDF</div>
														<div className="col-3">PDF</div>
													</div>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion> */}

							{docArray.length === 0 ? (
								selectedDisplayDocLanguage === "" ? (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF">
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
														textAlign: "center",
													}}>
													No Files & Docs added
												</h5>
												{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
											</div>
										</ul>
									</div>
								) : (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF" style={{ textAlign: "center" }}>
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
														textAlign: "center",
													}}>
													Files & Docs in selected language are comming soon!!
												</h5>
												{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
											</div>
										</ul>
									</div>
								)
							) : (
								<>
									{docCategoryArray.map((c) => {
										return (
											<>
												{dlCategorieArray.map((cc) => {
													if (
														c === cc.name
														// &&
														// JSON.stringify(docArray).includes(cc.id)
													) {
														return (
															<Accordion
																key={cc.id}
																style={{
																	marginTop: "10px",
																	marginBottom: "10px",
																}}>
																<Accordion.Item eventKey={c}>
																	<Accordion.Header
																		style={{
																			borderBottom: "1px solid #ddd",
																		}}>
																		<div
																			style={{
																				fontWeight: "500",
																				fontSize: "18px",
																			}}>
																			{c}
																		</div>
																	</Accordion.Header>

																	<Accordion.Body
																		style={{
																			padding: "15px",
																			// borderTop: "1px solid #ddd",
																			// display: "flex",
																		}}>
																		{cc.subcategories.map((s) => {
																			if (
																				JSON.stringify(docArray).includes(s.id)
																			) {
																				return (
																					<Accordion
																						key={s.id}
																						style={{
																							marginTop: "10px",
																							marginBottom: "10px",
																						}}>
																						<Accordion.Item eventKey={s.name}>
																							<Accordion.Header
																								style={{
																									borderBottom:
																										"1px solid #ddd",
																								}}>
																								<div
																									style={{
																										fontWeight: 500,
																										fontSize: "18px",
																									}}>
																									{s.name}
																								</div>
																							</Accordion.Header>

																							<Accordion.Body
																								className="row col-12"
																								style={{
																									padding: "15px",
																									// borderTop:
																									// 	"1px solid #ddd",
																									display: "flex",
																								}}>
																								{docArray.map((e, index) => {
																									if (
																										e.subcategory.id === s.id
																									) {
																										return (
																											// 									<div
																											// 										key={e.id}
																											// 										style={{
																											// 											padding: "5px",
																											// 										}}>
																											// 										<div
																											// 											className="card"
																											// 											style={{
																											// 												padding: "5px",
																											// 											}}>
																											// 											<div className="row">
																											// 												<div
																											// 													className="col-3"
																											// 													style={{
																											// 														display: "flex",
																											// 														alignItems:
																											// 															"center",
																											// 														justifyContent:
																											// 															"center",
																											// 													}}>
																											// 													<img
																											// 														src="../images/Downloads_Links-Thumb.svg"
																											// 														alt=""
																											// 													/>
																											// 												</div>
																											// 												<div className="col-7">
																											// 													<div>
																											// 														<span className="ebook">
																											// 															Name:
																											// 														</span>
																											// 														&nbsp;
																											// 														<span
																											// 															style={{
																											// 																textDecoration:
																											// 																	"underline",
																											// 																fontWeight:
																											// 																	"500",
																											// 															}}>
																											// 															{e.name}
																											// 														</span>
																											// 													</div>
																											// 													{/* <div>
																											// 															<span className="ebook">
																											// 																Language:
																											// 															</span>
																											// 															&nbsp;
																											// 															<span
																											// 																style={{
																											// 																	textDecoration:
																											// 																		"underline",
																											// 																	fontWeight:
																											// 																		"500",
																											// 																}}>
																											// 																{
																											// 																	e.language
																											// 																		.name
																											// 																}
																											// 															</span>
																											// 														</div> */}
																											// 													{/* <div>
																											// 																		<span className="ebook">
																											// 																			Category:
																											// 																		</span>
																											// 																		&nbsp;
																											// 																		<span
																											// 																			style={{
																											// 																				textDecoration:
																											// 																					"underline",
																											// 																				fontWeight:
																											// 																					"500",
																											// 																			}}>
																											// 																			{
																											// 																				e
																											// 																					.category
																											// 																					.name
																											// 																			}
																											// 																		</span>
																											// 																	</div> */}

																											// 													{/* {e.subject !==
																											// 															"" && (
																											// 															<div>
																											// 																<span className="ebook">
																											// 																	Subject:
																											// 																</span>
																											// 																&nbsp;
																											// 																<span
																											// 																	style={{
																											// 																		textDecoration:
																											// 																			"underline",
																											// 																		fontWeight:
																											// 																			"500",
																											// 																	}}>
																											// 																	{
																											// 																		e.subject
																											// 																	}
																											// 																</span>
																											// 															</div>
																											// 														)} */}
																											// 													{e.source !==
																											// 														"" && (
																											// 														<div>
																											// 															<span className="ebook">
																											// 																Source:
																											// 															</span>
																											// 															&nbsp;
																											// 															<span
																											// 																style={{
																											// 																	textDecoration:
																											// 																		"underline",
																											// 																	fontWeight:
																											// 																		"500",
																											// 																}}>
																											// 																{e.source}
																											// 															</span>
																											// 														</div>
																											// 													)}
																											// 													<div>
																											// 														<span className="ebook">
																											// 															No. of pages:
																											// 														</span>
																											// 														&nbsp;
																											// 														<span
																											// 															style={{
																											// 																textDecoration:
																											// 																	"underline",
																											// 																fontWeight:
																											// 																	"500",
																											// 															}}>
																											// 															{
																											// 																e.numberofpages
																											// 															}
																											// 														</span>
																											// 													</div>
																											// 													{/* <div>
																											// 	<span className="ebook">cost:</span>
																											// 	&nbsp;
																											// 	<span
																											// 		style={{
																											// 			textDecoration: "underline",
																											// 			fontWeight: "500",
																											// 		}}>
																											// 		{e.cost === "" ? "Free" : e.cost}
																											// 	</span>
																											// </div> */}
																											// 													<div>
																											// 														<span className="ebook">
																											// 															Link:
																											// 														</span>
																											// 														&nbsp;
																											// 														{/* <span
																											// 		style={{
																											// 			textDecoration: "underline",
																											// 			fontWeight: "500",
																											// 		}}
																											// 	>
																											// 		{e.link}
																											// 	</span> */}
																											// 														<a
																											// 															href={
																											// 																(e.link?.includes(
																											// 																	"https://"
																											// 																)
																											// 																	? ""
																											// 																	: "http://") +
																											// 																e.link
																											// 															}
																											// 															rel="no-referer noreferrer"
																											// 															target="_blank">
																											// 															{e.link}
																											// 														</a>
																											// 													</div>
																											// 												</div>
																											// 												<div
																											// 													className="col-2"
																											// 													style={{
																											// 														display: "flex",
																											// 														alignItems:
																											// 															"center",
																											// 														justifyContent:
																											// 															"center",
																											// 													}}>
																											// 													{e.filesource !==
																											// 														"" && (
																											// 														<Button
																											// 															onClick={() => {
																											// 																fileConversion(
																											// 																	e.filesource
																											// 																);
																											// 															}}
																											// 															style={{
																											// 																backgroundColor:
																											// 																	"#E23F1C",
																											// 																border:
																											// 																	"none",
																											// 																borderRadius:
																											// 																	"4px",
																											// 																marginTop:
																											// 																	"-6px",
																											// 																color:
																											// 																	"#fff",
																											// 															}}>
																											// 															Open
																											// 														</Button>
																											// 													)}
																											// 													{userRole ===
																											// 														"Admin" && (
																											// 														<DocAction
																											// 															categoriesArray={
																											// 																dlCategorieArray
																											// 															}
																											// 															languagesArray={
																											// 																languages
																											// 															}
																											// 															doc={e}
																											// 															categories={() =>
																											// 																setShowCategorieModal(
																											// 																	true
																											// 																)
																											// 															}
																											// 															languages={() =>
																											// 																setShowLanguageModal(
																											// 																	true
																											// 																)
																											// 															}
																											// 															subcategories={() =>
																											// 																setShowSubCategorieModal(
																											// 																	true
																											// 																)
																											// 															}
																											// 														/>
																											// 													)}
																											// 												</div>
																											// 											</div>
																											// 										</div>
																											// 									</div>
																											<div className="col-3">
																												<Card
																													id=""
																													className="fadeup d-flex flex-column"
																													style={{
																														width: "100%",
																														height: "100%",
																													}}>
																													<img
																														style={{
																															alignSelf:
																																"center",
																															marginTop: "1rem",
																														}}
																														src="../images/Downloads_Links-Thumb.svg"
																														height="50%"
																														width="50%"
																														alt=""
																													/>

																													{/* <iframe
																														disabled="true"
																														width="100%"
																														height="215"
																														src={e.link}
																														title="YouTube video player"
																														frameborder="0"
																														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																														allowfullscreen="false"></iframe> */}
																													{/* <ReactPlayer
																														width="100%"
																														height="160px"
																														controls={false}
																														url={e.link}
																													/> */}
																													<Card.Body className="digital-text">
																														<div>
																															<span>
																																{" "}
																																{e.name}
																															</span>
																														</div>
																														<div>
																															Source:
																															<span>
																																{" "}
																																{e.source}
																															</span>
																														</div>
																														<div>
																															No. of pages:
																															<span>
																																{" "}
																																{
																																	e.numberofpages
																																}
																															</span>
																														</div>
																														<div>
																															Link:
																															<a
																																href={
																																	(e.link?.includes(
																																		"https://"
																																	)
																																		? ""
																																		: "http://") +
																																	e.link
																																}
																																rel="no-referer noreferrer"
																																target="_blank">
																																&nbsp;{e.link}
																															</a>
																															{/* <DigitalLibraryRegister
																																link={e.link}
																																courseid={e.id}
																																type="video"
																															/> */}
																														</div>
																														{e.filesource !==
																															"" && (
																															<DigitalLibraryRegister
																																link={e.link}
																																courseid={e.id}
																																file={
																																	e.filesource
																																}
																																type="document"
																															/>
																														)}
																														{userRole ===
																															"Admin" && (
																															<DocAction
																																categoriesArray={
																																	dlCategorieArray
																																}
																																languagesArray={
																																	languages
																																}
																																doc={e}
																																categories={() =>
																																	setShowCategorieModal(
																																		true
																																	)
																																}
																																languages={() =>
																																	setShowLanguageModal(
																																		true
																																	)
																																}
																																subcategories={() =>
																																	setShowSubCategorieModal(
																																		true
																																	)
																																}
																															/>
																														)}
																													</Card.Body>
																												</Card>
																											</div>
																										);
																									}
																								})}
																							</Accordion.Body>
																						</Accordion.Item>
																					</Accordion>
																				);
																			}
																		})}
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>
														);
													}
												})}
											</>
										);
									})}
								</>
							)}
						</div>
					</div>

					<div className="tab-pane" id="3">
						<div
							className="container"
							style={{ paddingBottom: "100px", paddingTop: "50px" }}>
							<div
								className="row"
								style={{
									// backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									margin: "0",
									fontWeight: "600",
									fontSize: "16px",
									// borderTopLeftRadius: "15px",
									// borderTopRightRadius: "15px",
									justifyContent: "flex-end",
								}}>
								<div
									className="col-5"
									style={{ display: "flex", alignItems: "center" }}>
									{/* eBooks */}
								</div>
								<div className="col-2">
									{userRole === "Admin" && (
										<Button
											onClick={() => setShowUploadEbooksModal(true)}
											style={{
												backgroundColor: "#F4F7F9",
												border: "none",
												borderRadius: "4px",
												marginTop: "-6px",
												color: "#000",
											}}
											// onMouseOver={() => {
											//   setColour("#F4F7F9");
											// }}
											// onMouseOut={() => {
											//   setColour("#fff");
											// }}
										>
											<img alt="upload" src="../images/Upload.svg"></img>
											&nbsp;&nbsp;Upload
										</Button>
									)}
								</div>
								<div
									className="col-3"
									style={{
										// width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="../images/sort_icon.svg"
													alt=""
												/>
											</button> */}
									<div className="d-flex float-end">
										<div style={{ fontSize: "13px" }}>Language:</div>
										<select
											name="language"
											style={{ marginLeft: "5px" }}
											onChange={(e) =>
												setSelectedDisplayBookLanguage(e.target.value)
											}>
											<option value="">Select</option>
											{languages.map((e) => {
												return (
													<option value={e.id} key={e.id}>
														{e.name}
													</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="col-2 uc-searchBar">
									<input
										type="text"
										placeholder=" Search"
										onChange={(e) => setBookSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							{ebBookArray.length === 0 ? (
								selectedDisplayBookLanguage === "" ? (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF">
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
														textAlign: "center",
													}}>
													No eBooks added
												</h5>
												{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
											</div>
										</ul>
									</div>
								) : (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF" style={{ textAlign: "center" }}>
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
														textAlign: "center",
													}}>
													eBooks in selected language are comming soon!!
												</h5>
												{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
											</div>
										</ul>
									</div>
								)
							) : (
								<>
									{ebBookCategoryArray.map((c) => {
										return (
											<>
												{dlCategorieArray.map((cc) => {
													if (
														c === cc.name
														// &&
														// JSON.stringify(ebBookArray).includes(cc.id)
													) {
														return (
															<Accordion
																key={cc.id}
																style={{
																	marginTop: "10px",
																	marginBottom: "10px",
																}}>
																<Accordion.Item eventKey={c}>
																	<Accordion.Header
																		style={{
																			borderBottom: "1px solid #ddd",
																		}}>
																		<div
																			style={{
																				fontWeight: "500",
																				fontSize: "18px",
																			}}>
																			{c}
																		</div>
																	</Accordion.Header>

																	<Accordion.Body
																		style={{
																			padding: "15px",
																			// borderTop: "1px solid #ddd",
																			// display: "flex",
																		}}>
																		{cc.subcategories.map((s) => {
																			if (
																				JSON.stringify(ebBookArray).includes(
																					s.id
																				)
																			) {
																				return (
																					<Accordion
																						key={s.id}
																						style={{
																							marginTop: "10px",
																							marginBottom: "10px",
																						}}>
																						<Accordion.Item eventKey={s.name}>
																							<Accordion.Header
																								style={{
																									borderBottom:
																										"1px solid #ddd",
																								}}>
																								<div
																									style={{
																										fontWeight: 500,
																										fontSize: "18px",
																									}}>
																									{s.name}
																								</div>
																							</Accordion.Header>

																							<Accordion.Body
																								className="row col-12"
																								style={{
																									padding: "15px",
																									// borderTop:
																									// 	"1px solid #ddd",
																									display: "flex",
																								}}>
																								{ebBookArray.map((e, index) => {
																									if (
																										e.subcategory.id === s.id
																									) {
																										return (
																											<div
																												key={e.id}
																												style={{
																													padding: "5px",
																												}}>
																												<div
																													className="card"
																													style={{
																														padding: "5px",
																													}}>
																													<div className="row">
																														<div
																															className="col-3"
																															style={{
																																display: "flex",
																																alignItems:
																																	"center",
																																justifyContent:
																																	"center",
																															}}>
																															<img
																																alt="book"
																																src="../images/ebook.jpg"
																																style={{
																																	width: "100%",
																																}}></img>
																														</div>
																														<div className="col-6">
																															<div>
																																<span className="ebook"></span>
																																&nbsp;
																																<span
																																	style={{
																																		textDecoration:
																																			"underline",
																																		fontWeight:
																																			"500",
																																	}}>
																																	{e.name}
																																</span>
																															</div>

																															{/* <div>
																																		<span className="ebook">
																																			Language:
																																		</span>
																																		&nbsp;
																																		<span
																																			style={{
																																				textDecoration:
																																					"underline",
																																				fontWeight:
																																					"500",
																																			}}>
																																			{
																																				e
																																					.language
																																					.name
																																			}
																																		</span>
																																	</div> */}
																															{/* <div>
																																				<span className="ebook">
																																					Category:
																																				</span>
																																				&nbsp;
																																				<span
																																					style={{
																																						textDecoration:
																																							"underline",
																																						fontWeight:
																																							"500",
																																					}}>
																																					{
																																						e
																																							.category
																																							.name
																																					}
																																				</span>
																																			</div> */}
																															<div>
																																<span className="ebook">
																																	ISBN:
																																</span>
																																&nbsp;
																																<span
																																	style={{
																																		textDecoration:
																																			"underline",
																																		fontWeight:
																																			"500",
																																	}}>
																																	{e.isbn}
																																</span>
																															</div>
																															{/* {e.subject !==
																																		"" && (
																																		<div>
																																			<span className="ebook">
																																				Subject:
																																			</span>
																																			&nbsp;
																																			<span
																																				style={{
																																					textDecoration:
																																						"underline",
																																					fontWeight:
																																						"500",
																																				}}>
																																				{
																																					e.subject
																																				}
																																			</span>
																																		</div>
																																	)} */}
																															{e.source !==
																																"" && (
																																<div>
																																	<span className="ebook">
																																		Source:
																																	</span>
																																	&nbsp;
																																	<span
																																		style={{
																																			textDecoration:
																																				"underline",
																																			fontWeight:
																																				"500",
																																		}}>
																																		{e.source}
																																	</span>
																																</div>
																															)}
																															<div>
																																<span className="ebook">
																																	Author:
																																</span>
																																&nbsp;
																																<span
																																	style={{
																																		textDecoration:
																																			"underline",
																																		fontWeight:
																																			"500",
																																	}}>
																																	{e.author}
																																</span>
																															</div>
																															<div>
																																<span className="ebook">
																																	Publisher:
																																</span>
																																&nbsp;
																																<span
																																	style={{
																																		textDecoration:
																																			"underline",
																																		fontWeight:
																																			"500",
																																	}}>
																																	{e.publisher}
																																</span>
																															</div>
																															<div>
																																<span className="ebook">
																																	Cost:
																																</span>
																																&nbsp;
																																<span
																																	style={{
																																		textDecoration:
																																			"underline",
																																		fontWeight:
																																			"500",
																																	}}>
																																	{e.cost === ""
																																		? "Free"
																																		: e.cost}
																																</span>
																															</div>
																															<div>
																																<span className="ebook">
																																	Link:
																																</span>
																																&nbsp;
																																{/* <span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}
																			>
																				{e.link}
																			</span> */}
																																<a
																																	href={
																																		(e.link?.includes(
																																			"https://"
																																		)
																																			? ""
																																			: "http://") +
																																		e.link
																																	}
																																	rel="no-referer noreferrer"
																																	target="_blank">
																																	{e.link}
																																</a>
																															</div>
																														</div>
																														<div
																															className="col-3"
																															style={{
																																display: "flex",
																																alignItems:
																																	"center",
																																justifyContent:
																																	"center",
																															}}>
																															{e.file !== "" &&
																																(e.isBookSaved ===
																																	true ||
																																	userRole ===
																																		"Admin") && (
																																	<Button
																																		onClick={() => {
																																			fileConversion(
																																				e.file
																																			);
																																		}}
																																		style={{
																																			backgroundColor:
																																				"#E23F1C",
																																			border:
																																				"none",
																																			borderRadius:
																																				"4px",
																																			marginTop:
																																				"-6px",
																																			color:
																																				"#fff",
																																		}}>
																																		Open
																																	</Button>
																																	// <DigitalLibraryRegister
																																	// 	link={
																																	// 		e.link
																																	// 	}
																																	// 	courseid={
																																	// 		e.id
																																	// 	}
																																	// 	file={
																																	// 		e.file
																																	// 	}
																																	// 	type="book"
																																	// />
																																)}
																															{e.file !== "" &&
																																e.isBookSaved ===
																																	false &&
																																userRole !==
																																	"Admin" && (
																																	<div className="row">
																																		{" "}
																																		{/* <Button
																																			onClick={() => {
																																				addtomybooksclick(
																																					e.id
																																				);
																																			}}
																																			style={{
																																				backgroundColor:
																																					"#E23F1C",
																																				border:
																																					"none",
																																				borderRadius:
																																					"4px",
																																				marginTop:
																																					"-6px",
																																				color:
																																					"#fff",
																																			}}>
																																			Add to my
																																			books
																																		</Button> */}
																																		<DigitalLibraryRegister
																																			link={
																																				e.link
																																			}
																																			courseid={
																																				e.id
																																			}
																																			file={
																																				e.file
																																			}
																																			type="book"
																																		/>
																																	</div>
																																)}
																															{userRole ===
																																"Admin" && (
																																<BookAction
																																	book={e}
																																	categoriesArray={
																																		dlCategorieArray
																																	}
																																	languagesArray={
																																		languages
																																	}
																																	categories={() =>
																																		setShowCategorieModal(
																																			true
																																		)
																																	}
																																	languages={() =>
																																		setShowLanguageModal(
																																			true
																																		)
																																	}
																																	subcategories={() =>
																																		setShowSubCategorieModal(
																																			true
																																		)
																																	}
																																/>
																															)}
																														</div>
																													</div>
																												</div>
																											</div>
																										);
																									}
																								})}
																							</Accordion.Body>
																						</Accordion.Item>
																					</Accordion>
																				);
																			}
																		})}
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>
														);
													}
												})}
											</>
										);
									})}
								</>
							)}
						</div>
					</div>
				</div>
				<Footer />
			</div>

			<Modal
				show={showCategorieModal}
				onHide={() => setShowCategorieModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Digital Library Categorie
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setShowCategorieModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>
								Digital Library Categorie Name:
							</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Course Categorie name"
								onChange={(e) => setCourseName(e.target.value)}></input>
						</div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>
								Digital Library Categorie Description:
							</div>
							<textarea
								type="text"
								className="form-control"
								placeholder="Enter Course Categorie Description"
								onChange={(e) =>
									setCourseDescription(e.target.value)
								}></textarea>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={courseName === "" || courseDescription === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoCourseCategorieArray();
							}}>
							Save
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showSubCategorieModal}
				onHide={() => setShowSubCategorieModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Digital Library Sub Categorie
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setShowSubCategorieModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div style={{ paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Digital Library Categorie:</div>
							<select
								name="category"
								defaultValue={selectedCategory}
								onChange={(e) => setSelectedCategory(e.target.value)}>
								<option value="">Select</option>
								{dlCategorieArray.map((e) => {
									return (
										<option value={e.id} key={e.id}>
											{e.name}
										</option>
									);
								})}
							</select>
							{/* <span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowCategorieModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span> */}
						</div>
						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>
								Digital Library Sub Categorie Name:
							</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Course Sub Categorie name"
								onChange={(e) => setSubCourseName(e.target.value)}></input>
						</div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>
								Digital Library Sub Categorie Description:
							</div>
							<textarea
								type="text"
								className="form-control"
								placeholder="Enter Course Sub Categorie Description"
								onChange={(e) =>
									setSubCourseDescription(e.target.value)
								}></textarea>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={subCourseName === "" || subCourseDescription === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoCourseSubCategorieArray();
							}}>
							Save
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadLinkModal}
				onHide={handleCloseLinkModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Link
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseLinkModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div className="row">
								<div style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
									<div style={{ fontSize: "13px" }}>Language:</div>
									<select
										name="language"
										onChange={(e) => setSelectedLanguage(e.target.value)}>
										<option value="">Select</option>
										{languages.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowLanguageModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Name/ Title *"
										onChange={(e) => setLinkName(e.target.value)}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
								<div className="col-12" style={{ marginTop: "15px" }}>
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Source *"
										onChange={(e) => setLinkSource(e.target.value)}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>

								<div className="uc-modal-body">
									<div className="uc-detail-btn-wrap uc-share-btn-wrap">
										<div className="row">
											<div className="col-12" style={{ marginTop: "15px" }}>
												<input
													type="text"
													className="form-control"
													id="copytext"
													onChange={(e) => setLinkLink(e.target.value)}
													//   defaultValue="ulektz.com/c/krishigap"
													style={{ backgroundColor: "#fff" }}
													placeholder="Enter link *"
												/>
											</div>
											{/* <div
												className="col-2"
												style={{
													paddingLeft: "0px",
													marginTop: "15px",
												}}>
												<a
													href="javascript:void(0)"
													// onClick="copyCommunityUrl()"
													className="copybuttonaddlink">
													{/* <img src="../images/Copyshare_link.svg" alt="copy" />
													<span style={{ color: "#fff", paddingLeft: "4px" }}>
														Copy
													</span> */}
											{/* <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div> *
												</a>
											</div> */}
										</div>
									</div>
								</div>
								{/* <div
                  className="col-3"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <a
                    href="javascript:void(0)"
                    onClick="copyCommunityUrl()"
                    className="copybutton"
                  >
                    <img src="../images/Copyshare_link.svg" alt="copy" />
                    <span style={{ color: "#fff", paddingLeft: "4px" }}>
                      Copy
                    </span>
                    <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div>
                  </a>
                </div> */}
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
								}}>
								{/* <input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post */}
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										// onClick={() => handleUploadLink()}
									>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showLanguageModal}
				onHide={handleCloseLanguageModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Language
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseLanguageModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Language:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Language"
								onChange={(e) => setLanguage(e.target.value)}></input>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={language === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handleUploadlanguage();
							}}>
							Add
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadDocModal}
				onHide={handleCloseDocModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Files & Docs
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseDocModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 mt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Document Name"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocCategory(e.target.value)}
									/>
								</div>
							</div> */}

							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="number"
										className="form-control"
										id="copytext"
										placeholder="Number of pages"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setNumberOfPages(e.target.value)}
									/>
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocLink(e.target.value)}
									/>
								</div>
							</div>

							<div>File Source:</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="application/*"
										className="form-control"
										id="copytext"
										onChange={(e) => setDocFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadDoc()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadVideoModal}
				onHide={handleCloseVideoModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Video
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseVideoModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 mt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoCategory(e.target.value)}
									/>
								</div>
							</div> */}

							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="number"
										className="form-control"
										id="copytext"
										placeholder="Duration in minutes"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDuration(e.target.value)}
									/>
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setVideoLink(e.target.value)}
									/>
								</div>
							</div>

							{/* <div>Source:</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="video/*"
										className="form-control"
										id="copytext"
										onChange={(e) => setVideoFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div> */}
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadVideo()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadEbooksModal}
				onHide={handleCloseUploadEbooksModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Ebook
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseUploadEbooksModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 pt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebBookName}
										placeholder="Book Name"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbBookName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebCategory}
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbCategory(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebISBN}
										placeholder="ISBN"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbISBN(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebSubject}
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebSource}
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebAuthor}
										placeholder="Author"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbAuthor(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										value={ebPublisher}
										id="copytext"
										placeholder="Publisher"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbPublisher(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										value={ebCost}
										id="copytext"
										placeholder="Cost Rs."
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbCost(e.target.value)}
									/>
								</div>
								<div style={{ fontSize: "13px", fontWeight: 500 }}>
									(Note: In case of free course, leave the cost field empty!!!)
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										id="copytext"
										onChange={(e) => setEbFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div>
							<div style={{ textAlign: "center" }}>OR</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={eblink}
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbLink(e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadEbook()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default Digitallibrary;
