import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Anjunayyar = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />

			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Anju Nayyar</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img className="imagesb" src="../images/anju_nayyar.png" alt="" />
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<div style={{ paddingBottom: "10px", lineHeight: "30px" }}>
							<b>Present</b>
							<br />
							<span>
								Anju Nayyar is currently in a senior advisory role cutting
								across public affairs and business development for the Growth
								and Commercial Division at Yara in the Asia-Africa region, where
								she has developed a passion for emerging markets, farming, and
								digital solutions. She leads relationships and business
								development across these regions positioning Yara for success
								with customers and cross-sector stakeholders in Asia and Africa.
							</span>
							<br />
						</div>
						<div style={{ paddingBottom: "15px", lineHeight: "30px" }}>
							<b>Past</b>
							<br />
							<span>
								Anju Nayyar is a versatile senior executive leader and a board
								member specializing in public affairs, high-value sales, and
								business development, creating win-win scenarios with measurable
								social impact. With over 23 years of leadership experience,
								Anju’s work spans agriculture, healthcare and nutrition,
								technology, sustainability, finance, and media sectors. She is
								registered with the MCCIA. Talks about , CSR, ESG and statutory
								compliance.
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Significant Achievements</b>
					<ul className="ulstyle">
						<li>
							Grew to a profit Centre head while developing strategic alliances,
							tech transfer strategy, and partnering with countries to
							participate in technical trade shows.
						</li>
						<li>
							Worked on 4 agtech applications from conception to implementation.
						</li>
						<li style={{ marginLeft: "40px" }}>Farmcare</li>
						<li style={{ marginLeft: "40px" }}>Farmgo</li>
						<li style={{ marginLeft: "40px" }}>Farm Forward</li>
						<li style={{ marginLeft: "40px" }}>Yara Bodega</li>

						<li>
							Through these applications aere able to connect 13 million farmers
							and actually enhance the farmer’s income.
						</li>
						<li>
							She has been a pioneer in setting up systems to digitize the KCC
							and has tried to impress upon the farming community the
							significance and need for a healthy credit.
						</li>
						<li>
							Has stood up for the unrepresented and collaborated with 8 banks
							to make this possible on the ground.
						</li>
						<li>
							Has addressed and brought about several strategic alliances to
							make sure the message of collaboration is spread and thus bringing
							about increase in yield.
						</li>
						<li>
							She is currently working on several collaborations to digitize the
							supply chain.
						</li>
						<li>
							Explained to the stakeholders the value of FPOS and how can we
							leverage this Network.
						</li>
					</ul>
					<b>International Exposure</b>
					<ul className="ulstyle">
						<li>
							Has worked and collaborated with 16 different countries through
							the Chem -Tech Foundation on Technology transfer for Enviro and
							clean Tech. Has worked in Singapore, Malaysia, Vietnam, Germany
							and the US in different capacities.
						</li>
					</ul>
					<b>Publications</b>
					<ul className="ulstyle">
						<li>
							20 research publications are in diffrent journals like The Hindu
							Business Line, The National News (UAE) and The Pioneer,
							Entrepreneur India, Your story etc
						</li>
						<li>
							Several research publications and technical /Extension bulletins,
							brochure and videos for the benefit of farmers in English and
							Hindi.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							University of Cambridge, Sustainability Leadership (Pursuing)
						</li>
						<li>
							<b>University of Zambia</b>, Master of Business Administration,
							Marketing
						</li>
						<li>
							<b>University of Zambia</b>, BSC, Botany
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Anjunayyar;
