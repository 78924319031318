import "country-flag-icons/react/3x2";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataPDF, postDataAPI } from "../utils/fetchData";
import LoginRequest from "./loginRequest";

export default function DigitalLibraryRegister(props) {
	const { profile, auth } = useSelector((state) => state);
	const [showModal, setShowModal] = useState(false);
	const [videoModal, setVideoModal] = useState(false);
	const [selectedCourseId, setSelectedCourseId] = useState("");
	const [loginModel, setLoginModel] = useState(false);

	const initialState = {
		name: "",
		gender: "",
		email: "",
		phone: "",
		city: "",
		country: "",
		occupation: "",
		organization: "",
	};
	const [userData, setUserData] = useState(initialState);
	const [registerEnabled, setRegisterEnabled] = useState(false);

	const dispatch = useDispatch();

	const handleCloseModal = () => {
		setShowModal(false);
		setUserData(initialState);
	};

	const handleCloseVideoModal = () => {
		setVideoModal(false);
	};

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	useEffect(() => {
		setSelectedCourseId(props.courseid);
	}, [props]);

	useEffect(() => {
		if (
			userData.name !== "" &&
			// userData.gender !== "" &&
			(userData.email !== "" || userData.phone !== "")
			// &&
			// userData.city !== "" &&
			// userData.country !== "" &&
			// 		userData.occupation !== ""
		) {
			setRegisterEnabled(true);
		} else {
			setRegisterEnabled(false);
		}
		console.log("userData", userData);
	}, [userData]);

	const handleRegisterCourse = async () => {
		setShowModal(false);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("join clicked", userData);
		localStorage.setItem(
			"userDetails",
			JSON.stringify({
				...userData,
				dl_id: selectedCourseId,
				userid: auth.user?.id,
				dl_type: props.type,
			})
		);
		try {
			const res = await postDataAPI(
				"createdigitallibaryUsers",
				{
					...userData,
					dl_id: selectedCourseId,
					userid: auth.user?.id,
					dl_type: props.type,
				}
				// auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setShowModal(false);
				if (props.type === "video") {
					setVideoModal(true);
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				}
				if (props.type === "document") {
					fileConversion(props.file);
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				}
				if (props.type === "book") {
					fileConversion(props.file);
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
					// const addtomybooksclick = async (bookid) => {
					// dispatch({
					// 	type: GLOBALTYPES.ALERT,
					// 	payload: { loading: true },
					// });
					// console.log("bookid", props.courseid);
					// try {
					// 	const res = await postDataAPI(
					// 		"createusersaved",
					// 		{
					// 			userid: auth.user.id,
					// 			bookid: props.courseid,
					// 		},
					// 		auth.token
					// 	);
					// 	console.log("res", res);
					// 	if (res.status === 200) {
					// 		window.location.reload();
					// 	}
					// } catch (err) {
					// 	dispatch({
					// 		type: GLOBALTYPES.ALERT,
					// 		payload: { error: err.response.data.msg },
					// 	});
					// }
					// };
				}
				// window.open(
				// 	(props.link?.includes("https://") ? "" : "http://") + props.link,
				// 	"_blank"
				// );
				// window.location.reload();
				// window.location.href = "../mycources";
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	const handlePhoneChange = (value) => {
		// setPhone(value);
		setUserData({ ...userData, phone: value });
		console.log("Phone number:", value); // Full number with country code
	};

	const handleViewClick = async () => {
		var userDetails =
			localStorage.getItem("userDetails") === null
				? undefined
				: JSON.parse(localStorage.getItem("userDetails"));
		console.log("userDetails", userDetails);
		if (auth.token) {
			try {
				const res = await postDataAPI(
					"createdigitallibaryUsers",
					{
						...auth.user,
						name: auth.user.fullname,
						dl_id: selectedCourseId,
						userid: auth.user?.id,
						dl_type: props.type,
					}
					// auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					setShowModal(false);
					if (props.type === "video") {
						setVideoModal(true);
					}
					if (props.type === "document") {
						fileConversion(props.file);
					}
					if (props.type === "book") {
						fileConversion(props.file);

						// const addtomybooksclick = async (bookid) => {
						// dispatch({
						// 	type: GLOBALTYPES.ALERT,
						// 	payload: { loading: true },
						// });
						// console.log("bookid", props.courseid);
						// try {
						// 	const res = await postDataAPI(
						// 		"createusersaved",
						// 		{
						// 			userid: auth.user.id,
						// 			bookid: props.courseid,
						// 		},
						// 		auth.token
						// 	);
						// 	console.log("res", res);
						// 	if (res.status === 200) {
						// 		window.location.reload();
						// 	}
						// } catch (err) {
						// 	dispatch({
						// 		type: GLOBALTYPES.ALERT,
						// 		payload: { error: err.response.data.msg },
						// 	});
						// }
						// };
					}
					// window.open(
					// 	(props.link?.includes("https://") ? "" : "http://") + props.link,
					// 	"_blank"
					// );
					// window.location.reload();
					// window.location.href = "../mycources";
				}
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
		} else if (userDetails) {
			console.log("User details");
			try {
				const res = await postDataAPI(
					"createdigitallibaryUsers",
					{
						...userDetails,
						dl_id: selectedCourseId,
						userid: auth.user?.id,
						dl_type: props.type,
					}
					// auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					setShowModal(false);
					if (props.type === "video") {
						setVideoModal(true);
					}
					if (props.type === "document") {
						fileConversion(props.file);
					}
					if (props.type === "book") {
						fileConversion(props.file);
						// const addtomybooksclick = async (bookid) => {
						// dispatch({
						// 	type: GLOBALTYPES.ALERT,
						// 	payload: { loading: true },
						// });
						// console.log("bookid", props.courseid);

						// try {
						// 	const res = await postDataAPI(
						// 		"createusersaved",
						// 		{
						// 			userid: auth.user.id,
						// 			bookid: props.courseid,
						// 		},
						// 		auth.token
						// 	);
						// 	console.log("res", res);
						// 	if (res.status === 200) {
						// 		window.location.reload();
						// 	}
						// } catch (err) {
						// 	dispatch({
						// 		type: GLOBALTYPES.ALERT,
						// 		payload: { error: err.response.data.msg },
						// 	});
						// }
						// };
					}
					// window.open(
					// 	(props.link?.includes("https://") ? "" : "http://") + props.link,
					// 	"_blank"
					// );
					// window.location.reload();
					// window.location.href = "../mycources";
				}
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
		} else {
			setShowModal(true);
		}
	};

	return (
		<>
			<Modal
				show={showModal}
				onHide={handleCloseModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Please enter your details to view
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div style={{ padding: "15px 45px 10px" }}>
						<label for="name" style={{ paddingBottom: "5px" }}>
							Full Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="name"
							value={userData.name}
							className="form-control"
							placeholder="Enter Full Name"></input>
						{/* <label
							for="lastname"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Last Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="lastname"
							value={userData.lastname}
							className="form-control"
							placeholder="Enter Last Name"></input> */}
						{/* <div
							className="d-flex gap-5  mx-0 mb-1"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							<label for="name">Gender*</label>
							<label htmlFor="male">
								Male&nbsp;
								<input
									type="radio"
									id="male"
									name="gender"
									value="male"
									// defaultChecked
									onChange={handleChangeInput}
								/>
							</label>

							<label htmlFor="female">
								Female&nbsp;
								<input
									type="radio"
									id="female"
									name="gender"
									value="female"
									onChange={handleChangeInput}
								/>
							</label>
						</div> */}
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Email
						</label>
						<input
							type="email"
							onChange={handleChangeInput}
							name="email"
							value={userData.email}
							className="form-control"
							placeholder="Enter Email"></input>

						<h5 className="d-flex w-100 mt-3 justify-content-center align-content-center">
							OR
						</h5>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Mobile Number
						</label>
						{/* <input
							type="text"
							onChange={handleChangeInput}
							name="phone"
							// style={{ paddingTop: "10px", paddingBottom: "5px" }}
							value={userData.phone}
							className="form-control"
							placeholder="Enter Mobile Number"></input> */}
						<PhoneInput
							country={"in"} // Default country
							value={userData.phone}
							onChange={handlePhoneChange}
							inputStyle={{
								width: "100%",
							}}
						/>
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							City*
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="city"
							value={userData.city}
							placeholder="Enter City"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Country*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.country}
							name="country"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="India">India</option>
							<option value="USA">USA</option>
						</select> */}
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Occupation*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.occupation}
							name="occupation"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="Farmer">Farmer</option>
							<option value="FPO CEO">FPO CEO</option>
							<option value="Agri extension">Agri extension</option>
							<option value="Student">Student</option>
							<option value="Employee in food processing">
								Employee in food processing
							</option>
							<option value="Quality Professional">Quality Professional</option>
							<option value="Others">Others</option>
						</select>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Organization
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="organization"
							value={userData.organization}
							placeholder="Enter Organization"></input> */}
						{/* <input
							type="checkbox"
							// className="form-control"
							onChange={checkHandler}
							name="isChecked"
							isChecked={userData.isChecked}
							style={{
								marginTop: "10px",
								marginBottom: "5px",
								marginRight: "5px",
							}}></input>
						Click on the Box to agree to the platform terms and Privacy Policy */}
						{/* <input
							type="checkbox"
							id="checkbox"
							style={{
								marginTop: "10px",
								marginBottom: "5px",
								marginRight: "5px",
							}}
							checked={userData.isChecked}
							onChange={checkHandler}
						/>
						{/* <label htmlFor="checkbox"> *
						Click on the Box to agree to the platform terms and Privacy Policy{" "} */}
						{/* </label> */}
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={registerEnabled === false}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handleRegisterCourse();
							}}>
							Submit
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={videoModal}
				onHide={handleCloseVideoModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 20px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}></h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseVideoModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
						<ReactPlayer
							width="100%"
							height="360px"
							controls={true}
							url={props.link}
						/>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={loginModel}
				onHide={() => setLoginModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<LoginRequest />
					</div>
				</Modal.Body>
			</Modal>

			{props.type === "video" && (
				<Button
					onClick={() => {
						handleViewClick();
					}}
					style={{
						background: "#36BB7D",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}>
					Link
				</Button>
			)}
			{props.type === "book" && (
				<Button
					onClick={() => {
						// addtomybooksclick(e.id);
						// setShowModal(true);
						handleViewClick();
						// if (auth.token) {
						// 	setShowModal(true);
						// } else {
						// 	setLoginModel(true);
						// }
					}}
					style={{
						backgroundColor: "#E23F1C",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
						color: "#fff",
					}}>
					Open
				</Button>
			)}
			{props.type === "document" && (
				<Button
					onClick={() => {
						// fileConversion(props.file);
						// setShowModal(true);
						handleViewClick();
					}}
					style={{
						backgroundColor: "#E23F1C",
						border: "none",
						borderRadius: "4px",
						marginTop: "6px",
						color: "#fff",
					}}>
					Open
				</Button>
			)}
		</>
	);
}
