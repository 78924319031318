import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../Menu.css";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const JobSeekerP = () => {
	const dispatch = useDispatch();

	const { auth } = useSelector((state) => state);
	// console.log("auth", auth);
	const [jobseeker, setJobseeker] = useState({});

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		// if (auth.token) {
		getDataAPI(
			`getJobseekersById/${window.location.pathname.split("/")[2]}`
			// auth.token
		).then((res) => {
			console.log("res jobseekers", res.data);
			setJobseeker(res.data.jobseekers);
			// setDisplayContent(res.data.course);
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
		// }
	}, [auth.token]);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	return (
		<div>
			<Navbar />
			<Homecarousel />
			<div style={{ marginTop: "50px" }}>
				<center>
					<img
						src={jobseeker.profilepic || "../images/common-user.svg"}
						alt=""
						width="250px"
					/>
				</center>
			</div>
			<div
				style={{
					marginTop: "50px",
					marginLeft: "100px",
					marginRight: "100px",
					marginBottom: "50px",
					fontSize: "20px",
					paddingLeft: "100px",
					paddingRight: "100px",
					backgroundColor: " rgb(187, 228, 163)",
					paddingTop: "20px",
					paddingBottom: "20px",
					fontFamily: "Rubik, sans-serif",
					borderRadius: "5px",
					boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.5)",
					letterSpacing: "0.5px",
				}}>
				<span>Full Name: {jobseeker.name}</span>
				<br />
				<hr />
				<span>Gender: {jobseeker.gender}</span>
				<br />
				<hr />
				{/* <span>Picture :</span>
				<br />
				<hr /> */}
				<span>Email: {jobseeker.email}</span>
				<br />
				<hr />
				<span>Mobile Number: {jobseeker.mobile}</span>
				<br />
				<hr />
				<span>LinkedIn profile: {jobseeker.linkedin_profile}</span>
				<br />
				<hr />
				<span>
					Educational qualification: {jobseeker.educationalqualifications}
				</span>
				<br />
				<hr />
				<span>
					Food Safety Standard Interested: {jobseeker.f_s_s_intersted}
				</span>
				<br />
				<hr />
				<span>Employment Type: {jobseeker.employmenttype}</span>
				<br />
				<hr />
				<span>Job Type: {jobseeker.jobtype}</span>
				<br />
				<hr />
				<span>City: {jobseeker.city}</span>
				<br />
				<hr />
				<span>City: {jobseeker.country}</span>
				<br />
				<hr />
				<span>Salary range: {jobseeker.salaryrange}</span>
				<br />
				<hr />
				<span>Areas of expertise: {jobseeker.areasofexpertise}</span>
				<br />
				<hr />
				<span>Years of experience: {jobseeker.yearsofexperience}</span>
				<br />
				<hr />
				<span>Availability: {jobseeker.availability}</span>
				<br />
				<hr />
				<span>Additional information: {jobseeker.additionalinformation}</span>
				<br />
				<hr />
				{jobseeker.resume !== "" && (
					<>
						<span>
							Resume:{" "}
							<Button
								onClick={() => {
									fileConversion(jobseeker.resume);
								}}
								style={{
									backgroundColor: "#E23F1C",
									border: "none",
									borderRadius: "4px",
									marginTop: "-6px",
									color: "#fff",
								}}>
								Open
							</Button>
						</span>
						<br />
						<hr />
					</>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default JobSeekerP;
