import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Placementmanagement = () => {
	const dispatch = useDispatch();
	const { profile, auth } = useSelector((state) => state);
	const [pendingjobseekers, setPendingJobseekers] = useState([]);
	const [jobseekerInterest, setJobseekerInterest] = useState([]);
	const [jobseekerDetails, setJobseekerDetails] = useState("");

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		if (auth.token) {
			try {
				getDataAPI(`getAllPendingJobseekers`, auth.token).then((res) => {
					setPendingJobseekers(res.data.jobseekers);
				});
				getDataAPI(`getJobseekersCount`, auth.token).then((res) => {
					console.log("res getJobseekersCount", res.data);
					setJobseekerDetails(res.data.jobseekersdetails);
				});
				getDataAPI(`getAllJobseekerInterest`).then((res) => {
					console.log("res getAllJobseekerInterest", res.data);
					setJobseekerInterest(res.data.jobseekerInterests);
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				});
			} catch (err) {
				console.error("Error fetching job seekers:", err);
			}
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: false },
			});
		}
	}, [auth.token]);

	const handleAcceptClick = async (user) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI(
				"updatejobseekers",
				{
					id: user.id,
					activationstatus: "accepted",
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// alert("Thank you for creating this group");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			// dispatch({
			// 	type: GLOBALTYPES.ALERT,
			// 	payload: { error: err.response.data.msg },
			// });
		}
	};

	const findCount = (name) => {
		const job = jobseekerInterest.find((user) => user.f_s_s_intersted === name);
		return job ? job.count : 0;
	};

	const handleDeclineClick = async (user) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI(
				"updatejobseekers",
				{
					id: user.id,
					activationstatus: "declined",
				},
				auth.token
			);

			console.log("res", res);
			if (res.status === 200) {
				// alert("Thank you for creating this group");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			// dispatch({
			// 	type: GLOBALTYPES.ALERT,
			// 	payload: { error: err.response.data.msg },
			// });
		}
	};

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	return (
		<>
			<Adminnavbar />
			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="dashboard-text">Super Admin Dashboard</div>
					<div id="admin" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8" style={{ backgroundColor: "#A7D588" }}>
									<div
										className="row format"
										style={{ paddingTop: "20px", paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{jobseekerDetails.total}</div>
											<div className="text1">Total Jobseekers</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">{jobseekerDetails.accepted}</div>
											<div className="text1">Accepted Jobseekers</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											{" "}
											<div className="text1">{jobseekerDetails.declined}</div>
											<div className="text1">Declined Jobseekers</div>
										</div>
									</div>
									<div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">{jobseekerDetails.male}</div>
											<div className="text1">Male Jobseekers</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">{jobseekerDetails.female}</div>
											<div className="text1">Female Jobseekers</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											{" "}
											<div className="text1">{jobseekerDetails.pending}</div>
											<div className="text1">Pending Jobseekers</div>
										</div>
									</div>
								</div>
								<div
									className="row format"
									style={{ paddingBottom: "20px", marginTop: "20px" }}>
									<div
										className="col card-format-analatics"
										style={{ backgroundColor: "#A7D588" }}>
										<h4 className="instructorlist-text p-2">
											Jobseekers interested standards
										</h4>
										<div
											className="row format"
											style={{ paddingBottom: "20px", paddingTop: "20px" }}>
											<div
												className="col text-format"
												style={{ backgroundColor: "#C78B62" }}>
												<div className="text1">{findCount("Organic NPOP")}</div>
												<div className="text1">Organic NPOP</div>
											</div>
											<div
												className="col text-format"
												style={{ backgroundColor: "#198754" }}>
												<div className="text1">{findCount("GlobalG.A.P.")}</div>
												<div className="text1">GlobalG.A.P.</div>
											</div>
											<div
												className="col text-format"
												style={{ backgroundColor: "#FFC107" }}>
												<div className="text1">{findCount("ISO 9001")}</div>
												<div className="text1"> ISO 9001</div>
											</div>
										</div>
										<div
											className="row format"
											style={{ paddingBottom: "20px" }}>
											<div
												className="col text-format"
												style={{ backgroundColor: "#7805B5" }}>
												<div className="text1">{findCount("USFDA PCQI")}</div>
												<div className="text1">USFDA PCQI</div>
											</div>
											<div
												className="col text-format"
												style={{ backgroundColor: "#BF67A8" }}>
												<div className="text1">{findCount("ISO 14001")}</div>
												<div className="text1">ISO 14001</div>
											</div>
											<div
												className="col text-format"
												style={{ backgroundColor: "#0D6EFD" }}>
												<div className="text1">{findCount("FSSAI")}</div>
												<div className="text1">FSSAI</div>
											</div>
										</div>
										<div
											className="row format"
											style={{ paddingBottom: "20px" }}>
											<div
												className="col text-format"
												style={{ backgroundColor: "#0DCAF0" }}>
												<div className="text1">{findCount("FSSC 22000")}</div>
												<div className="text1">FSSC 22000</div>
											</div>
											<div
												className="col text-format"
												style={{ backgroundColor: "#C78B62" }}>
												<div className="text1">{findCount("ISO 22000")}</div>
												<div className="text1">ISO 22000</div>
											</div>
											<div
												className="col text-format"
												style={{ backgroundColor: "#4C87B8" }}>
												<div className="text1">{findCount("USDA NOP")}</div>
												<div className="text1">USDA NOP</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: "20px" }}>
									<div className="col">
										<h4 className="instructorlist-text">Pending Jobseekers</h4>
										<table className="table table-responsive">
											<thead id="pl">
												<tr>
													<th>S.No</th>
													<th>Profile pic</th>
													<th>Name</th>
													<th>Email</th>
													<th>Gender</th>
													<th>Mobile</th>
													<th>Location</th>
													<th>Resume</th>
													<th>LinkedIn Profile</th>
													<th>Availability</th>
													{/* <th>Job preference</th> */}
													{/* <th>Area of Expertise</th> */}
													<th>Years of Experience</th>
													<th>Food safety standard interested</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{pendingjobseekers.map((jobseeker, index) => (
													<tr key={jobseeker.id}>
														<td>{index + 1}</td>
														<td>
															{
																<img
																	// style={{ borderRadius: "50%" }}
																	src={
																		jobseeker.profilepic ||
																		"../images/common-user.svg"
																	}
																	alt=""
																	width="100px"
																/>
															}
														</td>
														<td>{jobseeker.name}</td>
														<td>{jobseeker.email}</td>
														<td>{jobseeker.gender}</td>
														<td>{jobseeker.mobile}</td>
														<td>{jobseeker.city + ", " + jobseeker.country}</td>
														<td>
															{jobseeker.resume !== "" && (
																<>
																	<span>
																		<Button
																			onClick={() => {
																				fileConversion(jobseeker.resume);
																			}}
																			style={{
																				backgroundColor: "#E23F1C",
																				border: "none",
																				borderRadius: "4px",
																				marginTop: "-6px",
																				color: "#fff",
																			}}>
																			Open
																		</Button>
																	</span>
																	<br />
																	{/* <hr /> */}
																</>
															)}
														</td>
														<td>{jobseeker.linkedin_profile}</td>
														<td>{jobseeker.availability}</td>
														{/* <td>{jobseeker.job_preference}</td> */}
														{/* <td>{jobseeker.expertise}</td> */}
														<td>{jobseeker.yearsofexperience}</td>
														<td>{jobseeker.f_s_s_intersted}</td>
														<td>
															<button
																onClick={() => handleAcceptClick(jobseeker)}
																className="btn btn-success bg-success">
																Accept
															</button>
															<button
																onClick={() => handleDeclineClick(jobseeker)}
																className="btn btn-danger bg-danger">
																Decline
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Placementmanagement;
