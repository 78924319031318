import React, { useEffect, useState } from "react";
import {
	Accordion,
	Button,
	Modal,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import "country-flag-icons/react/3x2";
import Table from "react-bootstrap/Table";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Navbar from "./navbar";

const Courseinfo = () => {
	const { profile, auth } = useSelector((state) => state);
	const [userRole, setUserRole] = useState("");
	const [displayContent, setDisplayContent] = useState("");
	const [courseRegisterModel, setCourseRegisterModel] = useState(false);
	const [loginModel, setLoginModel] = useState(false);
	const initialState = {
		fullname: "",
		gender: "",
		email: "",
		mobile: "",
		city: "",
		country: "",
		occupation: "",
		organization: "",
		isChecked: false,
	};
	const [userData, setUserData] = useState(initialState);
	const [userName, setUserName] = useState("");
	const [userPhone, setUserPhone] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userFeedback, setUserFeedback] = useState("");
	const [registerEnabled, setRegisterEnabled] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		if (auth.token) {
			getDataAPI(
				`getcommunityuser/${auth.user.id}/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);

				getDataAPI(
					`getCourseById/${window.location.pathname.split("/")[2]}/${
						auth.user.id
					}`,
					auth.token
				).then((res) => {
					console.log("res my courses", res.data);
					setDisplayContent(res.data.course);
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				});
			});
		} else {
			setUserRole("user");
			getDataAPI(
				`getCourseById/${window.location.pathname.split("/")[2]}/undefined`,
				auth.token
			).then((res) => {
				console.log("res my courses", res.data);
				setDisplayContent(res.data.course);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			});
		}
	}, [auth.token]);

	const checkHandler = () => {
		setUserData({ ...userData, isChecked: !userData.isChecked });
		// setIsChecked(!isChecked);
	};

	useEffect(() => {
		if (
			userData.fullname !== "" &&
			// userData.gender !== "" &&
			userData.email !== "" &&
			userData.mobile !== "" &&
			userData.city !== "" &&
			// userData.country !== "" &&
			userData.occupation !== "" &&
			userData.isChecked === true
		) {
			setRegisterEnabled(true);
		} else {
			setRegisterEnabled(false);
		}
		console.log("userData", userData);
	}, [userData]);

	var count = 1;

	const handleOpenCourseStepsInvolvedFile = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("file", file);
		var newfile = {
			file: file,
		};
		// pdfWindow.location.href = file;
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			// const pdfWindow = window.open();
			console.log("res my courses 11 ", res.data);

			const newfile1 = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(newfile1);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			// pdfWindow.location.href = fileURL;
			window.open(fileURL, "_blank");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			// window.open(fileURL);
		});
	};

	const addtomycoursesclick = async (courseid) => {
		if (auth.token) {
			setCourseRegisterModel(true);
		} else {
			setLoginModel(true);
		}

		console.log("courseid", courseid);
	};

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	const handlePhoneChange = (value) => {
		// setPhone(value);
		setUserData({ ...userData, mobile: value });
		console.log("Phone number:", value); // Full number with country code
	};

	const handleRegisterCourse = async () => {
		setCourseRegisterModel(false);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("join clicked", userData);
		try {
			if (displayContent.cost === "") {
				try {
					const res = await postDataAPI(
						"createCourseRegisters",
						{
							...userData,
							courseid: displayContent.id,
							userid: auth.user.id,
						},
						auth.token
					);
					console.log("res", res);
					if (res.status === 200) {
						const res1 = await postDataAPI(
							"createusersaved",
							{
								userid: auth.user.id,
								courseid: displayContent.id,
							},
							auth.token
						);
						console.log("res", res);
						if (res1.status === 200) {
							// window.location.reload();
							window.location.href = "../mycources";
						}
					}
				} catch (err) {
					dispatch({
						type: GLOBALTYPES.ALERT,
						payload: { error: err.response.data.msg },
					});
				}
			} else {
				const orderResponse = await postDataAPI(
					"createrazorpaypayment",
					{
						amount: Number(displayContent.cost),
						currency: "INR",
						receipt: "receipt#1",
						// notes: { key1: "value1", key2: "value2" },
					},
					auth.token
				);

				const { id: order_id, amount, currency } = orderResponse.data;
				console.log("orderResponse.data", orderResponse.data);

				const options = {
					key: "rzp_test_ZrlDFJqqD5ZYwg",
					amount,
					currency,
					name: "Foodsafety Academy",
					description: "Test Transaction",
					order_id,
					handler: async function (response) {
						const {
							razorpay_payment_id,
							razorpay_order_id,
							razorpay_signature,
						} = response;

						// const verificationResponse = await axios.post(verifyUrl, {
						// 	order_id: razorpay_order_id,
						// 	payment_id: razorpay_payment_id,
						// 	signature: razorpay_signature,
						// });
						const verificationResponse = await postDataAPI(
							"verifypayment",
							{
								order_id: razorpay_order_id,
								payment_id: razorpay_payment_id,
								signature: razorpay_signature,
							},
							auth.token
						);

						if (verificationResponse.data.status === "success") {
							console.log("Payment successful!!!");

							try {
								const res = await postDataAPI(
									"createpayment",
									{
										userid: auth.user.id,
										orderid: razorpay_order_id,
										paymentid: razorpay_payment_id,
										items: displayContent.id,
										amount: Number(displayContent.cost),
										items_community: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
									},
									auth.token
								);
								console.log("res", res);
								if (res.status === 200) {
									// window.location.reload();
									try {
										const res = await postDataAPI(
											"createCourseRegisters",
											{
												...userData,
												courseid: displayContent.id,
												userid: auth.user.id,
											},
											auth.token
										);
										console.log("res", res);
										if (res.status === 200) {
											const res1 = await postDataAPI(
												"createusersaved",
												{
													userid: auth.user.id,
													courseid: displayContent.id,
												},
												auth.token
											);
											console.log("res", res);
											if (res1.status === 200) {
												// window.location.reload();
												window.location.href = "../mycources";
											}
										}
									} catch (err) {
										dispatch({
											type: GLOBALTYPES.ALERT,
											payload: { error: err.response.data.msg },
										});
									}
								}
							} catch (err) {
								dispatch({
									type: GLOBALTYPES.ALERT,
									payload: { error: err.response.data.msg },
								});
							}
						} else {
							alert("Payment verification failed");
						}
					},
					prefill: {
						name: auth.user.name,
						email: auth.user.email,
						contact: "9999999999",
					},
					notes: {
						address: "Some Address",
					},
					theme: {
						color: "#0db154",
					},
				};
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				const rzp = new window.Razorpay(options);
				rzp.open();
			}
			// }
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handleFeedbackSubmit = async () => {
		console.log(
			"feedback submit",
			userName,
			userPhone,
			userEmail,
			userFeedback
		);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		let item = {
			courseid: displayContent.id,
			name: userName,
			phone: userPhone,
			email: userEmail,
			feedback: userFeedback,
		};
		console.log("item", item);
		const formData = new FormData();

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("createcoursefeedback", formData);
			console.log("res", res.data);
			if (res.status === 200) {
				setUserName("");
				setUserPhone("");
				setUserEmail("");
				setUserFeedback("");
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { success: "Thank you for your feedback" },
				});

				// setTimeout(() => {

				// 	window.location.reload();
				// }, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Modal
				show={loginModel}
				onHide={() => setLoginModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<LoginRequest />
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={courseRegisterModel}
				onHide={() => setCourseRegisterModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11" style={{ paddingLeft: "0px" }}>
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
									marginBottom: "0px",
									display: "flex",
								}}>
								Register for this Course
								{/* {selectedCommunity.name} &nbsp; */}
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={() => setCourseRegisterModel(false)}>
							<img src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />

					<div style={{ padding: "15px 45px 10px" }}>
						<label for="fullname" style={{ paddingBottom: "5px" }}>
							Full Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="fullname"
							value={userData.fullname}
							className="form-control"
							placeholder="Enter Full Name"></input>
						{/* <label
							for="lastname"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Last Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="lastname"
							value={userData.lastname}
							className="form-control"
							placeholder="Enter Last Name"></input> */}
						{/* <div
							className="d-flex gap-5  mx-0 mb-1"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							<label for="name">Gender*</label>
							<label htmlFor="male">
								Male&nbsp;
								<input
									type="radio"
									id="male"
									name="gender"
									value="male"
									// defaultChecked
									onChange={handleChangeInput}
								/>
							</label>

							<label htmlFor="female">
								Female&nbsp;
								<input
									type="radio"
									id="female"
									name="gender"
									value="female"
									onChange={handleChangeInput}
								/>
							</label>
						</div> */}
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Email*
						</label>
						<input
							type="email"
							onChange={handleChangeInput}
							name="email"
							value={userData.email}
							className="form-control"
							placeholder="Enter Email"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Mobile Number*
						</label>
						{/* <input
							type="text"
							onChange={handleChangeInput}
							name="mobile"
							// style={{ paddingTop: "10px", paddingBottom: "5px" }}
							value={userData.mobile}
							className="form-control"
							placeholder="Enter Mobile Number"></input> */}
						<PhoneInput
							country={"in"} // Default country
							value={userData.mobile}
							onChange={handlePhoneChange}
							inputStyle={{
								width: "100%",
							}}
						/>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							City*
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="city"
							value={userData.city}
							placeholder="Enter City"></input>
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Country*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.country}
							name="country"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="India">India</option>
							<option value="USA">USA</option>
						</select> */}
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Occupation*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.occupation}
							name="occupation"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="Farmer">Farmer</option>
							<option value="FPO CEO">FPO CEO</option>
							<option value="Student">Student</option>
							<option value="Quality Professional">Quality Professional</option>
							<option value="Others">Others</option>
						</select>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Organization
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="organization"
							value={userData.organization}
							placeholder="Enter Organization"></input>
						{/* <input
							type="checkbox"
							// className="form-control"
							onChange={checkHandler}
							name="isChecked"
							isChecked={userData.isChecked}
							style={{
								marginTop: "10px",
								marginBottom: "5px",
								marginRight: "5px",
							}}></input>
						Click on the Box to agree to the platform terms and Privacy Policy */}
						<input
							type="checkbox"
							id="checkbox"
							style={{
								marginTop: "10px",
								marginBottom: "5px",
								marginRight: "5px",
							}}
							checked={userData.isChecked}
							onChange={checkHandler}
						/>
						{/* <label htmlFor="checkbox"> */}
						Click on the Box to agree to the platform terms and Privacy Policy{" "}
						{/* </label> */}
					</div>
					<div
						className="row"
						style={{
							padding: "15px 15px 15px 15px",
							display: "flex",
							alignItems: "center",
							background: "#F1F1F1",
							//   marginLeft: "0px",
							//   marginRight:"0px"
						}}>
						<div className="col-7"></div>
						<div className="col-5">
							<button
								className="col-12 btn-danger"
								type="submit"
								disabled={registerEnabled === false}
								onClick={handleRegisterCourse}
								style={{
									border: "1px solid #ddd",
									borderRadius: "5px",
									padding: "4px 15px 6px 20px",
									//   width: "auto",
									paddingLeft: "20px",
									color: "#fff",
									// background: "#E23F1C",
									// cursor: "pointer",
								}}>
								Register and Pay
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Navbar />
			<div className="" style={{ backgroundColor: "#fff" }}>
				<div
					className="container "
					style={{ paddingBottom: "100px", paddingTop: "100px" }}>
					<div className="row">
						<div className="col text-courseinfo">
							{displayContent.coursetitle} : {displayContent?.subcategory?.name}
						</div>
					</div>
					<hr style={{ height: "5px", marginBottom: "50px" }} />
					<div className="row">
						<div className="col-8">
							{" "}
							{(displayContent.aboutcourse !== "" ||
								displayContent.overviewfile !== "") && (
								<Accordion>
									<Accordion.Item eventKey={displayContent.aboutcourse}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div style={{ fontWeight: "500", fontSize: "18px" }}>
												{" "}
												{count++}) Course Overview
											</div>{" "}
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.aboutcourse}
											{displayContent.overviewfile !== "" && (
												<div
													onClick={() =>
														handleOpenCourseStepsInvolvedFile(
															displayContent.overviewfile
														)
													}
													style={{ cursor: "pointer" }}>
													<img
														alt="pdf"
														style={{ width: "40px", height: "50px" }}
														src="../images/PDF.svg"></img>
												</div>
											)}
											{}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{(displayContent.topics !== "" ||
								displayContent.topicsfile !== "") && (
								<Accordion style={{ marginTop: "10px" }}>
									<Accordion.Item eventKey={displayContent.topics}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div style={{ fontWeight: "500", fontSize: "18px" }}>
												{count++}) Core Topics
											</div>
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.topics}
											{displayContent.topicsfile !== "" && (
												<div
													onClick={() =>
														handleOpenCourseStepsInvolvedFile(
															displayContent.topicsfile
														)
													}
													style={{ cursor: "pointer" }}>
													<img
														alt="pdf"
														style={{ width: "40px", height: "50px" }}
														src="../images/PDF.svg"></img>
												</div>
											)}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{(displayContent.learningoutcome !== "" ||
								displayContent.outcomesfile !== "") && (
								<Accordion style={{ marginTop: "10px" }}>
									<Accordion.Item eventKey={displayContent.learningoutcome}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div style={{ fontWeight: "500", fontSize: "18px" }}>
												{count++}) Learning Outcomes
											</div>
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.learningoutcome}
											{displayContent.outcomesfile !== "" && (
												<div
													onClick={() =>
														handleOpenCourseStepsInvolvedFile(
															displayContent.outcomesfile
														)
													}
													style={{ cursor: "pointer" }}>
													<img
														alt="pdf"
														style={{ width: "40px", height: "50px" }}
														src="../images/PDF.svg"></img>
												</div>
											)}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{(displayContent.obtaining !== "" ||
								displayContent.obtainingfile !== "") && (
								<Accordion style={{ marginTop: "10px" }}>
									<Accordion.Item eventKey={displayContent.obtaining}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div style={{ fontWeight: "500", fontSize: "18px" }}>
												{count++}) Steps in obtaining the IndG.A.P certificate
												for Producer Group
											</div>
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.obtaining}
											{displayContent.obtainingfile !== "" && (
												<div
													onClick={() =>
														handleOpenCourseStepsInvolvedFile(
															displayContent.obtainingfile
														)
													}
													style={{ cursor: "pointer" }}>
													<img
														alt="pdf"
														style={{ width: "40px", height: "50px" }}
														src="../images/PDF.svg"></img>
												</div>
											)}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{displayContent.targetaudience !== "" && (
								<Accordion style={{ marginTop: "10px" }}>
									<Accordion.Item eventKey={displayContent.targetaudience}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div style={{ fontWeight: "500", fontSize: "18px" }}>
												{count++}) Target Audience
											</div>
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.targetaudience}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{(displayContent.eligibility !== "" ||
								displayContent.eligibilityfile !== "") && (
								<Accordion style={{ marginTop: "10px" }}>
									<Accordion.Item eventKey={displayContent.eligibility}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div style={{ fontWeight: "500", fontSize: "18px" }}>
												{count++}) Eligibility to take the course
											</div>
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.eligibility}
											{displayContent.eligibilityfile !== "" && (
												<div
													onClick={() =>
														handleOpenCourseStepsInvolvedFile(
															displayContent.eligibilityfile
														)
													}
													style={{ cursor: "pointer" }}>
													<img
														alt="pdf"
														style={{ width: "40px", height: "50px" }}
														src="../images/PDF.svg"></img>
												</div>
											)}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{(displayContent.howtoproceed !== "" ||
								displayContent.howtoproceedfile !== "") && (
								<Accordion style={{ marginTop: "10px" }}>
									<Accordion.Item eventKey={displayContent.howtoproceed}>
										<Accordion.Header
											style={{
												borderBottom: "1px solid #ddd",
											}}>
											<div
												style={{
													fontWeight: "500",
													fontSize: "18px",
												}}>
												{count++}) Register for this course
											</div>
										</Accordion.Header>
										<Accordion.Body
											style={{
												padding: "15px",
												borderTop: "1px solid #ddd",
											}}>
											{displayContent.howtoproceed}
											{displayContent.howtoproceedfile !== "" && (
												<div
													onClick={() =>
														handleOpenCourseStepsInvolvedFile(
															displayContent.howtoproceedfile
														)
													}
													style={{ cursor: "pointer" }}>
													<img
														alt="pdf"
														style={{ width: "40px", height: "50px" }}
														src="../images/PDF.svg"></img>
												</div>
											)}
											<div style={{ textAlign: "center" }}>
												{
													// userRole !== "Admin" &&
													// displayContent.cost === "" &&
													displayContent.isCourseSaved === false && (
														<Button
															style={{
																background: "#f96342",
																fontSize: "13px",
																borderRadius: "4px",
																border: "none",
															}}
															onClick={() => {
																addtomycoursesclick(displayContent.id);
															}}>
															{/* + Add to my courses */}
															Register to this course
														</Button>
													)
												}
												{
													// userRole !== "Admin" &&
													// displayContent.cost === "" &&
													displayContent.isCourseSaved === true && (
														<Button
															style={{
																background: "#36BB7D",
																fontSize: "13px",
																borderRadius: "4px",
																border: "none",
															}}
															// onClick={(item) => setActiveTab("List")}
															onClick={() => {
																window.location.href = "../mycources";
															}}>
															<img
																alt="mycourses"
																src="../images/Go-to-My-Courses-icon.svg"></img>{" "}
															Go to My Courses
														</Button>
													)
												}
												{/* {userRole !== "Admin" &&
															displayContent.cost !== "" && (
																<Button
																	style={{
																		background: "#f96342",
																		fontSize: "13px",
																		borderRadius: "4px",
																		border: "none",
																	}}
																	onClick={() => {
																		addtomycartclick(displayContent.id);
																	}}>
																	+ Add to cart
																</Button>
															)} */}
												<div style={{ textAlign: "left", marginTop: "5px" }}>
													<br />
													If you decide not to continue with the course, we
													would greatly appreciate your feedback to help us
													improve future offerings.
													<br />
													<br />
													Thank you for your cooperation.
													<br />
													<br />
												</div>
												<h5 style={{ textAlign: "left", marginTop: "5px" }}>
													Send your feedback:
												</h5>
												<div className="row g-3 col-12">
													<div className="col-md-6">
														<div className="form-floating">
															<input
																type="text"
																value={userName}
																name="name"
																className="form-control"
																id="name"
																placeholder="Name"
																onChange={(e) => setUserName(e.target.value)}
															/>
															<label for="name">Name</label>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating">
															<input
																type="phone"
																name="phone"
																value={userPhone}
																className="form-control"
																onChange={(e) => setUserPhone(e.target.value)}
																id="phone"
																placeholder="Phone number"
															/>
															<label for="email">Phone number</label>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating">
															<input
																type="email"
																name="email"
																value={userEmail}
																className="form-control"
																onChange={(e) => setUserEmail(e.target.value)}
																id="email"
																placeholder="Email"
															/>
															<label for="email">Email</label>
														</div>
													</div>
													<div className="col-6">
														<div className="form-floating">
															<textarea
																className="form-control"
																name="message"
																placeholder="Leave a Feedback here"
																id="message"
																value={userFeedback}
																style={{ height: "150px" }}
																onChange={(e) =>
																	setUserFeedback(e.target.value)
																}
																required=""></textarea>
															<label for="message">Feedback</label>
														</div>
													</div>

													{/* <!--<div className="col-12">-->
                            <!--    <div className="form-floating">-->
                            <!--        <div className="g-recaptcha" data-sitekey="6LfwWTwnAAAAAEewaNprSQgIb4_ykWFWioQTntjh"></div>-->
                            <!--    </div>-->
                            <!--</div>--> */}
													<div className="col-12">
														<button
															className="btn btn-primary py-1"
															name="send"
															type="submit"
															disabled={userFeedback.length === 0}
															onClick={() => handleFeedbackSubmit()}
															id="sendmsg"
															style={{
																background: "green",
																border: "none",
																borderRadius: "10px",
															}}>
															Submit Feedback
														</button>
													</div>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							)}
							{displayContent !== "" &&
								displayContent.referencelist.length > 0 && (
									<div style={{ padding: "1rem 0" }}>
										<p>
											<b>
												<u>References:</u>
											</b>
											<Table bordered style={{ textAlign: "center" }}>
												<thead>
													<tr>
														<th>S.No</th>
														{/* <th>Subject</th> */}
														<th>Document Name</th>
														<th>Source</th>
														<th>Link</th>
														<th>Document upload</th>
													</tr>
												</thead>
												<tbody>
													{displayContent !== "" &&
														displayContent.referencelist.length > 0 &&
														displayContent.referencelist.map((ref, index) => {
															return (
																<tr key={index}>
																	<td>{index + 1}</td>
																	{/* <td>{ref?.title?.name}</td> */}
																	<td>{ref?.name}</td>
																	<td>
																		{ref.source !== null &&
																			ref.source !== undefined &&
																			ref.source !== "" && (
																				<div>{ref.source}</div>
																			)}
																	</td>
																	<td>
																		{ref.link !== undefined &&
																			ref.link !== "" && (
																				<div>
																					<a
																						href={
																							(ref.link?.includes("https://")
																								? ""
																								: "http://") + ref.link
																						}
																						rel="no-referer noreferrer"
																						target="_blank">
																						{/* {ref.link} */}
																						Click Here
																					</a>
																				</div>
																			)}
																	</td>
																	<td>
																		{ref.file !== undefined &&
																			ref.file !== "" && (
																				<div>
																					<span
																						onClick={() =>
																							handleOpenCourseStepsInvolvedFile(
																								ref?.file
																							)
																						}
																						style={{ cursor: "pointer" }}>
																						<img
																							alt="pdf"
																							style={{
																								width: "40px",
																								height: "50px",
																							}}
																							src="../images/PDF.svg"></img>
																					</span>
																				</div>
																			)}
																	</td>
																</tr>
															);
														})}
												</tbody>
											</Table>
										</p>
									</div>
								)}
							{/* <Accordion style={{ marginTop: "10px" }}>
										<Accordion.Item eventKey={"reference"}>
											<Accordion.Header
												style={{
													borderBottom: "1px solid #ddd",
												}}>
												8) References
											</Accordion.Header>
											<Accordion.Body
												style={{
													padding: "15px",
													borderTop: "1px solid #ddd",
												}}>
												{/* {displayContent.howtoproceed}
												{displayContent.howtoproceedfile !== "" && (
													<div
														onClick={() =>
															handleOpenCourseStepsInvolvedFile(
																displayContent.howtoproceedfile
															)
														}
														style={{ cursor: "pointer" }}>
														<img
															alt="pdf"
															style={{ width: "40px", height: "50px" }}
															src="../images/PDF.svg"></img>
													</div>
												)} *

												{displayContent !== "" &&
													displayContent.referencelist.length > 0 &&
													displayContent.referencelist.map((ref, index) => {
														return (
															<div key={index}>
																{index + 1}.{" "}
																<span>Title: {ref.title?.name}</span>
																<div style={{ marginLeft: "17px" }}>
																	Name: {ref.name}
																</div>
																{ref.file !== "" && (
																	<div style={{ marginLeft: "17px" }}>
																		File:{" "}
																		<span
																			onClick={() =>
																				handleOpenCourseStepsInvolvedFile(
																					ref.file
																				)
																			}
																			style={{ cursor: "pointer" }}>
																			<img
																				alt="pdf"
																				style={{
																					width: "40px",
																					height: "50px",
																				}}
																				src="../images/PDF.svg"></img>
																		</span>
																	</div>
																)}
																<div style={{ marginLeft: "17px" }}>
																	Link:{" "}
																	<a
																		href={"https://" + ref.link}
																		rel="no-referer noreferrer"
																		target="_blank">
																		{ref.link}
																	</a>
																</div>
																<div style={{ marginLeft: "17px" }}>
																	Source: {ref.source}
																</div>
															</div>
														);
													})}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion> */}
						</div>
						<div className="col-4">
							<Card className="fadeup1" style={{ width: "" }}>
								<div className="" style={{ backgroundColor: "#F4F6F9" }}>
									<div
										className="container"
										style={{ marginBottom: "30px", marginTop: "30px" }}>
										<div className="card-cost3" style={{}}>
											{/* <span className="">₹</span>
											500.00 */}
											{displayContent.cost === ""
												? "Free"
												: "₹" + displayContent.cost}
										</div>
										<button
											onClick={() => {
												addtomycoursesclick(displayContent.id);
											}}
											className="courseinfo-btn">
											Register to this Course
										</button>
									</div>
								</div>

								<hr style={{ margin: "0" }} />
								<div
									className="container"
									style={{ marginBottom: "30px", marginTop: "30px" }}>
									<div className="row" style={{ paddingBottom: "10px" }}>
										<div className="col">
											<div>
												<i
													className="fa fa-language "
													style={{ paddingRight: "8px", color: "" }}></i>

												<span className="card-cost2">Language</span>
											</div>
										</div>
										<div className="col">
											<div className="card-cost1">
												{displayContent.language?.name}
											</div>
										</div>
									</div>
									{displayContent.duration !== "" && (
										<div className="row" style={{ paddingBottom: "10px" }}>
											<div className="col">
												<div>
													{" "}
													<i
														className="fa fa-clock "
														style={{ paddingRight: "8px", color: "" }}></i>
													<span className="card-cost2">Duration</span>
												</div>
											</div>
											<div className="col">
												<div className="card-cost1">
													{displayContent.duration} Hours
												</div>
											</div>
										</div>
									)}

									{displayContent.validity !== "" && (
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="button-tooltip-2">
													The course spans {displayContent.duration} hours and
													must be completed within {displayContent.validity}{" "}
													days from the date of registration.
												</Tooltip>
											}>
											<div className="row">
												<div className="col">
													<div>
														{" "}
														<i
															className="fa fa-clock "
															style={{ paddingRight: "8px", color: "" }}></i>
														<span className="card-cost2">Validity</span>
													</div>
												</div>
												<div className="col">
													<div className="card-cost1">
														{displayContent.validity} Days
													</div>
												</div>
											</div>
										</OverlayTrigger>
									)}
								</div>
								{/* </div> */}
							</Card>
							<Card
								className="fadeup1 mt-3"
								style={{ backgroundColor: "#F4F6F9" }}>
								<div
									className="container"
									style={{ marginBottom: "30px", marginTop: "30px" }}>
									<div className="row" style={{ paddingBottom: "10px" }}>
										<div className="col">
											<div>
												<h5>Your digital consulting advisor</h5>
												Upon registration, you'll access essential resources,
												including requirements, guidance on implementation,
												generic QMS manual, procedures and other documents for
												your reference. You need to customize based on your
												processes etc.
											</div>
										</div>
									</div>
								</div>
							</Card>
						</div>
						{/* <div className="col-8">

            </div> */}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default Courseinfo;
