import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const MohinderKumarSalooja = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Dr. Mohinder Kumar Salooja</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/MohinderKumarSalooja.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<ul className="ulstyle">
							<li>
								Member Scientific Panel of FSSAI: Labelling and Claim/
								Advertisement- from 1.3.2023.
							</li>
						</ul>
						<b>Past</b>
						<ul className="ulstyle">
							<li>
								Retired Professor (Dairy Technology) & Former Director, School
								of Agriculture, IGNOU, New Delhi.
							</li>
							<li>
								Capacity Building in the areas of: Dairy Technology, Food Safety
								& Entrepreneurial programs.
							</li>
							<li>
								Design, Development and Delivery of Online and Open and Distance
								Learning Programs.
							</li>
							<li>
								Senior research officer on deputation at Planning Commission
								Govt. of India as Educational planning and research.
							</li>
							<li>
								Program Coordinator for Four Programs (I ) Diploma in Dairy
								Technology; (ii) Diploma in Value Added Products from Fruits &
								Vegetables; (iii) Post Graduate Diploma in Food Safety & Quality
								Management; and (iv) Ph.D. in Dairy Science & Technology.
							</li>
							<li>
								Chairman Horticulture Cell, IGNOU in 2009 and 2018 to June,
								2019.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Professional Experience</b>
					<ul className="ulstyle">
						<li>
							An expert on temperate fruits, diseases and pest management,
							completed research work on 32 research projects funded
							internationally and nationally.
						</li>
						<li>
							Specialized in research on epidemiology, weather monitoring &
							disease forecasting methods, pesticides mode of action and
							application.
						</li>
						<li>
							Significant achievements in production of quality planting
							material of commercially important temperate fruits, medicinal
							plants, and floriculture have made.
						</li>
						<li>
							Established bud wood & Gene bank for fruits, integrated diseases,
							pests and nutrient management, use of bio-fertilizers &
							natural/organic farming, crop regulation and energy efficient
							canopy management.
						</li>
					</ul>
					<b>Acadamic Acheivments</b>
					<ul className="ulstyle">
						<li>
							Programs Designed/Developed/Coordinated/Associated under Open and
							Distance Learning (ODL) in Agriculture and Food Processing – 11
							programs.
						</li>
						<li>
							Developed online programs: PG Diplomas: Food safety and Quality
							Management and Food Science and Technology. Creation of Online
							“Safe and Assured Food E-learning” (SAFE) Platform for running PG
							Diploma in Food Safety and Quality Management and PG Diploma in
							Food Science and Technology.
						</li>
					</ul>
					<b>Awards and Recognitions</b>
					<ul className="ulstyle">
						<li>
							Jawaharlal Nehru Award (ICAR Young Scientist Award for Ph.D).
						</li>
						<li>
							Commonwealth Scholarship, Australia Plan, during Post-Doctorate.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Post Doctorate from Dairy Research Laboratory, CSIRO, Australia.
						</li>
						<li>
							Ph.D. Dairy Technology in 1983 from National Dairy Research
							Institute (NDRI).
						</li>
						<li>M.Sc. Dairy Technology.</li>
						<li>B.Sc. Dairy Technology.</li>
						<li>
							ISO 22000:2018 Lead Auditor course in 2020 from CQI-IRCA certified
							course.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default MohinderKumarSalooja;
