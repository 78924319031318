import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createPost, updatePost } from "../redux/actions/postAction";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

export default function Addpost(props) {
	const { auth, theme, status, socket } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [showNewPostModal, setShowNewPostModal] = useState(false);
	const [showAudienceModal, setShowAudienceModal] = useState(false);
	const [text, setText] = useState("");
	const [showPicker, setShowPicker] = useState(false);
	const [showSubjectModal, setShowSubjectModal] = useState(false);
	const [size, setSize] = useState("");
	const [subjects, setSubjects] = useState([]);
	const [selectedSubject, setSelectedSubject] = useState("");
	const [subject, setSubject] = useState("");
	const [postImagesArray, setPostImagesArray] = useState([]);
	const [imageToCropCoverPhoto, setImageToCropCoverPhoto] = useState(undefined);
	const [groupid, setGroupid] = useState("");

	useEffect(() => {
		getDataAPI(`getpostsubjects`, auth.token).then((res) => {
			// console.log("res getlanguages", res.data);
			// setCommunitiesArray(res.data.community);
			setSubjects(res.data.postsubjects);
		});
	}, []);

	useEffect(() => {
		setGroupid(window.location.pathname.split("/")[2]);
	}, []);

	const handleUploadSubject = async () => {
		console.log(subject);

		try {
			const res = await postDataAPI(
				"createpostsubject",
				{
					name: subject,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setSubjects((prev) => [...prev, res.data.postsubject]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}

		// courseCategorieArray.push(item);
		setShowSubjectModal(false);
		// setShowUploadModal(true);
	};

	const handleCloseNewPostModal = () => {
		setShowNewPostModal(false);
		setPostImagesArray([]);
		setText("");
		setShowPicker(false);
		setSelectedSubject("");
	};
	const handleOpenNewPostModal = () => {
		if (auth.user === undefined) {
			alert("Please login to Post!!!");
			return;
		}
		setShowNewPostModal(true);
	};

	const handleCloseAudienceModal = () => {
		setShowAudienceModal(false);
	};

	const handleOpenAudienceModal = () => {
		setShowAudienceModal(true);
		setShowNewPostModal(false);
	};

	const addEmoji = (e) => {
		let emoji = e.native;
		setText(text + emoji);
	};

	const handleRemoveItemFromArray = (item) => {
		const index = postImagesArray.indexOf(item);
		if (index > -1) {
			// only splice array when item is found
			postImagesArray.splice(index, 1); // 2nd parameter means remove one item only
		}
		setPostImagesArray([...postImagesArray]);
	};

	const inputFileCoverPhoto = useRef(null);

	const onButtonClickCoverPhoto = () => {
		// `current` points to the mounted file input element
		inputFileCoverPhoto.current.click();
	};

	const onUploadFileCoverPhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropCoverPhoto(image);
				setPostImagesArray((prev) => [...prev, image]);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const handlePostSubmit = (e) => {
		console.log("post submit", text);
		setShowNewPostModal(false);
		e.preventDefault();
		if (postImagesArray.length === 0) {
			return dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: "Add image(s)." },
			});
		}

		if (status.onEdit) {
			dispatch(
				updatePost({
					content: text,
					subject: selectedSubject,
					images: postImagesArray,
					auth,
					status,
				})
			);
		} else {
			dispatch(
				createPost({
					content: text,
					subject: selectedSubject,
					images: postImagesArray,
					groupid,
					auth,
					socket,
				})
			);
		}

		setText("");
		setPostImagesArray([]);
		// if (tracks) {
		// 	tracks.stop();
		// }
		dispatch({
			type: GLOBALTYPES.STATUS,
			payload: false,
		});
	};

	return (
		<>
			<Modal
				show={showAudienceModal}
				onHide={handleOpenAudienceModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11" style={{ paddingLeft: "0px" }}>
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
									marginBottom: "0px",
								}}>
								Select Audience
							</h2>
						</div>
						{/* <div
              className="col-1"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={handleCloseNewPostModal}
            >
              <img src="./images/closeOne.png"></img>
            </div> */}
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
					<div style={{ paddingTop: "10px" }}>
						<div style={{ fontSize: "16px", fontWeight: "400" }}>
							Who can see your post?
						</div>
						<div style={{ fontSize: "12px", marginBottom: "25px" }}>
							Your post will be visible on feed, on your profile and see in
							search results
						</div>
						<div
							className="row"
							style={{
								padding: "10px 12px",
								background: "#f1f1f1",
								borderRadius: "5px",
								marginBottom: "15px",
								border: "1px solid #ddd",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div className="col-11">
								<span>
									<img src="./images/Public_icon.svg" alt="" /> All Member
								</span>
							</div>
							<div className="col-1">
								<label>
									<input
										type="radio"
										name="audience"
										id="Public"
										value="Community Public"
										checked=""
									/>
								</label>
							</div>
						</div>
						<div
							className="row"
							style={{
								padding: "10px 12px",
								borderRadius: "5px",
								marginBottom: "15px",
								border: "1px solid #ddd",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div className="col-11">
								<span>
									<img src="./images/Announcement.svg" alt="" /> Announcement
								</span>
							</div>
							<div className="col-1">
								<label>
									<input
										type="radio"
										name="audience"
										id="announcement"
										value="Announcement"
									/>
								</label>
							</div>
						</div>
					</div>

					<div
						className="row"
						style={{
							padding: "15px 15px 15px 15px",
							display: "flex",
							alignItems: "center",
							background: "#F1F1F1",
							//   marginLeft: "0px",
							//   marginRight:"0px"
						}}>
						<div className="col-8"></div>
						<div className="col-4">
							<a
								href="javascript:void(0);"
								className="uc-share-opts"
								// onClick="addmedia()"
								style={{
									border: "1px solid #ddd",
									padding: "6px 15px",
									borderRadius: "15px",
									background: "#fff",
									marginRight: "10px",
								}}
								onClick={(e) => {
									setShowAudienceModal(false);
									setShowNewPostModal(true);
								}}>
								<span style={{ color: "#000", paddingLeft: "5px" }}>Back</span>
							</a>
							<span
								className="col-1"
								style={{
									//   border: "1px solid #ddd",
									borderRadius: "15px",
									padding: "4px 15px 6px 20px",
									//   width: "auto",
									paddingLeft: "20px",
									color: "#fff",
									background: "#36BB7D",
								}}>
								Save
							</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showSubjectModal}
				onHide={() => setShowSubjectModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Subject
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setShowSubjectModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Subject:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Subject"
								onChange={(e) => setSubject(e.target.value)}></input>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={subject === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handleUploadSubject();
							}}>
							Add
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showNewPostModal}
				onHide={handleOpenNewPostModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								New Post
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseNewPostModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
					<div className="row" style={{ padding: "10px 0px 0px 0px" }}>
						{/* <div
							className="col-2"
							style={{ width: "auto", display: "flex", alignItems: "center" }}>
							{/* <img
								alt="krishigap logo"
								src="../images/krishigaplogo.png"
								width="40px"></img> */}
						{/* <Avatar src={""} size="medium-avatar2" /> *
						</div> */}
						{/* <div className="col-4">
							<div
								style={{
									fontSize: "15px",
									fontWeight: "500",
									marginLeft: "15px",
								}}>
								{auth.user?.fullname}
							</div>
							{/* <div className="uc-com-icon" onClick={handleOpenAudienceModal}>
								<div id="communityicon"></div>
								<a
									href="javascript:void(0)"
									className="uc-prof-in uc-post-audience-modal-toggle allmember"
									// className="allmember"
								>
									<img
										id="audience-icon"
										src="../images/Public_icon.svg"
										alt=""
									/>
									<p id="audience-text">All Member</p>
									<span className="uc-right-arrow"></span>
								</a>
							</div> *
						</div> */}

						<div className="row" style={{ padding: "15px 0px 10px 30px" }}>
							<div style={{ marginBottom: "15px", paddingLeft: "0" }}>
								<span style={{ fontSize: "13px" }}>Subject:</span>
								<select
									name="language"
									style={{ marginLeft: "15px" }}
									value={selectedSubject}
									onChange={(e) => setSelectedSubject(e.target.value)}>
									<option value="">Select</option>
									{subjects.map((e) => {
										return (
											<option value={e?.name} key={e?.id}>
												{e?.name}
											</option>
										);
									})}
								</select>
								{props.userRole === "Admin" && (
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												// props.languages();
												setShowSubjectModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								)}
							</div>
							<textarea
								type="text"
								value={text}
								onChange={(e) => setText(e.target.value)}
								placeholder="Share something interesting / informative"
								style={{ border: "1px solid #ddd" }}
								rows="10"></textarea>
							<div>
								<br />
							</div>

							<Button onClick={() => setShowPicker(!showPicker)}>
								{showPicker ? "Close Emoji Picker" : "Open Emoji Picker"}
							</Button>
							{/* <div>
                <br />
              </div> */}
							{showPicker && <Picker onSelect={addEmoji} />}
						</div>
						<div
							className="row"
							style={{ background: "#ddd", marginLeft: "0px" }}>
							{postImagesArray.length > 0 &&
								postImagesArray.map((e, index) => {
									return (
										<>
											<div
												key={index}
												className="col-4"
												style={{
													padding: "5px",
													//   background: "rgba(0, 0, 0, 0.6)",
												}}>
												{" "}
												<div>
													<span
														style={{
															textAlign: "end",
															display: "flex",
															justifyContent: "end",
															alignItems: "end",
															cursor: "pointer",
														}}
														onClick={() => handleRemoveItemFromArray(e)}>
														<img alt="close" src="../images/closeOne.png"></img>
													</span>

													<img
														alt="postimages"
														src={e}
														style={{
															width: "100%",
															height: "50%",
															background: "#000",
															objectFit: "contain",
														}}></img>
												</div>
											</div>
										</>
									);
								})}
						</div>
						<div
							className="row"
							style={{
								padding: "15px 15px 15px 15px",
								display: "flex",
								alignItems: "center",
								background: "#F1F1F1",
								marginLeft: "0px",
							}}>
							<div className="col-10">
								<input
									type="file"
									accept="image/png, image/gif, image/jpeg"
									id="file"
									ref={inputFileCoverPhoto}
									style={{ display: "none" }}
									onChange={onUploadFileCoverPhoto}
								/>
								<button
									onClick={onButtonClickCoverPhoto}
									style={{
										background: "#fff",
										border: "none",
										padding: "5px",
										borderRadius: "5px",
									}}>
									<img
										src="../images/media_f_icon.svg"
										alt="Media"
										style={{ marginTop: "-3px" }}
										//   className="uc-color"
									/>
									<span style={{ color: "#000", paddingLeft: "5px" }}>
										Add Media
									</span>
								</button>
								{/* <a
                  href="javascript:void(0);"
                  className="uc-share-opts"
                  // onClick="addmedia()"
                  style={{
                    border: "1px solid #ddd",
                    padding: "6px 15px",
                    borderRadius: "15px",
                    background: "#fff",
                  }}
                >
                  <img
                    src="../images/media_f_icon.svg"
                    alt="Media"
                    style={{ marginTop: "-3px" }}
                    //   className="uc-color"
                  />
                  <span style={{ color: "#000", paddingLeft: "5px" }}>
                    Add Media
                  </span>
                </a> */}
							</div>

							<button
								className="col-1"
								style={{
									border: "1px solid #ddd",
									borderRadius: "15px",
									padding: "2px 15px",
									width: "auto",
									paddingLeft: "20px",
									color: "#fff",
									background: "#36BB7D",
								}}
								onClick={(e) => handlePostSubmit(e)}>
								Post
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<div
				className="row card"
				style={{ borderRadius: "15px", cursor: "pointer" }}
				onClick={handleOpenNewPostModal}>
				<div
					className="row"
					style={{
						padding: "20px 15px",
						display: "flex",
						alignItems: "center",
						borderBottom: "1px solid #ddd",
						boxShadow: "0px 3px 3px -1px rgb(50 50 50 / 15%)",
						marginLeft: "0px",
					}}>
					{/* <div className="col-1">
						{/* <img
							src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/208352994771.png"
							alt=""
							width="40px"
							height="40px"
							// onError="this.onerror=null;this.src=`https://www.ulektz.com/assets/img/header/common-user.svg`;"
						/> *
						<Avatar src={""} size="medium-avatar2" />
					</div> */}
					<div
						className="col-10"
						style={{
							fontSize: "18px",
							fontWeight: "400",
							// marginLeft: "10px",
						}}>
						Share something interesting / informative...
					</div>
				</div>
				<div
					className="row"
					style={{
						padding: "8px 8px",
						display: "flex",
						alignItems: "center",
					}}>
					<div className="col-10" style={{ marginRight: "20px" }}>
						<a
							href="javascript:void(0);"
							className="uc-share-opts"
							// onClick="addmedia()"
							style={{
								border: "1px solid #ddd",
								padding: "6px 15px",
								borderRadius: "15px",
							}}>
							<img
								src="../images/media_f_icon.svg"
								alt="Media"
								style={{ marginTop: "-3px" }}
								//   className="uc-color"
							/>
							<span style={{ color: "#000", paddingLeft: "5px" }}>
								Add Media
							</span>
						</a>
					</div>

					<div
						className="col-1"
						style={{
							border: "1px solid #ddd",
							borderRadius: "15px",
							padding: "2px 15px",
							width: "auto",
							paddingLeft: "20px",
						}}>
						Post
					</div>
				</div>
			</div>
		</>
	);
}
