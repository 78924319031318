import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const BaskarKotte = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />

			<div className="container">
				<center>
					<div>
						<h2 className="heading-p1">Mr. Baskar Kotte</h2>
					</div>
					<div>
						<h6 className="heading-p2">International Standards Specialist</h6>
					</div>
					<div>
						<img className="image" src="../images/BaskarKotte.jpg" alt="" />
					</div>
				</center>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Founder, Quality Systems Enhancement Inc, USA.</b>
					<ul className="ulstyle">
						<li>
							Founder and President of Quality Systems Enhancement, Inc., of
							Roswell, GA. United States . An auditing, consulting and training
							organization dedicated to QMS, OHSMS, EMS, EnMS, Lean, Six Sigma
							and Operational Excellence.
						</li>
						<li>
							Original and active member of US Technical Advisory Groups ISO/TC
							176, TC 207 and TC 242, the technical committees responsible for
							writing ISO 9000 family of Quality Management Standards, ISO 14000
							series of Environmental Management Standards and ISO 50000 family
							of Energy Management System Standards. Chair SC3 for US TAG ISO/TC
							176 and ISO/TC 176 Liaison to International Accreditation
							Forum(IAF).
						</li>
						<li>
							KSenior member of American Society for Quality, TAPPI and
							associate member of AICC. Served as the Standards Committee Chair
							of the ASQ Quality Audit Division.
						</li>
						<li>
							Assisted over 800 facilities for ISO Related Certifications in
							North America
						</li>
						<li>
							Assisted in developing, implementing and achieving certification
							thorough a proven discipline path for the following standards: ISO
							9001, ISO 13485, ISO 14001, ISO 15189, ISO 15378, IATF 16949
							(formerly ISO/TS 16949), ISO/IEC 17025, ISO 45001(formerly OHSAS
							18001), ISO 22000, ISO 27001, ISO 50001, ISO 55001, AS 9100 and TL
							9100, Global Gap, FSSC 22000, FDA Gluten Free Labeling &
							Certification, HACCP, HARPC, Hygiene Excellence & Sanitation
							Excellence and PCQI
						</li>
					</ul>
					<b>Some of Honors and Awards</b>
					<ul className="ulstyle">
						<li>
							U.S. Delegate for ISO 10019 guidelines for selection and use of
							QMS consultants.
						</li>
						<li>
							U.S Delegate for 10004 guidelines for monitoring and measuring
							customer satisfaction
						</li>
						<li>
							U.S Delegate for 10008 guidelines for Customer Satisfaction-
							Guidelines for business-to-consumer electronic transactions
						</li>
						<li>
							Member of the TC176 Chairman’s Strategic Advisory Group (Joint
							Group with ISO/CASCO and the IAF) July 2010-July 2014, Geneva,
							Switzerland
						</li>
						<li>
							Chair Subcommittee 3 (SC3) and Membership Chair for United States
							Technical Advisory Group ISO/ TC 176
						</li>
					</ul>
					<b>Certifications</b>
					<ul className="ulstyle">
						<li>Certified Quality Engineer (CQE)</li>
						<li>Certified Quality Auditor (CQA)</li>
						<li>Exemplar Global Certified QMS Lead Auditor</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default BaskarKotte;
