import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF } from "../utils/fetchData";
import "./pdfreaderstyles.css";
import PdfViewer from "./pdfviewer";

const InstructorMaterial = () => {
	const { profile, auth } = useSelector((state) => state);
	console.log("auth", auth);
	const dispatch = useDispatch();
	const [pdfFile, setPdfFile] = useState("");
	const [data, setData] = useState(null);
	const [viewChange, setViewChange] = useState("");
	const [startTest, setStartTest] = useState("");
	const [sections, setSections] = useState([]);

	useEffect(() => {
		// if (auth.token) {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		// console.log('auth.user.id', auth.user.id)
		getDataAPI(
			`getCourseById/${window.location.pathname.split("/")[2]}/${
				auth?.user?.id
			}`
		).then((res) => {
			console.log("res my communities", res.data.course);
			if (res.data.courses !== null) {
				setData(res.data.course);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			}
		});
		// console.log("data", data);
		// }
	}, []);

	useEffect(() => {
		let tempSections = [];
		if (data !== null) {
			data.textlist.forEach((value) => {
				if (!tempSections.includes(value.section)) {
					tempSections.push(value.section);
				}
			});
		}
		console.log("tempSections", tempSections);
		setSections(tempSections);
	}, [data]);

	const [selectedOptions, setSelectedOptions] = useState({});

	const handleOptionChange = (questionIndex, selectedOption) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			[questionIndex]: selectedOption,
		}));
	};

	const [started, setStarted] = useState(false);
	const [results, setResults] = useState(null);
	const [score, setScore] = useState(0);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			setPdfFile(fileURL);
			// window.open(fileURL);
			setViewChange(false);
			setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};
	return (
		<div className="App pdf-container">
			<div
				style={{
					color: "#fff",
					background: "#0C67DA",
					textAlign: "center",
					padding: "10px",
					fontSize: "23px",
				}}>
				{data && data.coursetitle}
			</div>
			<div className="row">
				{/* <div className="col-1"></div> */}
				<div className="col-3" style={{ background: "#000" }}>
					<div style={{ padding: "25px" }}>
						<div className="pt-4">
							<img
								src={data && data.courseimage}
								style={{ width: "100%" }}></img>
						</div>
						<div
							style={{
								fontSize: "23px",
								fontWeight: "500",
								minHeight: "36px",
								color: "#fff",
								paddingTop: "10px",
							}}>
							{data && data.coursetitle}
						</div>
					</div>
					<div
						style={{
							color: "#fff",
							background: "#0C67DA",
							textAlign: "center",
							padding: "10px",
							marginRight: "-12px",
						}}>
						Course Study Materials
					</div>
					<div>
						{/* <Accordion style={{ marginRight: "-12px" }}>
							{sections.map((c) => {
								return (
									data &&
									data.files.map((e, index) => {
										if (e. === c) {
											return (
												<Fragment key={index}>
													<Accordion.Item eventKey={index}>
														<Accordion.Header
															style={
																{
																	// marginBottom: "10px",
																	// borderBottom: "1px solid #ddd",
																}
															}>
															{e.name}
														</Accordion.Header>
														<Accordion.Body
															style={{
																// padding: "5px",
																// borderTop: "1px solid #ddd",
																background: "#fff",
															}}>
															<div
																onClick={() => {
																	fileConversion(e.file);
																}}
																style={{ cursor: "pointer" }}>
																<img
																	alt="pdf"
																	src="../images/PDF.svg"
																	style={{ height: "20px" }}></img>
																<span
																	style={{ color: "green", paddingLeft: "10px" }}>
																	{e.name}
																</span>
															</div>
														</Accordion.Body>
													</Accordion.Item>
												</Fragment>
											);
										}
									})
								);
							})}
							<Accordion.Item eventKey={data && data.files.length}>
								<Accordion.Header
									style={
										{
											// marginBottom: "10px",
											// borderBottom: "1px solid #ddd",
										}
									}>
									<p style={{ fontWeight: 900, color: "#36BB7D" }}>
										Final Assessment
									</p>
								</Accordion.Header>
								<Accordion.Body
									style={{
										// padding: "5px",
										// borderTop: "1px solid #ddd",
										background: "#fff",
									}}>
									<div
										onClick={() => setViewChange(true)}
										style={{ cursor: "pointer" }}>
										{/* <img
                      src="./images/PDF.svg"
                      style={{ height: "20px" }}
                    ></img>
										<span style={{ color: "green", paddingLeft: "10px" }}>
											Final Assessment
										</span>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion> */}

						{sections.map((c) => {
							if (c !== "") {
								return (
									<>
										<Accordion
											style={{
												margin: "10px 0",
											}}>
											<Accordion.Item eventKey={c}>
												<Accordion.Header
													style={{
														borderBottom: "1px solid #ddd",
													}}>
													<div
														style={{
															fontWeight: 500,
															fontSize: "18px",
														}}>
														{c}
													</div>
												</Accordion.Header>

												<Accordion.Body
													style={{
														padding: "15px",
														borderTop: "1px solid #ddd",
														// display: "flex",
													}}>
													{data.textlist.map((e, index) => {
														if (e.section === c) {
															return (
																<Accordion
																	key={index}
																	style={{ marginBottom: "5px" }}>
																	<Accordion.Item eventKey={index}>
																		<Accordion.Header>
																			<div
																				style={{
																					fontSize: "18px",
																					fontWeight: 500,
																				}}>
																				{e.name}
																			</div>
																		</Accordion.Header>
																		<Accordion.Body
																			style={{
																				// padding: "5px",
																				// borderTop: "1px solid #ddd",
																				background: "#fff",
																			}}>
																			<div
																				onClick={() => {
																					fileConversion(e.file);
																				}}
																				style={{ cursor: "pointer" }}>
																				<img
																					alt="pdf"
																					src="../images/PDF.svg"
																					style={{ height: "20px" }}></img>
																				<span
																					style={{
																						color: "green",
																						paddingLeft: "10px",
																					}}>
																					{e.name}
																				</span>
																			</div>
																		</Accordion.Body>
																	</Accordion.Item>
																</Accordion>
															);
														}
													})}
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</>
								);
							} else {
								return data.textlist.map((e, index) => {
									if (e.section === c) {
										return (
											<>
												<Accordion key={index} style={{ margin: "10px 0" }}>
													<Accordion.Item eventKey={index}>
														<Accordion.Header>
															<div
																style={{
																	fontSize: "18px",
																	fontWeight: 500,
																}}>
																{e.name}
															</div>
														</Accordion.Header>
														<Accordion.Body
															style={{
																// padding: "5px",
																// borderTop: "1px solid #ddd",
																background: "#fff",
															}}>
															<div
																onClick={() => {
																	fileConversion(e.file);
																}}
																style={{ cursor: "pointer" }}>
																<img
																	alt="pdf"
																	src="../images/PDF.svg"
																	style={{ height: "20px" }}></img>
																<span
																	style={{
																		color: "green",
																		paddingLeft: "10px",
																	}}>
																	{e.name}
																</span>
															</div>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</>
										);
									}
								});
							}
						})}
					</div>
				</div>
				<div className="col-9">
					{/* <h1>PDF Viewer</h1> */}
					{viewChange === false && <PdfViewer pdfFile={pdfFile} />}
				</div>
			</div>
		</div>
	);
};

export default InstructorMaterial;
