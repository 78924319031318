import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const SrihariKotela = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="container">
				<center>
					<div>
						<h2 className="heading-p1">Srihari Kotela</h2>
					</div>
					<div>
						<h6 className="heading-p2">Founder</h6>
					</div>
					<div>
						<img className="image" src="../images/shri-hari.jpg" alt="" />
					</div>
				</center>
			</div>
			<div className="row">
				<div className="col containerin">
					<ul className="ulstyle">
						<li>
							Chairperson, India Good Agricultural Practices Certification
							Committee, appointed by the Quality Council of India (QCI), an
							autonomous body under the Ministry of Commerce, Govt of India.
						</li>
						<li>
							<b>Award Recipient</b>: Honored with the prestigious Lifetime
							Achievement Award by the President of the United States for
							lifelong dedication to strengthening the nation through volunteer
							service.
						</li>
						<li>
							ISO 17065, Technical Expert for India Good Agricultural Practices
						</li>
						<li>
							<b>Founder</b>: Food Cert India, a former leading accredited
							inspection and certification body for global food safety and
							sustainability standards. The company was successfully acquired by
							TATA Projects Ltd., a TATA group company and now operates as TQ
							Cert Services Pvt. Ltd.(www.tqcert.in)
						</li>
						<li>
							<b>Former Committee Member</b>: Contributed to several expert
							committees formed by prominent organizations such as the Bureau of
							Indian Standards (BIS), Food Safety and Standards Authority of
							India (FSSAI), Quality Council of India, Department of Ayush
							(Government of India), and the Confederation of Indian Industry
							(CII). These roles involved the development of key standards,
							including:
						</li>
						<li style={{ marginLeft: "40px" }}>National Agricultural Code</li>
						<li style={{ marginLeft: "40px" }}>Good Hygiene Practices</li>
						<li style={{ marginLeft: "40px" }}>
							Retail and Animal Husbandry Practices
						</li>
						<li style={{ marginLeft: "40px" }}>
							Basic Food Safety Management Systems for food establishments
						</li>
						<li style={{ marginLeft: "40px" }}>
							Certification Procedures for professionals in food safety
							compliance
						</li>
						<li style={{ marginLeft: "40px" }}>
							Globally competitive horticulture chains
						</li>
						<li style={{ marginLeft: "40px" }}>
							Voluntary Certification Mark for the food processing sector
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default SrihariKotela;
