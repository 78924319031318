import React from "react";
import { Link } from "react-router-dom";
import "../Menu.css";
import Footer from "./footer";
import Navbar from "./navbar";

const Lostpassword = () => {
	return (
		<>
			<Navbar />
			<div id="testlogin" className="" style={{ backgroundColor: "white" }}>
				<div className="spacer"></div>
				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 block">
						<p className="heading-1">
							Please enter your username or email address. You will receive a
							link to create a new password via email.
						</p>
						<form>
							<div className="row">
								<label className="email-label-3">
									Username or Email address{" "}
									<span style={{ color: "rgb(114, 28, 36)" }}>*</span>
								</label>
								<div className="email-input-3">
									<input className="email-input-3" type="email" />
								</div>
							</div>

							<button className="GetNewpassword" type="submit">
								Get New Password
							</button>
						</form>
					</div>

					<div className="col-1"></div>
				</div>
				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 text-left join">
						<Link to="/login">
							<button className="Lost-button" type="button">
								Login
							</button>
						</Link>
						{" | "}
						<Link to="/register">
							<button className="Lost-button" type="button">
								Join Now
							</button>
						</Link>
					</div>
					<div className="col-1"></div>
				</div>
				<div className="spacer"></div>
			</div>
			<Footer />
		</>
	);
};
export default Lostpassword;
