import {
	faPaperPlane,
	faPhone,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "../Menu.css";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const JobSeeker = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		mobile: "",
		gender: "Gender",
		profilepic: "",
		linkedin_profile: "",
		educationalqualifications: "",
		f_s_s_intersted: "Food Safety Standard Interested",
		employmenttype: "Employment Type",
		jobtype: "Job Type",
		city: "",
		country: "",
		salaryrange: "",
		areasofexpertise: "",
		yearsofexperience: "",
		availability: "Availability",
		additionalinformation: "",
		resume: "",
	});

	const genderRef = useRef(null);
	const f_s_s_interstedRef = useRef(null);
	const employmentRef = useRef(null);
	const jobRef = useRef(null);
	const availabilityRef = useRef(null);
	const dispatch = useDispatch();

	const [genderOpen, setIsgenderOpen] = useState(false);
	const [isf_s_s_interstedOpen, setIsf_s_s_interstedOpen] = useState(false);
	const [empOpen, setIsempOpen] = useState(false);
	const [jobOpen, setIsjobOpen] = useState(false);
	const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false);
	const [alljobs, setAlljobs] = useState([]);
	const [jobseekersCount, setJobseekersCount] = useState("");
	const [searchTerm, setSearchTerm] = useState("");

	const genderOptions = ["Gender", "Male", "Female"];
	const f_s_s_interstedOptions = [
		"USDA NOP",
		"Organic NPOP",
		"GlobalG.A.P.",
		"ISO 9001",
		"ISO 22000",
		"FSSC 22000",
		"FSSAI",
		"ISO 14001",
		"USFDA PCQI",
	];

	const empOptions = [
		"Employment Type",
		"Full - Time",
		"Part - Time",
		"Contract",
		"Internship",
	];
	const jobOptions = ["Job Type", "On - Site", "Remote", "Hybrid"];

	const availabilityOptions = [
		"Availability",
		"Immediate",
		"30 Days",
		"90 Days",
	];

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`getJobseekersCount`).then((res) => {
			console.log("res getJobseekersCount", res.data);
			setJobseekersCount(res.data.jobseekersdetails.total);
		});

		(async () => {
			try {
				const res = await getDataAPI(`getAllJobs`);
				console.log("res", res.data);
				setAlljobs(res.data.jobs);
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { loading: false },
				});
			} catch (err) {
				console.error("Error fetching job seekers:", err);
			}
		})();
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (genderRef.current && !genderRef.current.contains(event.target)) {
				setIsgenderOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				f_s_s_interstedRef.current &&
				!f_s_s_interstedRef.current.contains(event.target)
			) {
				setIsf_s_s_interstedOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				employmentRef.current &&
				!employmentRef.current.contains(event.target)
			) {
				setIsempOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (jobRef.current && !jobRef.current.contains(event.target)) {
				setIsjobOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				availabilityRef.current &&
				!availabilityRef.current.contains(event.target)
			) {
				setIsAvailabilityOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleFileChange = (event) => {
		// setFormData((prevState) => ({
		// 	...prevState,
		// 	profilepic: e.target.files[0],
		// }));
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				// setCourseImage(e.target.result);
				setFormData((prevState) => ({
					...prevState,
					profilepic: e.target.result,
				}));
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const toggleGenderDropdown = () => {
		setIsgenderOpen(!genderOpen);
	};

	const handlegenderSelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			gender: option,
		}));
		setIsgenderOpen(false);
	};

	const togglef_s_s_interstedDropdown = () => {
		setIsf_s_s_interstedOpen(!isf_s_s_interstedOpen);
	};

	const handlef_s_s_interstedSelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			f_s_s_intersted: option,
		}));
		setIsf_s_s_interstedOpen(false);
	};

	const toggleEmploymentDropdown = () => {
		setIsempOpen(!empOpen);
	};

	const handleEmploymentSelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			employmenttype: option,
		}));
		setIsempOpen(false);
	};

	const toggleJobDropdown = () => {
		setIsjobOpen(!jobOpen);
	};

	const handleJobSelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			jobtype: option,
		}));
		setIsjobOpen(false);
	};

	const toggleAvailabilityDropdown = () => {
		setIsAvailabilityOpen(!isAvailabilityOpen);
	};

	const handleAvailabilitySelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			availability: option,
		}));
		setIsAvailabilityOpen(false);
	};

	const handleResumeChange = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			// const url = URL.createObjectURL(file);
			// console.log(url);

			// setObtainingFile(url);
			setFormData((prevState) => ({
				...prevState,
				resume: file,
			}));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("formData", formData);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		const sendData = new FormData();
		if (formData.resume !== "") {
			sendData.append("files", formData.resume);
		}
		for (var key in formData) {
			sendData.append(
				key,
				typeof formData[key] === "object"
					? JSON.stringify(formData[key])
					: formData[key]
			);
		}

		try {
			const res = await postDataAPI("createjobseekers", sendData);
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering. We will get back to you soon");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const searchedData = alljobs.filter(
		(jobseeker) =>
			jobseeker.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
			jobseeker.employer.organisationname
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			jobseeker.experiencelevel
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			jobseeker.f_s_s_intersted
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			jobseeker.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
			jobseeker.country.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="tab-m" style={{}}>
				<div id="Tab">
					<ul className="nav1 nav-tabs1">
						<li className="active">
							<a href="#1" data-toggle="tab">
								Job Postings
							</a>
						</li>
						<li>
							<a href="#2" data-toggle="tab">
								Submit your application
							</a>
						</li>
						{/* <li>
								<a href="#3" data-toggle="tab">
									eBooks
								</a>
							</li> */}
					</ul>
				</div>
			</div>
			<div className="tab-content ">
				<div className="tab-pane active" id="1">
					<div className="" style={{ padding: "100px 30px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<h4 className="instructorlist-text"></h4>
							<input
								type="text"
								placeholder="Search"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className="mb-4 p-2 border border-gray-300 rounded"
							/>
						</div>
						{searchedData.map((job) => (
							<div key={job.id} className="col-12" style={{ padding: "10px" }}>
								<div className="card" style={{ padding: "5px" }}>
									<div className="row">
										<div className="col-9 listofcourses-format">
											<div
												style={{
													fontSize: "20px",
													fontWeight: "500",
													minHeight: "36px",
												}}>
												<label>{job.title}</label>
											</div>
											<div style={{ fontSize: "15px" }}>
												<div>
													Organisation: <b>{job.employer.organisationname}</b>
												</div>
												<div>
													Food Safety Standard Interested:{" "}
													<b>{job.f_s_s_intersted}</b>
												</div>
												<div>
													Experience level: <b>{job.experiencelevel}</b>
												</div>
												<div>
													Work location: <b>{job.city + ", " + job.country}</b>
												</div>
											</div>
										</div>
										<div className="col-3 listofcourses-format">
											<a href={`/jobinfo/${job.id}`}>
												<button
													className="addinstructor-btn"
													style={{
														background: "rgb(30 243 164)",
														border: "none",
														width: "150px",
													}}>
													View Job
												</button>
											</a>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="tab-pane" id="2">
					<div className="" style={{ padding: "100px 30px" }}>
						{/* <h4 className="instructorlist-text">List Of Job Seekers</h4> */}
						<div className="js">
							<div className="js-box">
								{/* <div className="js-heading">
									<h2>Job Seeker</h2>
								</div> */}
								<br />
								<div className="js-heading">
									<h3>Total Applications: {jobseekersCount}</h3>
								</div>
								<div className="js-boxIn">
									<div className="js-boxIn-content">
										<div className="js-greenbox">
											<div>
												<h4 className="js-greenbox-heading">Fill the form</h4>
											</div>
											<div>
												<form onSubmit={handleSubmit}>
													<h4 className="emp-greenbox-heading">
														1. Contact Information
													</h4>
													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="name"
																value={formData.name}
																onChange={handleInputChange}
																placeholder="Full Name"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="email"
																name="email"
																value={formData.email}
																onChange={handleInputChange}
																placeholder="Email"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faPaperPlane} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<div
																className="js-dropdown-container"
																ref={genderRef}>
																<div className="js-dropdown">
																	<div
																		className="js-dropdown-header"
																		onClick={toggleGenderDropdown}>
																		{formData.gender}
																		<span
																			className={`js-dropdown-symbol ${
																				genderOpen ? "open" : ""
																			}`}
																		/>
																	</div>

																	{genderOpen && (
																		<ul className="js-dropdown-list">
																			{genderOptions.map((option, index) => (
																				<li
																					key={index}
																					className="js-dropdown-item"
																					onClick={() =>
																						handlegenderSelect(option)
																					}>
																					{option}
																				</li>
																			))}
																		</ul>
																	)}
																</div>
															</div>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<span>Profile pic (Optional):</span>
															<input
																style={{ paddingLeft: "0px" }}
																type="file"
																onChange={(e) => handleFileChange(e)}
																accept="image/*"
															/>
														</span>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="tel"
																name="mobile"
																value={formData.mobile}
																onChange={handleInputChange}
																placeholder="Phone Number"
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faPhone} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="linkedin_profile"
																value={formData.linkedin_profile}
																onChange={handleInputChange}
																placeholder="LinkedIn Profile"
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<h4 className="emp-greenbox-heading">
														2. Submit a Job Profile
													</h4>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="educationalqualifications"
																value={formData.location}
																onChange={handleInputChange}
																placeholder="Educational Qualifications"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<div
																className="js-dropdown-container"
																ref={f_s_s_interstedRef}>
																<div className="js-dropdown">
																	<div
																		className="js-dropdown-header"
																		onClick={togglef_s_s_interstedDropdown}>
																		{formData.f_s_s_intersted}
																		<span
																			className={`js-dropdown-symbol ${
																				isf_s_s_interstedOpen ? "open" : ""
																			}`}
																		/>
																	</div>

																	{isf_s_s_interstedOpen && (
																		<ul className="js-dropdown-list">
																			{f_s_s_interstedOptions.map(
																				(option, index) => (
																					<li
																						key={index}
																						className="js-dropdown-item"
																						onClick={() =>
																							handlef_s_s_interstedSelect(
																								option
																							)
																						}>
																						{option}
																					</li>
																				)
																			)}
																		</ul>
																	)}
																</div>
															</div>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<div
																className="js-dropdown-container"
																ref={employmentRef}>
																<div className="js-dropdown">
																	<div
																		className="js-dropdown-header"
																		onClick={toggleEmploymentDropdown}>
																		{formData.employmenttype}
																		<span
																			className={`js-dropdown-symbol ${
																				empOpen ? "open" : ""
																			}`}
																		/>
																	</div>

																	{empOpen && (
																		<ul className="js-dropdown-list">
																			{empOptions.map((option, index) => (
																				<li
																					key={index}
																					className="js-dropdown-item"
																					onClick={() =>
																						handleEmploymentSelect(option)
																					}>
																					{option}
																				</li>
																			))}
																		</ul>
																	)}
																</div>
															</div>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<div
																className="js-dropdown-container"
																ref={jobRef}>
																<div className="js-dropdown">
																	<div
																		className="js-dropdown-header"
																		onClick={toggleJobDropdown}>
																		{formData.jobtype}
																		<span
																			className={`js-dropdown-symbol ${
																				jobOpen ? "open" : ""
																			}`}
																		/>
																	</div>

																	{jobOpen && (
																		<ul className="js-dropdown-list">
																			{jobOptions.map((option, index) => (
																				<li
																					key={index}
																					className="js-dropdown-item"
																					onClick={() =>
																						handleJobSelect(option)
																					}>
																					{option}
																				</li>
																			))}
																		</ul>
																	)}
																</div>
															</div>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<h6 className="emp-greenbox-heading">
														Work Location
													</h6>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="city"
																value={formData.city}
																onChange={handleInputChange}
																placeholder="City"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="country"
																value={formData.country}
																onChange={handleInputChange}
																placeholder="Country"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="salaryrange"
																value={formData.salaryrange}
																onChange={handleInputChange}
																placeholder="Salary Range(Optional)"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="areasofexpertise"
																value={formData.areasofexpertise}
																onChange={handleInputChange}
																placeholder="Areas of Expertise"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="yearsofexperience"
																value={formData.yearsofexperience}
																onChange={handleInputChange}
																placeholder="Years of Experience"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<div
																className="js-dropdown-container"
																ref={availabilityRef}>
																<div className="js-dropdown">
																	<div
																		className="js-dropdown-header"
																		onClick={toggleAvailabilityDropdown}>
																		{formData.availability}
																		<span
																			className={`js-dropdown-symbol ${
																				isAvailabilityOpen ? "open" : ""
																			}`}
																		/>
																	</div>

																	{isAvailabilityOpen && (
																		<ul className="js-dropdown-list">
																			{availabilityOptions.map(
																				(option, index) => (
																					<li
																						key={index}
																						className="js-dropdown-item"
																						onClick={() =>
																							handleAvailabilitySelect(option)
																						}>
																						{option}
																					</li>
																				)
																			)}
																		</ul>
																	)}
																</div>
															</div>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>
													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																name="additionalinformation"
																value={formData.additionalinformation}
																onChange={handleInputChange}
																placeholder="Additional Information"
																required
															/>
														</span>
														<div className="js-Icon">
															<FontAwesomeIcon icon={faUser} />
														</div>
													</div>
													<span>Note: Upload resume in pdf format</span>
													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<span>Resume:</span>
															<input
																style={{ paddingLeft: "0px" }}
																type="file"
																onChange={(e) => handleResumeChange(e)}
																accept="application/pdf"
															/>
														</span>
													</div>
													<button type="submit" className="js-submitbtn">
														SUBMIT
													</button>
												</form>
											</div>
										</div>
									</div>
									<div className="js-boxIn-Image">
										<div className="js-boxIn-ImageIn">
											<img src="../images/job.png" alt="job" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default JobSeeker;
