import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const JoginderSinghMinhas = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Joginder Singh Minhas</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/Joginder-Singh-Minhas.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<ul className="ulstyle">
							<li>Project Manager, CIP-APART Potato Value Chain,</li>
							<li>International Potato Center (CIP),</li>
						</ul>
						<b>Past</b>
						<ul className="ulstyle">
							<li>
								Chief Executive Officer, Jalandhar Potato Growers Biotech
								Producer Company Ltd, Lead a group of potato seed producers for
								quality seed production through geoponics technology and through
								good agricultural practices. Helped potato seed farmers in
								establishing a 2 million tuber capacity aeroponics facility in
								Jalandhar, Punjab, India
							</li>
							<li>
								Head, Central Potato Research Station, Jalandhar, Punjab State
							</li>
							<li>
								Principle Scientist, Agricultural Research Service of the Indian
								Council of Agricultural Research (ICAR), Central Potato Research
								Institute (CPRI) Shimla
							</li>
							<li>
								Scientist S2, Agricultural Research Service of the Indian
								Council of Agricultural Research (ICAR), Tribal Area Development
								Scheme Waghai
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Membership in Professional Associations</b>
					<ul className="ulstyle">
						<li>Distinguished fellow, Indian Potato Association.</li>
						<li>Secretary, Indian Potato Association, 2004-2007.</li>
						<li>Vice President, Indian Potato Association, 2007-09.</li>
					</ul>
					<b>Awards</b>

					<ul className="ulstyle">
						<li>
							“ICAR Outstanding Multi-disciplinary Team Research Award 2010” for
							development of heat tolerant variety Kufri Surya (Team leader)
						</li>
						<li>
							Merit Certificate (2004) by CPRI Shimla for significant
							contribution to potato research
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Ph.D., Plant Physiology and Molecular Biology, Himachal Pradesh
							University, Shimla, India, 1999
						</li>
						<li>
							Master of Science (Hons.), Plant physiology, Punjab Agricultural
							University, Ludhiana, India, 1977
						</li>
					</ul>
				</div>
			</div>
			<Footer />{" "}
		</>
	);
};

export default JoginderSinghMinhas;
