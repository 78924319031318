import React, { useState } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import "./faqs.css";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div id="faqs">
      <Navbar />
      <div className="container pt-pb">
        <div className="accordin">
          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(1)}>
              <strong>
                1. Challenges in implementing food safety standards
              </strong>
              <span className={`arrow ${activeIndex === 1 ? "open" : ""}`} />
            </div>
            {activeIndex === 1 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>Limited Skilled Personnel:</strong> Many developing
                    countries struggle with a lack of trained professionals in
                    crop production areas. This gap prevents effective guidance
                    on implementing global food safety and sustainability
                    standards.
                  </li>
                  <li>
                    <strong>Scaling limitations for standard owners:</strong>{" "}
                    Organizations responsible for publishing standards face
                    challenges in expanding their reach to standard
                    implementers, while accredited Certification Bodies face
                    difficulties in conducting assessments and issuing
                    certifications.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(2)}>
              <strong>2. Approach taken to address the problem</strong>
              <span className={`arrow ${activeIndex === 2 ? "open" : ""}`} />
            </div>
            {activeIndex === 2 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>Digital Learning Platform:</strong> We offer a blend
                    of virtual and instructor-led courses covering essential
                    global food safety and sustainability standards. These
                    courses cater to stakeholders across the entire food supply
                    chain.
                  </li>
                  <li>
                    <strong>Model Farm Initiative:</strong> Aligned with ISO
                    17065 and Smart Farming practices, demonstrate practical
                    applications of global standards in key crop areas. These
                    farms serve as interactive training hubs for a wide range of
                    participants, including farmers, Farmer Producer
                    Organizations (FPOs), extension officers etc.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(3)}>
              <strong>3. Key objectives in learning the platform</strong>
              <span className={`arrow ${activeIndex === 3 ? "open" : ""}`} />
            </div>
            {activeIndex === 3 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>Expand Access to Skilled Professionals:</strong>
                    <br />
                    Establish a digital platform to increase the availability of
                    skilled professionals in rural and underserved areas within
                    the food supply chain.
                  </li>
                  <li>
                    <strong>Promote Safe, Quality Food Production:</strong>
                    <br />
                    Advocate for the production of safe, high-quality food for
                    consumers globally, ensuring adherence to internationally
                    recognized safety and sustainability standards.
                  </li>
                  <li>
                    <strong>
                      Bridge Knowledge and Practice with Model Farms:
                    </strong>
                    <br />
                    Facilitate the practical application of standards through
                    Model Farms that serve as educational hubs.
                  </li>
                  <li>
                    <strong>
                      Support United Nations Sustainable Development Goals
                      (SDGs):
                    </strong>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(4)}>
              <strong>4. Availability of Publications and standards</strong>
              <span className={`arrow ${activeIndex === 4 ? "open" : ""}`} />
            </div>
            {activeIndex === 4 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    The standards and schemes referenced in our courses are not
                    published by us; therefore, copies are not available on our
                    site. To download, purchase, or access these standards or
                    for further guidance, please visit the official websites of
                    the respective standard owners:
                  </li>
                  <li>www.qcin.org</li>
                  <li>www.globalgap.org</li>
                  <li>www.rainforest-alliance.org</li>
                  <li>www.apeda.gov.in</li>
                  <li>www.usda.gov</li>
                  <li>www.fda.gov</li>
                  <li>www.brcgs.com</li>
                  <li>www.iso.org</li>
                  <li>www.fssc.com</li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(5)}>
              <strong>5. What courses are offered on the Platform ?</strong>
              <span className={`arrow ${activeIndex === 5 ? "open" : ""}`} />
            </div>
            {activeIndex === 5 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>ON-FARM PRODUCTION</strong>
                  </li>
                  <li>India Good Agricultural Practices,</li>
                  <li>G.A P</li>
                  <li>National Organic Program (USDA),</li>
                  <li>National Program for Organic Production.</li>
                  <li>Rainforest Alliance</li>
                  <li>
                    <strong>POST-HARVEST PROCESSING</strong>
                  </li>
                  <li>Food Safety and Standards Authority of India</li>
                  <li>Catering</li>
                  <li>Milk Processing</li>
                  <li>Spices Production</li>
                  <li>Storage and Warehousing</li>
                  <li>HACCP</li>
                  <li>ISO 22000 Food Safety Management Systems,</li>
                  <li>BRCGS Global Food Safety Standard</li>
                  <li>FSSC 22000 Food Safety Standard,</li>
                  <li>BRCGS Packaging Materials Global Standard</li>
                  <li>USFDA FSMS PCQI</li>
                  <li>USFDA FSMS PCQ</li>
                  <li>
                    <strong>SUSTAINABILITY</strong>
                  </li>
                  <li>ISO 14001: Environmental Management Systems,</li>
                  <li>ISO 50001: Energy Management,</li>
                  <li>
                    ISO 45001: Occupational health and safety management systems
                  </li>
                  <li>
                    <strong> Other Schemes</strong>
                  </li>
                  <li>ISO 19011</li>
                  <li>Packhouses</li>
                  <li>Organic Inputs Preparation</li>
                  <li>Laboratories</li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(6)}>
              <strong>
                6. What You Receive Upon Regisering for the Course
              </strong>
              <span className={`arrow ${activeIndex === 6 ? "open" : ""}`} />
            </div>
            {activeIndex === 6 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>
                      Comprehensive Access to Essential Resources:
                    </strong>
                    <br />
                    Upon registration, you will receive access to vital
                    resources, including compliance requirements, implementation
                    guidance, Quality Management System (QMS) manuals,
                    procedures, risk assessment frameworks, record-keeping
                    templates etc
                  </li>
                  <li>
                    <strong>Customizable Documentation:</strong>
                    <br />
                    You need to have your own customised documentation based on
                    your products / processes and regulatory compliances.
                  </li>
                  <li>
                    <strong>Disclaimer on Document Use:</strong>
                    <br />
                    While these guidance documents and templates have been
                    prepared to the best of our knowledge and they are intended
                    as general resources.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(7)}>
              <strong>7. Key features of the Platform</strong>
              <span className={`arrow ${activeIndex === 7 ? "open" : ""}`} />
            </div>
            {activeIndex === 7 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>Comprehensive Online Courses:</strong>
                    <br />
                    Access a range of courses focused on global food safety and
                    sustainability standards, designed to educate and empower
                    participants across the food supply chain.
                  </li>
                  <li>
                    <strong>Digital Resource Library:</strong>
                    <br />
                    Explore a digital library with extensive resources,
                    including guidance documents, compliance manuals, case
                    studies, and reference materials.
                  </li>
                  <li>
                    <strong>Interactive Discussion Board:</strong>
                    <br />
                    Engage in discussions, share insights, and connect with
                    other participants, experts, and industry professionals to
                    enhance learning and networking.
                  </li>
                  <li>
                    <strong>
                      Placement Assistance within the Food Sector:
                    </strong>
                    <br />
                    Benefit from placement opportunities that connect you with
                    potential employers across the food industry.
                  </li>
                  <li>
                    <strong>Regulatory Compliance Updates:</strong>
                    <br />
                    Stay informed with the latest regulatory requirements and
                    compliance updates relevant to the food safety and
                    sustainability standards
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(8)}>
              <strong>8. Course Delivery Methods</strong>
              <span className={`arrow ${activeIndex === 8 ? "open" : ""}`} />
            </div>
            {activeIndex === 8 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>Self-Paced and Instructor-Led Options:</strong>
                    <br />
                    Courses are offered in both self-paced formats for flexible,
                    independent learning and instructor-led sessions for guided,
                    interactive experiences.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(9)}>
              <strong>9. How to take the Courses Online </strong>
              <span className={`arrow ${activeIndex === 9 ? "open" : ""}`} />
            </div>
            {activeIndex === 9 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>Browse Available Courses:</strong>
                    <br />
                    Visit the course section and explore the list of available
                    courses.
                  </li>
                  <li>
                    <strong>Select a Course:</strong>
                    <br />
                    Choose the course that interests you.
                  </li>
                  <li>
                    <strong>Review the Course Overview:</strong>
                    <br />
                    Review the detailed course overview to understand the
                    content, objectives, and outcomes.
                  </li>
                  <li>
                    <strong>Register and Pay:</strong>
                    <br />
                    If you're satisfied with the course details, proceed to
                    register and pay the prescribed fees to gain access.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(10)}>
              <strong>10. Regulatory compliances</strong>
              <span className={`arrow ${activeIndex === 10 ? "open" : ""}`} />
            </div>
            {activeIndex === 10 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    Regulatory requirements for export of food products from
                    India
                  </li>
                  <li>
                    Regulatory requirements for import of food products into
                    India and other countries
                  </li>
                  <li>
                    Maximum Residue Levels (MRLS) in the Importing Countries
                  </li>
                  <li>
                    Permitted Plant Protection Products crop wise in the
                    importing countries
                  </li>
                  <li>Approved NABL Accredited labs</li>
                  <li>Approved Packhouses for exports by APEDA</li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(11)}>
              <strong>11. Involvement in Certification </strong>
              <span className={`arrow ${activeIndex === 11 ? "open" : ""}`} />
            </div>
            {activeIndex === 11 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>No Certification Role:</strong>
                    <br />
                    We are not involved in certification or registration to
                    standards. Certification is conducted by approved
                    certification bodies accredited by the National
                    Accreditation Boards in respective countries.
                  </li>
                  <li>
                    <strong>Focus on Skill Development::</strong>
                    <br />
                    Our role is dedicated exclusively to skill development for
                    personnel within the food supply chain, equipping them with
                    the knowledge and tools needed for effective implementation
                    of standards.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(12)}>
              <strong>12. Types of Training Certificated provided </strong>
              <span className={`arrow ${activeIndex === 12 ? "open" : ""}`} />
            </div>
            {activeIndex === 12 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>
                      Certificate of Training – Successful Completion:
                    </strong>
                    <br />
                    Participants who complete the course and pass the online
                    assessment with a score of 60% or higher will receive a
                    Certificate of Training indicating successful completion.
                  </li>
                  <li>
                    <strong>Certificate of Training – Attendance:</strong>
                    <br />
                    Participants who complete the course and take the online
                    assessment but score below 60% will receive a Certificate of
                    Training acknowledging their attendance.
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(13)}>
              <strong>13. Who are Customers </strong>
              <span className={`arrow ${activeIndex === 13 ? "open" : ""}`} />
            </div>
            {activeIndex === 13 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    All the personnel involved in the activities within food
                    supply chain
                  </li>
                  <li>
                    Farmers organizations, Food growers, Food processors/
                    packers, Food exporters / importers, Food retailers,
                    Institutions, faculty and students in food supply chain,
                    Suppliers of farm inputs
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div style={{ marginBottom: "10px" }}>
            <div className="accordin-heading" onClick={() => toggleSection(14)}>
              <strong>14. Impact Creation </strong>
              <span className={`arrow ${activeIndex === 14 ? "open" : ""}`} />
            </div>
            {activeIndex === 14 && (
              <div className="accordin-text">
                <ul>
                  <li>
                    <strong>
                      Increased Income for Farmers and Processors:
                    </strong>
                    <br />
                    Certification of produce under recognized standards can
                    significantly enhance income for farmers and food processors
                    by adding value and credibility to their products.
                  </li>
                  <li>
                    <strong>
                      Empowerment through Digital Education and Model Farms:
                    </strong>
                    <br />
                    Access to comprehensive educational resources and Model
                    Farms helps build a network of digitally skilled
                    entrepreneurs within the food supply chain.
                  </li>
                  <li>
                    <strong>
                      Sustainable Practices for Carbon Footprint Reduction:
                    </strong>
                    <br />
                    Supporting farmers in adopting global standards promotes
                    sustainable practices, enabling them to reduce their carbon
                    footprint and contribute to environmental conservation.
                  </li>
                  <li>
                    <strong>Ensuring Safe Food and Boosting Exports:</strong>
                    <br />
                    Widespread adoption of standards ensures safer food for
                    consumers and the food processing industry, while also
                    enhancing export opportunities by meeting international
                    requirements.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Accordion;




