import {
	faCalendar,
	faEnvelope,
	faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../Menu.css";
import Counter from "./visitorcounter";

const Footer = () => {
	return (
    <>
      {/* First Footer Section */}
      <div
        style={{
          background: "#15222c",
          backgroundImage: 'url("../images/background-footer.png")',
          backgroundSize: "100%",
          color: "white",
          backgroundRepeat:
            "repeat-x" /* Repeats the background image horizontally */,
          // backgroundSize: "auto",
          backgroundPosition: "center",
          position: " relative",
          zIndex: "1",
          height: "auto",
          width: "100%",
        }}
      >
        <div className="info-footer v2">
          <div className="" style={{ paddingTop: "80px" }}>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-lg-4 col-xl-3 ">
                <FooterLeft />
              </div>
              <div className="col-lg-4 col-xl-2 ">
                <FooterQuickLinks />
              </div>
              <div className=" col-lg-4 col-xl-3 ">
                <FooterContact />
              </div>

              <div className=" col-lg-12 col-xl-3">
                <FooterRecentPosts />
              </div>
            </div>
          </div>
        </div>
        <Counter />
      </div>

      {/* Second Footer Section with repeated background */}
      <div
        style={{
          background: "#15222c",
          backgroundImage: 'url("../images/background-footer.png")',
          backgroundSize: "cover",
          // backgroundPosition: 'end',
          // backgroundRepeat: 'repeat-y',
          height: "200px",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center", // Center horizontally
          position: " relative",
        }}
      >
        <div
          className="footer-main"
          style={{
            backgroundColor: "#14292D",
            height: "80px",
            display: "flex",
            alignItems: "center", // Vertically center the content
            justifyContent: "center", // Horizontally center the content
            width: "100%", // Ensure the container stretches the full width
          }}
        >
          <p style={{ color: "#616670", margin: 0 }}>
            Copyright © 2024 Global Academy On Food Safety And Sustainability
            Standards. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

const FooterLeft = () => (
	<div className="footer-left">
		<div className="footer-logo" style={{ paddingBottom: "20px" }}>
			<a href="#">
				<img src="../images/LOGO.png" alt="logo" width="180px" />
			</a>
		</div>
		<p>
			Global Academy for Food Safety & Sustainability Standards, an initiative
			from Krishigap Digital Solutions Pvt Ltd that was established in May 2023.
		</p>

		<div className="social-link">
			<ul style={{ listStyle: "none", padding: 0 }}>
				<li style={{ display: "inline-block", marginRight: "10px" }}>
					<a
						className="social-icon"
						href="https://facebook.com"
						target="_blank"
						rel="noopener noreferrer">
						<i className="fab fa-facebook" style={{ padding: "6px" }}></i>
					</a>
				</li>
				<li style={{ display: "inline-block", marginRight: "10px" }}>
					<a
						className="social-icon"
						href="https://instagram.com"
						target="_blank"
						rel="noopener noreferrer">
						<i className="fab fa-instagram" style={{ padding: "6px" }}></i>
					</a>
				</li>
				<li style={{ display: "inline-block", marginRight: "10px" }}>
					<a
						className="social-icon"
						href="https://pinterest.com"
						target="_blank"
						rel="noopener noreferrer">
						<i className="fab fa-pinterest" style={{ padding: "6px" }}></i>
					</a>
				</li>
				<li style={{ display: "inline-block", marginRight: "10px" }}>
					<a
						className="social-icon"
						href="https://twitter.com"
						target="_blank"
						rel="noopener noreferrer">
						<i className="fab fa-twitter" style={{ padding: "6px" }}></i>
					</a>
				</li>
			</ul>
		</div>
	</div>
);

const FooterContact = () => (
	<div className="get-touch">
		<h4 className="footer__widget-title">Get in Touch</h4>

		{/* Email Section */}
		<div className="contact-item">
			<div
				className="my-icon envelope-icon"
				style={{ padding: "0px 15px 0px 15px" }}>
				<FontAwesomeIcon icon={faEnvelope} /> {/* Envelope Icon */}
			</div>
			<div className="content">
				<p>Email</p>
				<h6>
					<a href="mailto:info@krishigap.com" className="contact-link">
						info@krishigap.com
					</a>
				</h6>
			</div>
		</div>

		{/* Location Section */}
		<div className="contact-item">
			<div
				className="my-icon location-icon"
				// style={{ paddingtop: "10px", paddingBottom: "10px" }}
				style={{ padding: "5px 18px" }}>
				<FontAwesomeIcon icon={faMapMarkerAlt} /> {/* Location Icon */}
			</div>
			<div className="content">
				<p>Location</p>
				<h6>
					<a href="#" className="contact-link">
						House No 5-106/281B, Narsing Municipality Manchirevula K.V Ranga
						Reddy Hyderabad 500075 Telangana
					</a>
				</h6>
			</div>
		</div>
	</div>
);

const FooterQuickLinks = () => (
	<div className="footer__widget">
		<h4 className="footer__widget-title" style={{ color: "white" }}>
			Quick Links
		</h4>
		<div style={{ color: "white", marginTop: "10px" }}>
			<p
				style={{ marginBottom: "10px", cursor: "pointer" }}
				onClick={() =>
					(window.location.href =
						"/contactus")
				}
				className="quick-link">
				Contact Us
			</p>
			<p
				style={{ marginBottom: "10px", cursor: "pointer" }}
				onClick={() =>
					(window.location.href =
						"/privacypolicy")
				}
				className="quick-link">
				Privacy Policy
			</p>
			<p
				style={{ marginBottom: "10px", cursor: "pointer" }}
				onClick={() =>
					(window.location.href =
						"/termsconditions")
				}
				className="quick-link">
				Terms & Conditions
			</p>
			{/* <p
				style={{ marginBottom: "10px", cursor: "pointer" }}
				onClick={() => (window.location.href = "#")}
				className="quick-link">
				Feedback
			</p> */}
			<p
				style={{ marginBottom: "10px", cursor: "pointer" }}
				onClick={() => (window.location.href = "/cancellationrefundpolicy")}
				className="quick-link">
				Cancellation & Refund Policy
			</p>
			<p
				style={{ marginBottom: "10px", cursor: "pointer" }}
				onClick={() =>
					(window.location.href =
						"/disclaimer")
				}
				className="quick-link">
				Disclaimer
			</p>
		</div>
	</div>
);

const FooterRecentPosts = () => (
	<div className="popular-post">
		<h4 className="footer__widget-title">Recent Post</h4>
		<ul>
			{/* Post 1 */}
			<div style={{ marginLeft: "-20px" }}>
				<div
					className="post-item"
					style={{
						display: "flex",
						alignItems: "flex-start",
						color: "white",
						marginTop: "10px",
					}}>
					<div className="post-img" style={{ marginRight: "10px" }}>
						<div
							onClick={() =>
								(window.location.href =
									"https://digitalvega.in/foodsafetyacademy/elevating-your-business-to-new-heights-3/")
							}
							className="post-img-link">
							<img
								width="80px"
								height="85px"
								src="../images/recent-post-img.jpg"
								alt=""
							/>
						</div>
					</div>
					<div className="post-text">
						<h6>
							<span
								onClick={() =>
									(window.location.href =
										"https://digitalvega.in/foodsafetyacademy/elevating-your-business-to-new-heights-3/")
								}
								className="recent-post-link">
								Elevating your business to new heights
							</span>
						</h6>
						<div
							className="post-time"
							style={{ display: "flex", alignItems: "center" }}>
							<FontAwesomeIcon
								icon={faCalendar}
								style={{ color: "#006436", marginRight: "5px" }}
							/>
							<p style={{ margin: 0 }}>May 14, 2023</p>
						</div>
					</div>
				</div>
			</div>

			{/* Post 2 */}
			<div style={{ marginLeft: "-20px" }}>
				<div
					className="post-item"
					style={{
						display: "flex",
						alignItems: "center",
						color: "white",
						marginTop: "10px",
					}}>
					<div className="post-img" style={{ marginRight: "10px" }}>
						<div
							onClick={() =>
								(window.location.href =
									"https://digitalvega.in/foodsafetyacademy/elevating-your-business-to-new-heights/")
							}
							className="post-img-link">
							<img
								width="80px"
								height="85px"
								src="../images/recent-post-img.jpg"
								alt=""
							/>
						</div>
					</div>
					<div className="post-text">
						<h6>
							<span
								onClick={() =>
									(window.location.href =
										"https://digitalvega.in/foodsafetyacademy/elevating-your-business-to-new-heights/")
								}
								className="recent-post-link">
								Elevating your business to new heights
							</span>
						</h6>
						<div
							className="post-time"
							style={{ display: "flex", alignItems: "center" }}>
							<FontAwesomeIcon
								icon={faCalendar}
								style={{ color: "#006436", marginRight: "5px" }}
							/>
							<p style={{ margin: 0 }}>April 18, 2023</p>
						</div>
					</div>
				</div>
			</div>

			{/* Post 3 */}
			<div style={{ marginLeft: "-20px" }}>
				<div
					className="post-item"
					style={{
						display: "flex",
						alignItems: "center",
						color: "white",
						marginTop: "10px",
					}}>
					<div className="post-img" style={{ marginRight: "10px" }}>
						<div
							onClick={() =>
								(window.location.href =
									"https://digitalvega.in/foodsafetyacademy/unleashing-your-businesss-full-potential/")
							}
							className="post-img-link">
							<img
								width="80px"
								height="85px"
								src="../images/recent-post-img-2.jpg"
								alt=""
							/>
						</div>
					</div>
					<div className="post-text">
						<h6>
							<span
								onClick={() =>
									(window.location.href =
										"https://digitalvega.in/foodsafetyacademy/unleashing-your-businesss-full-potential/")
								}
								className="recent-post-link">
								Unleashing your business’s full potential
							</span>
						</h6>
						<div
							className="post-time"
							style={{ display: "flex", alignItems: "center" }}>
							<FontAwesomeIcon
								icon={faCalendar}
								style={{ color: "#006436", marginRight: "5px" }}
							/>
							<p style={{ margin: 0 }}>April 18, 2023</p>
						</div>
					</div>
				</div>
			</div>
		</ul>
	</div>

);

export default Footer;
