import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Createjob from "./createjob";
import Footer from "./footer";
import Navbar from "./navbar";

const Listofjobseeker = () => {
	const [alljobseekers, setAlljobseekers] = useState([]);
	const [alljobs, setAlljobs] = useState([]);
	const [jobseekersCount, setJobseekersCount] = useState([]);
	const [employer, setEmployer] = useState();
	const { auth } = useSelector((state) => state);
	const history = useHistory();
	const dispatch = useDispatch();
	const [searchTerm, setSearchTerm] = useState("");

	// Fetch job seekers on component mount
	console.log("history.location.state", history.location.state?.email);

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		const fetchAlljobseekers = async () => {
			try {
				const res = await getDataAPI("getAllAcceptedJobseekers");
				setAlljobseekers(res.data.jobseekers); // Adjust based on API response structure
				getDataAPI(`getJobseekersApplicationCount`).then((res) => {
					console.log("res getJobseekersApplicationCount", res.data);
					setJobseekersCount(res.data.jobseekersapplicationdetails);
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				});
			} catch (err) {
				console.error("Error fetching job seekers:", err);
			}
		};
		if (history.location.state?.email) {
			fetchAlljobseekers();
			if (localStorage.getItem("employer")) {
				setEmployer(JSON.parse(localStorage.getItem("employer")));
				// console.log(
				// 	'JSON.parse(localStorage.getItem("employer")',
				// 	JSON.parse(localStorage.getItem("employer"))
				// );
			}
		} else {
			alert("Please login to view this page");
			window.location.href = "./employerlogin";
		}
	}, [auth.token, history]);

	const findCount = (name) => {
		const job = jobseekersCount.find((user) => user.f_s_s_intersted === name);
		return job ? job.count : 0;
	};

	useEffect(() => {
		if (employer) {
			(async () => {
				try {
					const res = await getDataAPI(`getAllJobsByEmployerId/${employer.id}`);
					// console.log("res", res.data);
					setAlljobs(res.data.jobs);
				} catch (err) {
					console.error("Error fetching job seekers:", err);
				}
			})();
		}
	}, [employer]);

	const searchedData = alljobseekers.filter(
		(jobseeker) =>
			jobseeker.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			jobseeker.educationalqualifications
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			jobseeker.yearsofexperience
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			jobseeker.f_s_s_intersted
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			jobseeker.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
			jobseeker.country.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<>
			<Navbar />
			{/* <Homecarousel /> */}

			<div className="tab-m" style={{}}>
				<div id="Tab">
					<ul className="nav1 nav-tabs1">
						<li className="active">
							<a href="#1" data-toggle="tab">
								My Job Postings
							</a>
						</li>
						<li>
							<a href="#2" data-toggle="tab">
								Jobseekers
							</a>
						</li>
						{/* <li>
								<a href="#3" data-toggle="tab">
									eBooks
								</a>
							</li> */}
					</ul>
				</div>
			</div>
			<div className="tab-content ">
				<div className="tab-pane active" id="1">
					<div className="" style={{ padding: "100px 30px" }}>
						<Createjob employer={employer} />
						{alljobs.map((job) => (
							<div key={job.id} className="col-12" style={{ padding: "10px" }}>
								<div className="card" style={{ padding: "5px" }}>
									<div className="row">
										<div className="col-9 listofcourses-format">
											<div
												style={{
													fontSize: "20px",
													fontWeight: "500",
													minHeight: "36px",
												}}>
												<label>{job.title}</label>
											</div>
											<div style={{ fontSize: "15px" }}>
												<div>
													Food Safety Standard Interested:{" "}
													<b>{job.f_s_s_intersted}</b>
												</div>
												<div>
													Employment Type: <b>{job.employmenttype}</b>
												</div>
												<div>
													Job Type: <b>{job.jobtype}</b>
												</div>
												<div>
													Job Description <b>{job.description}</b>
												</div>
												<div>
													Work location: <b>{job.city + ", " + job.country}</b>
												</div>
											</div>
										</div>
										<div className="col-3 listofcourses-format">
											<a href={`/employerjob/${job.id}`}>
												<button
													className="addinstructor-btn"
													style={{
														background: "rgb(30 243 164)",
														border: "none",
														width: "150px",
													}}>
													View Job
												</button>
											</a>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="tab-pane" id="2">
					<div className="" style={{ padding: "100px 30px" }}>
						<div className="row format" style={{ paddingBottom: "100px" }}>
							<div
								className="col card-format-analatics"
								style={{ backgroundColor: "#A7D588" }}>
								<div
									className="row format"
									style={{ paddingBottom: "20px", paddingTop: "20px" }}>
									<div
										className="col text-format"
										style={{ backgroundColor: "#C78B62" }}>
										<div className="text1">{findCount("Organic NPOP")}</div>
										<div className="text1">Organic NPOP</div>
									</div>
									<div
										className="col text-format"
										style={{ backgroundColor: "#198754" }}>
										<div className="text1">{findCount("GlobalG.A.P.")}</div>
										<div className="text1">GlobalG.A.P.</div>
									</div>
									<div
										className="col text-format"
										style={{ backgroundColor: "#FFC107" }}>
										<div className="text1">{findCount("ISO 9001")}</div>
										<div className="text1"> ISO 9001</div>
									</div>
								</div>
								<div className="row format" style={{ paddingBottom: "20px" }}>
									<div
										className="col text-format"
										style={{ backgroundColor: "#7805B5" }}>
										<div className="text1">{findCount("USFDA PCQI")}</div>
										<div className="text1">USFDA PCQI</div>
									</div>
									<div
										className="col text-format"
										style={{ backgroundColor: "#BF67A8" }}>
										<div className="text1">{findCount("ISO 14001")}</div>
										<div className="text1">ISO 14001</div>
									</div>
									<div
										className="col text-format"
										style={{ backgroundColor: "#0D6EFD" }}>
										<div className="text1">{findCount("FSSAI")}</div>
										<div className="text1">FSSAI</div>
									</div>
								</div>
								<div className="row format" style={{ paddingBottom: "20px" }}>
									<div
										className="col text-format"
										style={{ backgroundColor: "#0DCAF0" }}>
										<div className="text1">{findCount("FSSC 22000")}</div>
										<div className="text1">FSSC 22000</div>
									</div>
									<div
										className="col text-format"
										style={{ backgroundColor: "#C78B62" }}>
										<div className="text1">{findCount("ISO 22000")}</div>
										<div className="text1">ISO 22000</div>
									</div>
									<div
										className="col text-format"
										style={{ backgroundColor: "#4C87B8" }}>
										<div className="text1">{findCount("USDA NOP")}</div>
										<div className="text1">USDA NOP</div>
									</div>
								</div>
							</div>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<h4 className="instructorlist-text">List Of Job Seekers</h4>
							<input
								type="text"
								placeholder="Search"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className="mb-4 p-2 border border-gray-300 rounded"
							/>
						</div>

						{searchedData.map((jobseeker) => (
							<div
								key={jobseeker.id}
								className="col-12"
								style={{ padding: "10px" }}>
								<div className="card" style={{ padding: "5px" }}>
									<div className="row">
										<div className="col-3" style={{ cursor: "pointer" }}>
											<img
												alt=""
												src={
													jobseeker.profilepic || "../images/common-user.svg"
												}
												style={{ width: "100%" }}
											/>
										</div>
										<div className="col-6 listofcourses-format">
											<div
												style={{
													fontSize: "20px",
													fontWeight: "500",
													minHeight: "36px",
												}}>
												<label>{jobseeker.name}</label>
											</div>
											<div style={{ fontSize: "15px" }}>
												<div>
													Educational Qualification:{" "}
													<b>{jobseeker.educationalqualifications}</b>
												</div>
												<div>
													Years of experience:{" "}
													<b>{jobseeker.yearsofexperience}</b>
												</div>
												<div>
													Food Safety Standard Intrested:{" "}
													<b>{jobseeker.f_s_s_intersted}</b>
												</div>
												<div>
													Work location:{" "}
													<b>{jobseeker.city + ", " + jobseeker.country}</b>
												</div>
											</div>
										</div>
										<div className="col-3 listofcourses-format">
											<a href={`/jobseekerprofile/${jobseeker.id}`}>
												<button
													className="addinstructor-btn"
													style={{
														background: "rgb(30 243 164)",
														border: "none",
														width: "150px",
													}}>
													View Profile
												</button>
											</a>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="container" style={{ padding: "100px" }}></div>
			<Footer />
		</>
	);
};

export default Listofjobseeker;
