import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { postDataAPI } from "../utils/fetchData";

export default function Createjob(props) {
	console.log("props", props.employer);
	const dispatch = useDispatch();

	const [showJobModal, setShowJobModal] = useState(false);
	const [formData, setFormData] = useState({
		title: "",
		f_s_s_intersted: "",
		employmenttype: "",
		jobtype: "",
		city: "",
		country: "",
		salaryrange: "",
		description: "",
		skills: "",
		experiencelevel: "",
		deadline: "",
		submissionlink: "",
		additionalinformation: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		e.preventDefault();
		console.log("formData", formData);
		try {
			const res = await postDataAPI("createjobs", {
				...formData,
				createdby: props.employer.id,
			});
			console.log("res", res);
			if (res.status === 200) {
				alert("Job posted successfully");
				window.location.reload();
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const f_s_s_iOptions = [
		"USDA NOP",
		"Organic NPOP",
		"GlobalG.A.P.",
		"ISO 9001",
		"ISO 22000",
		"FSSC 22000",
		"FSSAI",
		"ISO 14001",
		"USFDA PCQI",
	];

	const empOptions = ["Full-Time", "Part-Time", "Contract", "Internship"];
	const jobOptions = ["On-Site", "Remote", "Hybrid"];
	const expOptions = ["Entry-Level", "Mid-Level", "Senior"];

	return (
		<>
			<Modal
				show={showJobModal}
				onHide={() => setShowJobModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Job Posting
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer", textAlign: "right" }}
							onClick={() => setShowJobModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Job title:</div>
							<input
								type="text"
								name="title"
								value={formData.title}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Job Title"
								required></input>
						</div>

						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>
								Food Safety Standard Interested:{" "}
							</div>
							<select
								name="f_s_s_intersted"
								className="form-select"
								onChange={handleInputChange}>
								<option value="">Select</option>
								{f_s_s_iOptions.map((option, index) => {
									return <option key={index}>{option}</option>;
								})}
							</select>
						</div>

						<div
							style={{
								paddingLeft: "10px",
								paddingTop: "15px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Employment Type: </div>
							<select
								name="employmenttype"
								className="form-select"
								onChange={handleInputChange}>
								<option value="">Select</option>
								{empOptions.map((option, index) => {
									return <option key={index}>{option}</option>;
								})}
							</select>
						</div>

						<div
							style={{
								paddingLeft: "10px",
								paddingTop: "15px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Job Type: </div>
							<select
								name="jobtype"
								className="form-select"
								onChange={handleInputChange}>
								<option value="">Select</option>
								{jobOptions.map((option, index) => {
									return <option key={index}>{option}</option>;
								})}
							</select>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Work Location:</div>
							<div class="row">
								<div class="col-6">
									<div style={{ fontSize: "13px" }}>City:</div>
									<input
										type="text"
										name="city"
										value={formData.city}
										onChange={handleInputChange}
										className="form-control"
										placeholder="City"
										required></input>
								</div>

								<div class="col-6">
									<div style={{ fontSize: "13px" }}>Country:</div>
									<input
										type="text"
										name="country"
										value={formData.country}
										onChange={handleInputChange}
										className="form-control"
										placeholder="Country"
										required></input>
								</div>
							</div>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Salary Range (Optional):</div>
							<input
								type="text"
								name="salaryrange"
								value={formData.salaryrange}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Salary Range (Optional)"
								required></input>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>
								Job Description (Key responsibilities & requirements):
							</div>
							{/* <input
								type="text"
								name="description"
								value={formData.description}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Job Description"
								required></input> */}
							<textarea
								name="description"
								value={formData.description}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Job Description"
								required></textarea>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>
								Required Skills & Qualifications:
							</div>
							<input
								type="text"
								name="skills"
								value={formData.skills}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Required Skills & Qualifications"
								required></input>
						</div>

						<div
							style={{
								paddingLeft: "10px",
								paddingTop: "15px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Experience Level: </div>
							<select
								name="experiencelevel"
								className="form-select"
								onChange={handleInputChange}>
								<option value="">Select</option>
								{expOptions.map((option, index) => {
									return <option key={index}>{option}</option>;
								})}
							</select>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Application Deadline :</div>
							<input
								type="text"
								name="deadline"
								value={formData.deadline}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Application Deadline"
								required></input>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>
								Application Submission Link or Email:
							</div>
							<input
								type="text"
								name="submissionlink"
								value={formData.submissionlink}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Application Submission Link or Email"
								required></input>
						</div>

						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Additional information :</div>
							<input
								type="text"
								name="additionalinformation"
								value={formData.additionalinformation}
								onChange={handleInputChange}
								className="form-control"
								placeholder="Additional information"
								required></input>
						</div>

						<div
							style={{
								textAlign: "center",
								paddingBottom: "10px",
								paddingTop: "20px",
							}}>
							<Button
								style={{ background: "#20ce8f", border: "none" }}
								type="submit">
								Add
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<div className="d-flex justify-content-end">
				<Button
					style={{
						background: "#36BB7D",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
						marginRight: "5px",
					}}
					onClick={() => setShowJobModal(true)}>
					+ Add Job Posting
				</Button>
			</div>
		</>
	);
}
