import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

export default function DocAction(props) {
	const { auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [showUploadDocModal, setShowUploadDocModal] = useState(false);
	const [dlCategorieArray, setDlCategorieArray] = useState([]);
	const [dlSubCategorieArray, setDlSubCategorieArray] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [docName, setDocName] = useState("");
	// const [docCategory, setDocCategory] = useState("");
	// const [docSubject, setDocSubject] = useState("");
	const [docSource, setDocSource] = useState("");
	const [numberOfPages, setNumberOfPages] = useState(0);
	const [docLink, setDocLink] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		// getDataAPI(`getdlcategory`).then((res) => {
		// 	// console.log("dlcategoryarray", res.data.categories);
		// 	// setCommunitiesArray(res.data.community);
		// 	setDlCategorieArray(res.data.categories);
		// });
		// getDataAPI(`getlanguages`, auth.token).then((res) => {
		// 	// console.log("res getlanguages", res.data);
		// 	// setCommunitiesArray(res.data.community);
		// 	setLanguages(res.data.languages);
		// });
		setDlCategorieArray(props.categoriesArray);
		setLanguages(props.languagesArray);
	}, [props]);

	useEffect(() => {
		if (selectedCategory !== "") {
			getDataAPI(`getdlsubcategory/${selectedCategory}`).then((res) => {
				// console.log("res getdl subcategory", res.data);
				// setCommunitiesArray(res.data.community);
				setDlSubCategorieArray(res.data.subcategories);
			});
		}
	}, [selectedCategory]);

	const handleEditDoc = async (doc) => {
		console.log("doc", doc);
		setDocName(doc.name);
		setSelectedCategory(doc.categoryid);
		setSelectedSubCategory(doc.subcategoryid);
		setSelectedLanguage(doc.languageid);
		// setDocSubject(doc.subject);
		setDocSource(doc.source);
		setNumberOfPages(doc.numberofpages);
		setDocLink(doc.link);

		setTimeout(() => {
			setShowUploadDocModal(true);
		}, 100);
	};

	const handleSetDocInactive = async (doc) => {
		console.log("doc", doc);
		getDataAPI(`inactivedoc/${doc.id}`, auth.token).then((res, err) => {
			console.log("res inactivedoc", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				window.location.reload();
			}
			// setMycommunitiesArray(res.data.community);
		});
	};

	const handleUploadDoc = async () => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		setShowUploadDocModal(false);

		let item = {
			id: props.doc.id,
			name: docName,
			categoryid: selectedCategory,
			subcategoryid: selectedSubCategory,
			languageid: selectedLanguage,
			numberofpages: numberOfPages,
			// subject: docSubject,
			source: docSource,
			link: docLink,
			communityid: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
		};
		console.log("item", item);
		const formData = new FormData();

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("updatedocument", formData, auth.token);
			console.log("res", res.data);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Modal
				show={showUploadDocModal}
				onHide={() => setShowUploadDocModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Edit Files & Docs
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={() => setShowUploadDocModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											props.categories();
											// setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											props.subcategories();
											// setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									value={selectedLanguage}
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											props.languages();
											// setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>

							<div className="row pb-2 mt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={docName}
										placeholder="Document Name"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocCategory(e.target.value)}
									/>
								</div>
							</div> */}

							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={docSubject}
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={docSource}
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="number"
										className="form-control"
										value={numberOfPages}
										id="copytext"
										placeholder="Number of pages"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setNumberOfPages(e.target.value)}
									/>
								</div>
							</div>

							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										placeholder="Link"
										value={docLink}
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setDocLink(e.target.value)}
									/>
								</div>
							</div>

							{/* <div>Source:</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="application/*"
										className="form-control"
										id="copytext"
										onChange={(e) => setDocFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div> */}
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadDoc()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div style={{ marginLeft: "5px" }}>
				<Button
					style={{
						// background:
						// 	"#E23F1C",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}
					className="btn btn-warning mt-2 col-12"
					onClick={() => handleEditDoc(props.doc)}>
					<i className="fas fa-edit"></i>
					Edit doc
				</Button>
				<Button
					style={{
						background: "#E23F1C",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}
					className="btn btn-danger mt-2 col-12"
					onClick={() => handleSetDocInactive(props.doc)}>
					<i className="fa fa-trash" aria-hidden="true"></i> Delete doc
				</Button>
			</div>
		</>
	);
}
