import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const OmveerSingh = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Dr. Omveer Singh</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img className="imagesb" src="../images/T_Appaji_Rao.jpg" alt="" />
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<ul className="ulstyle">
							<li>
								Advisor to National Dairy Development Board from March 2023.
								Helping in setting up multistate cooperative business entities
								in the areas of Seeds, Organic Food under the aegis of Ministry
								of Cooperation .Govt. of India.
							</li>
							<li>
								In the role of Director in NDDB Dairy Services, Delhi and Mother
								Dairy Fruits & Vegetables Pvt. Ltd and member of Advisory Board
								in Amit university –College of Agriculture.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Past</b>
					<ul className="ulstyle">
						<li>
							Deputy Managing Director of Mother Dairy Fruits & Vegetables Pvt.
							Ltd . heading Dairy, Horticulture and Oil businesses of Mother
							Dairy and taking organization to the new growth trajectory with
							his leadership abilities.
						</li>
						<li>
							Managing Director of NDDB Dairy Services (NDS), a Wholly Owned
							Subsidiary of NDDB for more than six years.
						</li>
						<li>
							Joined NDDB’s initiative in 1995 Horticulture Business which later
							came to be known as the SAFAL Business of MDFVPL.
						</li>
						<li>
							Field Fresh Foods Pvt. Ltd.as Head- Agri. Business Development and
							Corporate Social Responsibility (CSR) for more than 5 years.
						</li>
						<li>Chief Executive Officer ,Champion Agro Fresh Pvt. Ltd.</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Doctorate Degree in Genetics & Plant Breeding from Agra
							University, Agra.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default OmveerSingh;
