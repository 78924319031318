import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const VijaySinghThakur = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Dr Vijay Singh Thakur</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/Vijay-Singh-Thakur.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<ul className="ulstyle">
							<li>An expert on Apple and Horticulture Value chain.</li>
						</ul>
						<b>Past</b>
						<ul className="ulstyle">
							<li>
								Ex-Vice Chancellor, Dr. YS Parmar University of Horticulture and
								Forestry, Nauni, Solan in 2013-2016.
							</li>
							<li>
								An expert on temperate fruits, diseases and pest management,
								completed research work on 32 research projects funded
								internationally and nationally.
							</li>
							<li>
								Prepared and implemented Horticulture Development Project under
								World Bank for the state Himachal Pradesh.
							</li>
							<li>
								Handled Apple scab disease management in Himachal Pradesh.
							</li>
							<li>
								Chairman, ICAR Board Subject Matter Area (BSMA) Committee for
								Horticulture i.e. 12th September, 2018.
							</li>
							<li>
								Visiting scientist to Horticulture Research International, UK,
								Institute for Plant Protection of Hungarian Academy of Sciences,
								, Federal Centre for Breeding Research on Cultivated Plants,
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Professional Experiences</b>
					<ul className="ulstyle">
						<li>
							An expert on temperate fruits, diseases and pest management,
							completed research work on 32 research projects funded
							internationally and nationally.
						</li>
						<li>
							Specialized in research on epidemiology, weather monitoring &
							disease forecasting methods, pesticides mode of action and
							application.
						</li>
						<li>
							Significant achievements in production of quality planting
							material of commercially important temperate fruits, medicinal
							plants, and floriculture have made.
						</li>
						<li>
							Established bud wood & Gene bank for fruits, integrated diseases,
							pests and nutrient management, use of bio-fertilizers &
							natural/organic farming, crop regulation and energy efficient
							canopy management.
						</li>
					</ul>
					<b>Awards and Recognitions</b>

					<ul className="ulstyle">
						<li>
							Life time Achievements award by Indian Academy of Horticulture
							Science, New Delhi for 2019.
						</li>
						<li>
							Awarded Indian National Science Academy visiting scientist
							fellowship under exchange program with Royal Society, London, UK.
						</li>
						<li>
							Life Time Achievement Award for overall development of
							Horticulture in general and Plant Diseases Management by YSPUHF,
							Himalayan Phytopathological Society of India.
						</li>
						<li>
							Recipient of Indian Horticulture Gold Medal in Fruit Science in
							the development of Horticulture.
						</li>
						<li>
							Received first European Commission collaborative research project
							on “Sustainable Production of Apple in Asia”. In the consortium
							UK, Germany, Hungry, France, China and India are partners.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Post Doctorate from Horticulture research international East
							Malling of UK in Disease forecasting.
						</li>
						<li>
							Ph.D. on 1987 from Dr. YS Parmar University, Solan in Plant
							Pathology (Fruits).
						</li>
						<li>M.Sc. on 1983 from HPKV, Palampur in Plant Pathology.</li>
						<li>
							B.Sc. on 1981 from HPKV, Palampur in Specialization of
							Agriculture.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default VijaySinghThakur;
