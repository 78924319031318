import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Akhilmittal = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />

			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Akhil Mittal</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img className="imagesb" src="../images/akhil_mittal.jpg" alt="" />
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<div style={{ paddingBottom: "15px", lineHeight: "30px" }}>
							<b>Present</b>
							<br />
							<span>
								Providing comprehensive Legal, Secretarial and Management
								Advisory Services in the field of Company Law, Securities Laws,
								inbound and outbound Investment, Trademark, Copyrights & Patent
								filing, Legal Due Diligence, Transaction documents, FEMA
								Compliance, Private Equity, Mergers & Acquisition.
							</span>
							<br />
						</div>
						<div style={{ paddingBottom: "15px", lineHeight: "30px" }}>
							<b>Past</b>
							<br />
							<span>
								A Law Graduate, Masters in Commerce and a member of The
								Institute of Company Secretaries of India with a professional
								experience of over 10 years in various industries.
								<br /> <br />
								He has hands on experience in Company Law, Securities Laws,
								inbound and outbound Investment, Trademark, Copyrights & Patent
								filing, Legal Due Diligence, FEMA Compliance, RBI matters and
								relevant compliances. He has been actively engaged in dealing
								with company law matters and obtaining approvals and
								registrations with various governmental, statutory, regulatory
								and administrative authorities like Registrar of Companies,
								Company Law Boards, Regional Directors, and Ministry of
								Corporate Affairs, Reserve Bank of India, Stock Exchanges, SEBI,
								DGFT and other statutory Authorities and representing before the
								said Authorities on behalf of clients.
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Educational Qualifications</b>
					<ul className="ulstyle">
						<li>M.Com</li>
						<li>LLB</li>
						<li>CS</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Akhilmittal;
