import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Navbar from "./navbar";

const Agripreneurs = () => {
	return (
		<>
			<Navbar />

			<div className="agripreneur-container">
				<div className="agripreneur-title row">
					<div style={{ fontSize: "26px", padding: "0 20px 20px 0 " }}>
						{" "}
						Empowering Women Agripreneurs:{" "}
					</div>
					<div style={{ padding: "0" }}>
						{" "}
						Unlocking Business Opportunities in Crop Production Centers in Rural
						Areas
					</div>
				</div>
				<div className="row agripreneur-images-matter">
					<div className="col-8 agripreneur-matter-container">
						<div className="elementor-widget-container">
							<p>
								<strong>Course Overview</strong>
							</p>
							<p>
								This course empowers women in rural areas to become
								Agripreneurs, equipping them with skills to drive change in
								agriculture and improve their livelihoods. Participants will
								become Brand Ambassadors for the India Good Agricultural
								Practices (IndG.A.P<strong>)</strong>&nbsp;Certification Scheme,
								launched by the Quality Council of India (QCI) under the
								Ministry of Commerce, Government of India. Aligned with the
								International Standard &nbsp;ISO 17065 standard, the IndG.A.P
								<strong>.</strong>&nbsp;initiative, part of the Make in India
								program, promotes sustainable farming practices that benefit
								farmers, consumers, the environment, and society.
							</p>
							<p>
								<strong>
									<br />
									Supporting UN SDG Goals:
								</strong>
							</p>
							<p>
								The course contributes to sustainable economic growth,
								responsible production, and community development in rural
								areas.
							</p>
							<p>&nbsp;</p>
							<p>
								<strong>
									Challenges in Implementing India Good Agricultural Practices
									(IndG.A.P) Among Smallholder Farmers in India
								</strong>
							</p>
						</div>
						<ul className="">
							<li className="">
								<div className="list-item">
									<div className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span className="elementor-icon-list-text">
										<strong>Limited Awareness and Knowledge</strong>
										<br />{" "}
										<p style={{ fontWeight: "lighter" }}>
											Many smallholder farmers lack access to quality training
											programs and knowledge of IndG.A.P benefits, making it
											challenging to initiate compliance.
										</p>
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<strong>
											Limited Access to Quality Farm Inputs and Equipment
										</strong>
										<br />
										<p style={{ fontWeight: "lighter" }}>
											{" "}
											Smallholder farmers often face difficulties in obtaining
											high-quality seeds, fertilizers, crop protection products,
											and reliable maintenance services for farm equipment, all
											essential for meeting GAP standards.
										</p>
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<b>Challenges with Documentation and Record-Keeping</b>
										<br />
										<p style={{ fontWeight: "lighter" }}>
											Record-keeping and documentation can be challenging for
											farmers, hindering effective monitoring and compliance.
										</p>{" "}
									</span>
								</div>
							</li>
						</ul>
					</div>
					<div className="col-4 agripreneur-images-container">
						<div className="row image-container ">
							<img src="../images/agripreneurs1.jpg" alt="" className="image" />
						</div>
						<div className="row image-container">
							<img src="../images/agripreneurs2.jpg" alt="" className="image" />
						</div>
						<div className="row image-container">
							<img src="../images/agripreneurs3.jpg" alt="" className="image" />
						</div>
					</div>
				</div>
				<div className="row agripreneur-images-matter-2">
					<div className="col agripreneur-matter-container-2">
						<div style={{ fontSize: "20px", padding: "20px 0 10px 0" }}>
							<strong>Key Objectives</strong>
							<br />
						</div>
						<ul className="">
							<li className="">
								<div className="list-item">
									<div className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span className="elementor-icon-list-text">
										{" "}
										<p style={{ fontWeight: "lighter" }}>
											Equip women Agripreneurs in rural areas to become Brand
											Ambassadors for the IndG.A.P scheme, enhancing their
											livelihoods and influence in local agriculture.
										</p>
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<p style={{ fontWeight: "lighter" }}>
											{" "}
											Expand the presence of skilled women entrepreneurs
											actively contributing to agricultural development in rural
											communities.
										</p>
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<p style={{ fontWeight: "lighter" }}>
											Strengthen the financial sustainability of women
											entrepreneurs and farmers by integrating sustainable,
											profitable practices.{" "}
										</p>{" "}
									</span>
								</div>
							</li>

							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<p style={{ fontWeight: "lighter" }}>
											Empower women Agripreneurs to lead farmers in reducing
											carbon footprints through adherence to on-farm food safety
											and sustainability standards.{" "}
										</p>{" "}
									</span>
								</div>
							</li>
						</ul>
					</div>
					<div className="col agripreneur-matter-container-2">
						<div style={{ fontSize: "20px", padding: "10px 0 10px 0" }}>
							<strong>
								Impact Creation for Women Agripreneurs in Supporting the
								IndG.A.P Scheme
							</strong>
							<br />
						</div>
						<ul className="">
							<li className="">
								<div className="list-item">
									<div className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>
									</div>
									<span className="elementor-icon-list-text">
										<strong>Increased Income and Livelihoods</strong>
										<br />{" "}
										<p style={{ fontWeight: "lighter" }}>
											Women Agripreneurs will generate new income by guiding
											farmers through certification and offering support
											services, benefiting the rural economy.
										</p>
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<strong>Skilled Agricultural Workforce</strong>
										<br />
										<p style={{ fontWeight: "lighter" }}>
											{" "}
											Women will educate farmers, creating a digitally skilled
											workforce in rural agriculture
										</p>
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<b>Financial and Food Safety Sustainability</b>
										<br />
										<p style={{ fontWeight: "lighter" }}>
											Local farmers, guided by Agripreneurs, will adopt
											cost-effective, sustainable practices that increase
											productivity. high-quality food for consumers, improving
											public health.
										</p>{" "}
									</span>
								</div>
							</li>
							<li className="">
								<div className="list-item">
									<span className="elementor-icon-list-icon">
										<svg
											aria-hidden="true"
											className="e-font-icon-svg e-fas-leaf"
											viewBox="0 0 576 512"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
										</svg>{" "}
									</span>
									<span className="elementor-icon-list-text">
										<b>Environmental Benefits</b>
										<br />
										<p style={{ fontWeight: "lighter" }}>
											IndG.A.P promotes eco-friendly practices, reducing carbon
											footprints and supporting the UN SDGs.
										</p>{" "}
									</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Agripreneurs;
