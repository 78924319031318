import React from "react";
import Carousel from "react-multi-carousel";
import "../Menu.css";
import Footer from "./footer";
import JoinUsSection from "./joinus";
import ModelFarm2 from "./modelfarm2";
import Navbar from "./navbar";

const ModelFarm = () => {
	const responsive1 = {
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1400,
			},
			items: 1,
			partialVisibilityGutter: 40,
		},
		desktop1: {
			breakpoint: {
				max: 1400,
				min: 1024,
			},
			items: 1,
			partialVisibilityGutter: 40,
		},
		mobile: {
			breakpoint: {
				max: 767,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 767,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
	};
	return (
		<>
			<Navbar />

			<div className="model-farm-container">
				<div className="model-farm-inner">
					<div className="main-heading"> AGRIGROWTH, THE DIGITAL WAY</div>
					{/* <div className="model-farm-heading">
          <h3 className="centered-heading">Model Farm With Global Food Safety And Sustainability Standards</h3>
        </div> */}
					<div className="model-farm-subheading">
						<h6 className="centered-subheading">
							Empowering Farmers for a Sustainable Future: (A) Model Farms and
							(B) On-Farm Production Excellence through global standards"
						</h6>
					</div>

					<div className="model-farm-key-components-container">
						<div className="model-farm-key-components">
							<h3>A. MODEL FARMS</h3>
						</div>
					</div>

					<div className="model-farm-key-point">
						<p>
							Bridging technology and global food safety standards to empower
							farmers and corporates while enhancing the food safety and
							sustainability ecosystem for a secure future.
						</p>
					</div>

					{/* Updated section starts here */}
					<div className="model-farm-details-container ">
						<div className="col ">
							<div className="row matter-container row-substitute">
								<h4 className="dark-green-heading center caps-heading">
									Targeted Groups for Collaboration in the Selection, Operation,
									and Demonstration of Model Farms:
								</h4>
								{/* Left Column: Model Farm Infrastructure */}

								<div className="col">
									<ul className="elementor-icon-list-items">
										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>
												</div>
												<span className="elementor-icon-list-text">
													<strong>Farmers Networks</strong>
												</span>
											</div>
										</li>

										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>
												</div>
												<span className="elementor-icon-list-text">
													<strong>Farmer Producer Companies (FPCs)</strong>
												</span>
											</div>
										</li>

										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>
												</div>
												<span className="elementor-icon-list-text">
													<strong>Corporates in Farming</strong>
												</span>
											</div>
										</li>

										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>
												</div>
												<span className="elementor-icon-list-text">
													<strong>Government Institutions:</strong>{" "}
													<span style={{ fontWeight: "500" }}>
														{" "}
														Partnering with government bodies to establish Model
														Farms as benchmarks for policies and training
														initiatives.
													</span>
												</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>

						{/* Image Section inside the Right Column */}
						<div className="model-farm-image image-fluid">
							<a
								href="https://digitalvega.in/foodsafetyacademy/wp-content/uploads/2024/10/modalfarm.png"
								target="_blank"
								rel="noopener noreferrer">
								<img
									src="..\images\keycomponentsmodelfarm.png"
									alt="Model Farm"
									className="farm-image"
								/>
							</a>
						</div>
					</div>

					{/* Additional Income and Value Added Services */}
					<div className="model-farm-income-services">
						<div className="row row-substitute">
							<div className="additional-income">
								<h4 className="dark-green-heading center caps-heading">
									Key Components of Model Farms
								</h4>
								<ul className="elementor-icon-list-items">
									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Skill Development:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Equip farmers with globally recognized farming
													techniques and technological advancements..
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>
													On-Farm Food Safety Protocol Integration:
												</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Implement standards and technology to enhance produce
													quality and ensure compliance with food safety
													standards.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Precision Farming Deployment:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Utilize IoT, sensors, and data analytics for resource
													efficiency, improved productivity, and reduced carbon
													footprints.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Self-Reliant Farm Inputs:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Encourage on-farm preparation and storage of inputs to
													reduce costs and enhance self-sufficiency
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Expanded Market Access:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Link certified farms with premium markets to boost
													farmer income.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Fostering Collaboration and Innovation:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Facilitate innovation by connecting farmers, tech
													providers, and policymakers.
												</span>
											</span>
										</div>
									</li>
								</ul>
							</div>

							<div
								className="value-added-services"
								style={{ paddingBottom: "30px" }}>
								<h4 className="dark-green-heading center caps-heading">
									How Model Farms Benefit Farmers
								</h4>
								<ul className="elementor-icon-list-items">
									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Digitized Farm Operations:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Save time and effort with digital records and farm
													data management.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Access to Premium Markets:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Certified produce can access premium markets,
													generating higher profits.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>
													Hands-On Skill Development and Knowledge Transfer:
												</strong>
												<span style={{ fontWeight: "500" }}>
													Farmers receive practical training in best practices
													and advanced farming techniques.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Technology-Driven Learning:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													AI-powered tools provide real-time monitoring and
													data-driven decisions, improving crop management and
													reducing waste.
												</span>
											</span>
										</div>
									</li>

									<li className="elementor-icon-list-item">
										<div className="list-item">
											<div className="elementor-icon-list-icon">
												<svg
													aria-hidden="true"
													className="e-font-icon-svg e-fas-leaf"
													viewBox="0 0 576 512"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
												</svg>{" "}
											</div>
											<span className="elementor-icon-list-text">
												<strong>Compliance with Global Standards:</strong>
												<span style={{ fontWeight: "500" }}>
													{" "}
													Ensure adherence to international food safety
													protocols such as GlobalG.A.P., USDA, and NPOP.
												</span>
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* *********************************************** */}
					<div className="model-farm-income-services">
						<div className="row row-substitute ">
							<div className="additional-income">
								<h4 className="dark-green-heading center caps-heading">
									Benefits to Society at Large
								</h4>

								<div className="benefits-of-society-points">
									<ul>
										<li>
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>
												</div>
												<span className="elementor-icon-list-text">
													<strong>Climate-Smart Agriculture:</strong>
													<span style={{ fontWeight: "500" }}>
														{" "}
														Reduce environmental impact by adopting sustainable
														farming practices that contribute to long-term
														ecological balance.
													</span>
												</span>
											</div>
										</li>
										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>{" "}
												</div>
												<span className="elementor-icon-list-text">
													<strong>Innovation and Growth:</strong>
													<span style={{ fontWeight: "500" }}>
														{" "}
														Encourage collaboration among farmers, tech experts,
														and policymakers, driving innovation and enabling
														large-scale implementation of advanced agricultural
														solutions.
													</span>
												</span>
											</div>
										</li>

										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>{" "}
												</div>
												<span className="elementor-icon-list-text">
													<strong>
														Increased Productivity and Profitability:{" "}
													</strong>
													<span style={{ fontWeight: "500" }}>
														Precision farming boosts crop yields, reduces costs,
														and improves product quality, ensuring safer food
														for processors and consumers.
													</span>
												</span>
											</div>
										</li>

										<li className="elementor-icon-list-item">
											<div className="list-item">
												<div className="elementor-icon-list-icon">
													<svg
														aria-hidden="true"
														className="e-font-icon-svg e-fas-leaf"
														viewBox="0 0 576 512"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
													</svg>{" "}
												</div>
												<span className="elementor-icon-list-text">
													<strong>Reduced Carbon Footprints:</strong>
													<span style={{ fontWeight: "500" }}>
														Promote eco-friendly farming techniques that lower
														carbon emissions while enhancing compliance with the
														United Nations Sustainable Development Goals (SDGs).
													</span>
												</span>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className="carousel-container">
								<Carousel
									className="carousel img-fluid"
									responsive={responsive1}
									arrows={true}
									autoPlay
									infinite
									slidesToSlide={1}>
									<img
										src="https://digitalvega.in/foodsafetyacademy/wp-content/uploads/2024/10/istockphoto-836090694-612x612-1.jpg"
										alt="Description"
									/>
									<img
										src="https://digitalvega.in/foodsafetyacademy/wp-content/uploads/2024/10/istockphoto-1158194879-612x612-1.jpg"
										alt="Description"
									/>
									<img
										src="https://digitalvega.in/foodsafetyacademy/wp-content/uploads/2024/10/istockphoto-1163941571-612x612-1.jpg"
										alt="Description"
									/>
									<img
										src="https://digitalvega.in/foodsafetyacademy/wp-content/uploads/2024/10/istockphoto-1035635816-612x612-1.jpg"
										alt="Description"
									/>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="model-farm-inner">
				<ModelFarm2 />
				<JoinUsSection />
			</div>

			<Footer />
		</>
	);
};

export default ModelFarm;
