import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

import "font-awesome/css/font-awesome.min.css";
import { Button, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import LoginRequest from "./loginRequest";

const Digitallibrary = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();

	const [userData, setUserData] = useState({});
	const [loginModel, setLoginModel] = useState(false);
	const [searchCourseValue, setSearchCourseValue] = useState("");
	const [communitiesArray, setCommunitiesArray] = useState([]);
	const [ebookArray, setEbookArray] = useState([]);
	const [searchBookValue, setSearchBookValue] = useState("");
	const [searchVideoValue, setSearchVideoValue] = useState("");
	const [videoArray, setVideoArray] = useState([]);
	const [videoModal, setVideoModal] = useState(false);
	const [videoUrl, setVideoUrl] = useState(false);
	const [communities, setCommunities] = useState([]);
	const [videos, setVideos] = useState([]);
	const [books, setBooks] = useState([]);

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		if (auth.token !== undefined) {
			setLoginModel(false);

			setUserData(auth.user);
			getDataAPI(`getSavedCourses/${auth.user.id}`, auth.token).then((res) => {
				console.log("res my courses", res.data);

				setCommunitiesArray(res.data.courses);
				setCommunities(res.data.courses);
			});
			getDataAPI(`getSavedBooks/${auth.user.id}`, auth.token).then((res) => {
				console.log("res get books", res.data);
				// setCommunitiesArray(res.data.community);
				setEbookArray(res.data.books);
				setBooks(res.data.books);
			});
			getDataAPI(`getSavedVideos/${auth.user.id}`, auth.token).then((res) => {
				console.log("res get videos", res.data);
				// setCommunitiesArray(res.data.community);
				setVideoArray(res.data.videos);
				setVideos(res.data.videos);
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { loading: false },
				});
			});
		}
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
	}, [auth]);

	const handleClick = async (
		file,
		image,
		title,
		question,
		id,
		admin,
		details
	) => {
		// console.log(file, image, title);
		// localStorage.setItem("data", "");
		// console.log(localStorage.getItem("data"));
		if (details === 0) {
			try {
				const res = await postDataAPI(
					"createCourseDetails",
					{
						userid: auth.user.id,
						courseid: id,
						adminid: admin,
					},
					auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					// window.location.reload();
				}
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
		}
	};

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	const handleCloseVideoModal = () => {
		setVideoModal(false);
	};

	useEffect(() => {
		if (setSearchCourseValue === "") {
			setCommunitiesArray(communities);
			return;
		}
		const filterBySearch = communities.filter((item) => {
			if (
				JSON.stringify(item.coursetitle)
					.toLowerCase()
					.includes(searchCourseValue.toLowerCase()) ||
				JSON.stringify(item.cost)
					.toLowerCase()
					.includes(searchCourseValue.toLowerCase()) ||
				JSON.stringify(item.targetaudience)
					.toLowerCase()
					.includes(searchCourseValue.toLowerCase()) ||
				JSON.stringify(item.category)
					.toLowerCase()
					.includes(searchCourseValue.toLowerCase())
			) {
				return item;
			}
		});

		setCommunitiesArray(filterBySearch);
	}, [searchCourseValue]);

	useEffect(() => {
		if (setSearchBookValue === "") {
			setEbookArray(books);
			return;
		}
		const filterBySearch = books.filter((item) => {
			if (
				JSON.stringify(item.name)
					.toLowerCase()
					.includes(searchBookValue.toLowerCase()) ||
				JSON.stringify(item.author)
					.toLowerCase()
					.includes(searchBookValue.toLowerCase()) ||
				JSON.stringify(item.isbn)
					.toLowerCase()
					.includes(searchBookValue.toLowerCase()) ||
				JSON.stringify(item.source)
					.toLowerCase()
					.includes(searchBookValue.toLowerCase()) ||
				JSON.stringify(item.subject)
					.toLowerCase()
					.includes(searchBookValue.toLowerCase())
			) {
				return item;
			}
		});
		setEbookArray(filterBySearch);
	}, [searchBookValue]);

	useEffect(() => {
		if (setSearchVideoValue === "") {
			setVideoArray(videos);
			return;
		}
		const filterBySearch = videos.filter((item) => {
			if (
				JSON.stringify(item.name)
					.toLowerCase()
					.includes(searchVideoValue.toLowerCase()) ||
				JSON.stringify(item.duration)
					.toLowerCase()
					.includes(searchVideoValue.toLowerCase()) ||
				JSON.stringify(item.source)
					.toLowerCase()
					.includes(searchVideoValue.toLowerCase())
			) {
				return item;
			}
		});
		setVideoArray(filterBySearch);
	}, [searchVideoValue]);

	return (
		<>
			<Modal
				show={videoModal}
				onHide={handleCloseVideoModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 20px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11"></div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseVideoModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
						<ReactPlayer
							width="100%"
							height="360px"
							controls={true}
							url={videoUrl}
						/>
					</div>
				</Modal.Body>
			</Modal>

			<div className=" " style={{ backgroundColor: "#ffffff" }}>
				<Navbar />
				<Homecarousel />
				{auth.user ? (
					<>
						<div className="tab-m" style={{}}>
							<div id="Tab">
								<ul className="nav1 nav-tabs1">
									<li className="active">
										<a href="#1" data-toggle="tab">
											{/* <i style={{}} className="fa fa-graduation-cap"></i> &nbsp; */}
											My Profile
										</a>
									</li>
									<li>
										<a href="#2" data-toggle="tab">
											{/* <i style={{}} className="fa fa-book"></i>&nbsp; */}
											My Courses
										</a>
									</li>
									<li>
										<a href="#3" data-toggle="tab">
											{/* <i style={{}} className="fa fa-file"></i>&nbsp; */}
											My Books
										</a>
									</li>
									<li>
										<a href="#4" data-toggle="tab">
											{/* <i style={{}} className="fa fa-file"></i>&nbsp; */}
											My Videos
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div className="tab-content ">
							<div className="tab-pane active" id="1">
								<div
									className="row container"
									style={{ paddingBottom: "100px", paddingTop: "100px" }}>
									<div className="col-6">
										<div className="userdetails">
											<div className="">
												<label>Name:&nbsp;</label>
												<label>{userData.fullname}</label>
											</div>
											<div className="">
												<label>Email:&nbsp;</label>
												<label>{userData.email}</label>
											</div>
											<div className="">
												<label>Phone no:&nbsp;</label>
												<label>{userData.mobile}</label>
											</div>
											<div className="">
												<label>Gender:&nbsp;</label>
												<label>{userData.gender}</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tab-pane" id="2">
								<div
									// className="row container"
									style={{ paddingBottom: "100px", paddingTop: "100px" }}>
									<div className="col-12">
										<div className="userdetails">
											<div className="row pt-3 justify-content-around">
												{/* <div className="col-1"></div> */}
												<div
													className="card "
													style={{
														marginRight: "5px",
														border: "none",
														borderRadiusTop: "15px",
														//   marginLeft: "10px",
														borderRadius: "15px",
													}}>
													<div
														className="row"
														style={{
															backgroundColor: "#BBE4A3",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "15px",
															borderTopRightRadius: "15px",
															justifyContent: "end",
														}}>
														<div
															className="col-8"
															style={{ display: "flex", alignItems: "center" }}>
															{/* My Courses */}
														</div>
														<div
															className="col-1"
															style={{
																width: "auto",
																paddingRight: "0px",
																paddingLeft: "0px",
															}}>
															{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
														</div>
														<div className="col-3 uc-searchBar">
															<input
																type="text"
																placeholder=" Search"
																onChange={(e) =>
																	setSearchCourseValue(e.target.value)
																}
																className="uc-formControl1"></input>
														</div>
													</div>
													<div>
														{communitiesArray.length === 0 ? (
															<div
																className=""
																style={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	padding: "30px 30px",
																}}>
																<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
																	<div className="uc-NRF">
																		<img
																			src="../images/Search-no-results-found.svg"
																			alt=""
																		/>
																		<h5
																			style={{
																				fontSize: "28px",
																				fontWeight: "300",
																				color: "#62DDB2",
																			}}>
																			No Courses are added to my courses
																		</h5>
																		{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
																	</div>
																</ul>
															</div>
														) : (
															<div
																className="row"
																style={{
																	backgroundColor: "#BBE4A3",
																}}>
																{communitiesArray.map((e, index) => {
																	return (
																		<div
																			key={e.id}
																			className="col-12"
																			style={{
																				padding: "10px 10px 10px 10px",
																			}}>
																			<div
																				className="card"
																				style={{
																					padding: "5px 5px 5px 5px",
																					// visibility: showCard ? "visible" : "hidden",
																				}}>
																				<div className="row">
																					<div className="col-3">
																						<img
																							alt=""
																							onError={(e) => {
																								e.target.src =
																									"../images/common-couse-image.png";
																							}}
																							src={e.courseimage}
																							style={{ width: "100%" }}></img>
																					</div>
																					<div className="col-6">
																						{" "}
																						<div
																							style={{
																								fontSize: "15px",
																								fontWeight: "500",
																								minHeight: "36px",
																							}}>
																							{e.coursetitle}
																						</div>
																						{/* <div style={{ fontSize: "12px" }}>
																	Instructor&nbsp;&nbsp;
																	<span
																		style={{
																			textDecoration: "underline",
																		}}>
																		{e.instructorinformation}
																	</span>{" "}
																</div> */}
																						{e.language.name !== "English" && (
																							<div style={{ fontSize: "12px" }}>
																								Language&nbsp;&nbsp;
																								<span
																									style={{
																										textDecoration: "underline",
																									}}>
																									{e.language.name}
																								</span>{" "}
																							</div>
																						)}
																						{/* <div style={{ fontSize: "12px" }}>
																					Certification By&nbsp;&nbsp;
																					<span
																						style={{
																							textDecoration: "underline",
																						}}>
																						krishigap
																					</span>{" "}
																				</div> */}
																						{/* <div style={{ fontSize: "12px" }}>
																					Target Audience&nbsp;&nbsp;
																					<span
																						style={{
																							textDecoration: "underline",
																						}}>
																						{e.targetaudience}
																					</span>{" "}
																				</div> */}
																						<div style={{ fontSize: "12px" }}>
																							Duration&nbsp;&nbsp;
																							<span>{e.duration} Hrs</span>
																						</div>
																						<div
																							style={{
																								fontSize: "15px",
																								fontWeight: "500",
																								paddingTop: "13px",
																								paddingBottom: "13px",
																							}}>
																							{e.cost === ""
																								? "Free"
																								: "₹" + e.cost}
																						</div>
																					</div>
																					<div className="col-3">
																						<br />
																						<br />
																						<br />
																						{e.courses_details > 0 && (
																							<div
																								className="lightgreen"
																								style={{
																									textAlign: "center",
																								}}>
																								In Progress
																							</div>
																						)}

																						<div
																							style={{
																								paddingTop: "10px",
																								textAlign: "center",
																							}}>
																							{/* <Link
                                            to={{
                                              pathname: "/pdfreader",
                                              state: {
                                                files: e.files,
                                                courseimage: e.courseimage,
                                                coursetitle: e.coursetitle,
                                              }, // your data array of objects
                                            }}
                                            // target="_blank"
                                          > */}
																							<Button
																								style={{
																									background: "#F96342",
																									border: "none",
																									width: "150px",
																								}}
																								onClick={() => {
																									handleClick(
																										e.files,
																										e.courseimage,
																										e.coursetitle,
																										e.questionlist,
																										e.id,
																										e.admin,
																										e.courses_details
																									);
																								}}>
																								{e.courses_details > 0
																									? "Resume"
																									: "Start"}
																							</Button>
																							{/* </Link> */}
																						</div>
																					</div>
																				</div>

																				{/* <Button
                                  style={{
                                    background: "#36BB7D",
                                    fontSize: "13px",
                                    borderRadius: "4px",
                                    border: "none",
                                  }}
                                  onClick={(item) => handleViewChange(e)}
                                >
                                  <img src="../images/Go-to-My-Courses-icon.svg"></img>{" "}
                                  Go to My Cources
                                </Button> */}
																			</div>
																		</div>
																	);
																})}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane" id="3">
								<div
									// className="row container"
									style={{ paddingBottom: "100px", paddingTop: "100px" }}>
									<div className="col-12">
										<div className="userdetails">
											<div className="row pt-3 justify-content-around">
												<div
													className="card "
													style={{
														marginRight: "5px",
														border: "none",
														borderRadiusTop: "15px",
														//   marginLeft: "10px",
														borderRadius: "15px",
													}}>
													<div
														className="row"
														style={{
															backgroundColor: "#BBE4A3",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "15px",
															borderTopRightRadius: "15px",
															justifyContent: "end",
														}}>
														<div
															className="col-8"
															style={{ display: "flex", alignItems: "center" }}>
															{/* My Courses */}
														</div>
														<div
															className="col-1"
															style={{
																width: "auto",
																paddingRight: "0px",
																paddingLeft: "0px",
															}}>
															{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
														</div>
														<div className="col-3 uc-searchBar">
															<input
																type="text"
																placeholder=" Search"
																onChange={(e) =>
																	setSearchBookValue(e.target.value)
																}
																className="uc-formControl1"></input>
														</div>
													</div>
												</div>
												<div
													className="col-12 card"
													style={{
														backgroundColor: "#BBE4A3",
														border: "none",
														borderRadiusTop: "15px",
														borderRadius: "15px",
													}}>
													{ebookArray.length === 0 ? (
														<div
															className=""
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																padding: "30px 30px",
															}}>
															<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
																<div className="uc-NRF">
																	<img
																		src="../images/Search-no-results-found.svg"
																		alt=""
																	/>
																	<h5
																		style={{
																			fontSize: "28px",
																			fontWeight: "300",
																			color: "#62DDB2",
																		}}>
																		No eBooks are added to my books
																	</h5>
																	{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
																</div>
															</ul>
														</div>
													) : (
														<>
															{ebookArray.map((e) => {
																return (
																	<>
																		<div key={e.id} style={{ padding: "5px" }}>
																			<div
																				className="card"
																				style={{ padding: "5px" }}>
																				<div className="row">
																					<div
																						className="col-3"
																						style={{
																							display: "flex",
																							alignItems: "center",
																							justifyContent: "center",
																						}}>
																						<img
																							alt="book"
																							src="../images/ebook.jpg"
																							style={{ width: "100%" }}></img>
																					</div>
																					<div className="col-7">
																						<div>
																							<span className="ebook">
																								Name:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.name}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								Category:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.category}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								ISBN:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.isbn}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								Subject:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.subject}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								Author:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.author}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								Publisher:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.publisher}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								Cost:
																							</span>
																							&nbsp;
																							<span
																								style={{
																									textDecoration: "underline",
																									fontWeight: "500",
																								}}>
																								{e.cost === ""
																									? "Free"
																									: e.cost}
																							</span>
																						</div>
																						<div>
																							<span className="ebook">
																								Link:
																							</span>
																							&nbsp;
																							{/* <span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}
																			>
																				{e.link}
																			</span> */}
																							<a
																								href={
																									(e.link?.includes("https://")
																										? ""
																										: "https://") + e.link
																								}
																								rel="no-referer noreferrer"
																								target="_blank">
																								{e.link}
																							</a>
																						</div>
																					</div>
																					<div
																						className="col-2"
																						style={{
																							display: "flex",
																							alignItems: "center",
																							justifyContent: "center",
																						}}>
																						{e.file !== "" && (
																							<Button
																								onClick={() => {
																									fileConversion(e.file);
																								}}
																								style={{
																									backgroundColor: "#E23F1C",
																									border: "none",
																									borderRadius: "4px",
																									marginTop: "-6px",
																									color: "#fff",
																								}}>
																								Open
																							</Button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</>
																);
															})}
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane" id="4">
								<div
									// className="row container"
									style={{ paddingBottom: "100px", paddingTop: "100px" }}>
									<div className="col-12">
										<div className="userdetails">
											<div className="row pt-3 justify-content-around">
												<div
													className="card "
													style={{
														marginRight: "5px",
														border: "none",
														borderRadiusTop: "15px",
														//   marginLeft: "10px",
														borderRadius: "15px",
													}}>
													<div
														className="row"
														style={{
															backgroundColor: "#BBE4A3",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "15px",
															borderTopRightRadius: "15px",
															justifyContent: "end",
														}}>
														<div
															className="col-8"
															style={{ display: "flex", alignItems: "center" }}>
															{/* My Courses */}
														</div>
														<div
															className="col-1"
															style={{
																width: "auto",
																paddingRight: "0px",
																paddingLeft: "0px",
															}}>
															{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
														</div>
														<div className="col-3 uc-searchBar">
															<input
																type="text"
																placeholder=" Search"
																onChange={(e) =>
																	setSearchVideoValue(e.target.value)
																}
																className="uc-formControl1"></input>
														</div>
													</div>
												</div>
												<div>
													{videoArray.length === 0 ? (
														<div
															className=""
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																padding: "30px 30px",
															}}>
															<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
																<div className="uc-NRF">
																	<img
																		src="../images/Search-no-results-found.svg"
																		alt=""
																	/>
																	<h5
																		style={{
																			fontSize: "28px",
																			fontWeight: "300",
																			color: "#62DDB2",
																		}}>
																		No Videos are added to my videos
																	</h5>
																	{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
																</div>
															</ul>
														</div>
													) : (
														<div className="row">
															{videoArray.map((e) => {
																return (
																	<div
																		key={e.id}
																		className="col-4"
																		style={{ padding: "10px 10px 10px 10px" }}>
																		<div
																			className="card"
																			style={{ padding: "5px 5px 5px 5px" }}>
																			<ReactPlayer
																				onClick={() => {
																					setVideoModal(true);
																					setVideoUrl(e.link);
																				}}
																				style={{ cursor: "pointer" }}
																				width="100%"
																				height="160px"
																				controls={true}
																				url={e.link}
																			/>
																			<div>
																				<span className="ebook">Name:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e?.name}
																				</span>
																			</div>
																			{/* <div>
																<span className="ebook">Category:</span>
																&nbsp;
																<span
																	style={{
																		textDecoration: "underline",
																		fontWeight: "500",
																	}}>
																	{e?.category}
																</span>
															</div> */}

																			<div>
																				<span className="ebook">Subject:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e?.subject}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Duration:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e?.duration} Mins
																				</span>
																			</div>
																			{/* <div>
																			<span className="ebook">cost:</span>
																			&nbsp;
																			<span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}>
																				{e.cost === "" ? "Free" : e.cost}
																			</span>
																		</div> */}
																			{/* <div>
																				<span className="ebook">Link:</span>
																				&nbsp;
																				{/* <span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}
																			>
																				{e.link}
																			</span> *
																				<a
																					href={
																						(e.link?.includes("https://")
																							? ""
																							: "https://") + e.link
																					}
																					rel="no-referer noreferrer"
																					target="_blank">
																					{e?.link}
																				</a>
																			</div> */}

																			{/* <Button
															style={{
																background: "#36BB7D",
																fontSize: "13px",
																borderRadius: "4px",
																border: "none",
															}}
															// onClick={() => addtomyvideosclick(e.id)}
														>

															Play video
														</Button> */}
																		</div>
																	</div>
																);
															})}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<LoginRequest />
				)}
				<Footer />
			</div>
		</>
	);
};

export default Digitallibrary;
