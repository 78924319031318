import React, { useState } from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Ourteam = () => {
	const [activeTab, setActiveTab] = useState("2");

	return (
		<div style={{}}>
			<Navbar />
			<Homecarousel />
			<div className="page-gap">
				<div
					style={{
						backgroundImage: "url('../images/globalacademybg.jpg')",
						backgroundRepeat: "no-repeat",
					}}>
					<div className="container" style={{}}>
						{/* <h3 className="">Company formation</h3> */}
						<div className="row">
							<div className="col" style={{}}>
								<p className="mb-4"></p>
							</div>
						</div>

						<div id="exTab2">
							<ul className="nav nav-tabs">
								<div className="top-row">
									<li className={activeTab === "2" ? "active" : ""}>
										<a
											href="#2"
											onClick={() => setActiveTab("2")}
											data-toggle="tab">
											Standard and Food Safety Experts
										</a>
									</li>
									<li className={activeTab === "3" ? "active" : ""}>
										<a
											href="#3"
											onClick={() => setActiveTab("3")}
											data-toggle="tab">
											Products Value Chain Experts
										</a>
									</li>
									<li className={activeTab === "8" ? "active" : ""}>
										<a
											href="#8"
											onClick={() => setActiveTab("8")}
											data-toggle="tab">
											Organic Value Chain
										</a>
									</li>
									<li className={activeTab === "6" ? "active" : ""}>
										<a
											href="#6"
											onClick={() => setActiveTab("6")}
											data-toggle="tab">
											Dairy Value Chain
										</a>
									</li>
								</div>
								<div className="bottom-row">
									<li className={activeTab === "7" ? "active" : ""}>
										<a
											href="#7"
											onClick={() => setActiveTab("7")}
											data-toggle="tab">
											Fisheries &amp; Aquaculture Value Chain
										</a>
									</li>
									<li className={activeTab === "4" ? "active" : ""}>
										<a
											href="#4"
											onClick={() => setActiveTab("4")}
											data-toggle="tab">
											Technology Experts
										</a>
									</li>
									<li className={activeTab === "5" ? "active" : ""}>
										<a
											href="#5"
											onClick={() => setActiveTab("5")}
											data-toggle="tab">
											Compliance Experts
										</a>
									</li>
								</div>
							</ul>
						</div>

						<div className="tab-content ">
							<div
								className={`tab-pane2 ${activeTab === "2" ? "active" : ""}`}
								id="2">
								<h3>Standard and Food Safety Experts</h3>
								<br />
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./sriharikotela">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/shri-hari.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./sriharikotela">
													<h5 className="experts-person-name">
														Srihari Kotela
													</h5>
												</a>
												<small>Standard Specialist</small>
												<br />
												<small>
													Ex Founder Foodcert India (Now TQ Cert Services, A
													TATA group company)
												</small>
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./seemashukla">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Dr-Seema-Shukla.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./seemashukla">
													<h5 className="experts-person-name">
														Dr Seema Shukla
													</h5>
												</a>
												<small>Expert on Indian and International</small>
												<br />
												<small>
													(Codex, EU, USFDA, CFIA, ASEAN) standards and
													regulations within food supply chain
												</small>
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./mohanmkulkarni">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Mohan_M_Kulkarni.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./mohanmkulkarni">
													<h5 className="experts-person-name">
														Mr. Mohan M. Kulkarni
													</h5>
												</a>
												<small>
													Standard Expert (EMS, Carbon Footprint, Water Foot
													Print)
												</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./devendraprasad">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/devender-prasad.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./devendraprasad">
													<h5 className="experts-person-name">
														Mr. Devendra Prasad
													</h5>
												</a>
												<small>
													Laboratory and Food Safety Compliances Specialist
												</small>
												<br />
												<small>Former-DGM, APEDA</small>
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./sumantdparkhi">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Sumant_D_Parkhi.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./sumantdparkhi">
													<h5 className="experts-person-name">
														Mr. Sumant D. Parkhi
													</h5>
												</a>
												<small>Standard Expert (QMS, EMS and EnMS)</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./diptisaudagar">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Dipti_Saudagar.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./diptisaudagar">
													<h5 className="experts-person-name">
														Mrs Dipti Saudagar
													</h5>
												</a>
												<small>Food Safety and Compliances Expert</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./chetnaipar">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Chetna_Ipar.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./chetnaipar">
													<h5 className="experts-person-name">
														Ms. Chetna Ipar
													</h5>
												</a>
												<small>
													Food Safety, Quality and Regulatory Compliances.
												</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./onkarchoche">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Onkar_Choche.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./onkarchoche">
													<h5 className="experts-person-name">
														Mr. Onkar Choche
													</h5>
												</a>
												<small>
													Food Safety, Quality and Regulatory Compliances.
												</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className={`tab-pane2 ${activeTab === "3" ? "active" : ""}`}
								id="3">
								<h3 className="pt-3 pb-3">Product Value Chain Experts</h3>
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className=" team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./santhoshjeapen">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/DrSanthoshJEapen.png"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./santhoshjeapen">
													<h5 className="experts-person-name">
														Dr. Santhosh J. Eapen
													</h5>
												</a>
												<small>Spices Specialist</small>
												<br />
												<small>Ex Head ICAR Spices Research</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./abrahamverghese">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/DrAbrahamVerghese.png"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./abrahamverghese">
													<h5 className="experts-person-name">
														Dr. Abraham Verghese
													</h5>
												</a>
												<small>IPM Specialist</small>
												<br />
												<small>Ex Director ,ICAR NBAIR</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./msrao">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Dr-MS-Rao.png"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./msrao">
													<h5 className="experts-person-name">Dr. MS Rao</h5>
												</a>
												<small>Bio Pesticides Specialist</small>
												<br />
												<small>Ex Principal Scientist ,ICAR</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./vilasatonapi">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/vilas-a-tonapi.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./vilasatonapi">
													<h5 className="experts-person-name">
														Dr. Vilas A Tonapi
													</h5>
												</a>
												<small>Millet Value Chain Specialist </small>
												<br />
												<small>Former Director, ICAR-IIMR</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./panduranggundappaadsule">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/pandurang-gundappaad-sule.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./panduranggundappaadsule">
													<h5 className="experts-person-name">
														Dr. Pandurang Gundappa Adsule
													</h5>
												</a>
												<small>Grape Value Chain Specialist</small>
												<br />
												<small>
													Former Director, National Research Centre for Grapes
												</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./kapsebhagwan">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/kapse_bhagwan.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./kapsebhagwan">
													<h5 className="experts-person-name">
														Dr. Kapse Bhagwan
													</h5>
												</a>
												<small>
													Mango, Sweet Orange &amp; Banana Supply Chain Expert
												</small>
												<small>
													Former Director, National Institute of Post-Harvest
													Technology
												</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./jyotsanasharma">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/jyotsana_sharma.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./jyotsanasharma">
													<h5 className="experts-person-name">
														Dr. (Mrs.) Jyotsana Sharma
													</h5>
												</a>
												<small>Pomegranate Value Chain </small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./jogindersinghminhas">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Joginder-Singh-Minhas.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./jogindersinghminhas">
													<h5 className="experts-person-name">
														Joginder Singh Minhas
													</h5>
												</a>
												<small>Potato Value Chain</small>
												<br />
												<small>
													Project Manager, International Potato Center
												</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./dharmeshverma">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/DharmeshVerma.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./dharmeshverma">
													<h5 className="experts-person-name">
														Dr Dharmesh Verma
													</h5>
												</a>
												<small>Basmati Rice Value Chain Expert</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./vijaysinghthakur">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Vijay-Singh-Thakur.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./vijaysinghthakur">
													<h5 className="experts-person-name">
														Dr Vijay Singh Thakur
													</h5>
												</a>
												<small>Apple and Horticulture Value Chain</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>

									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./amarnathsharma">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/amar-nath-sharma.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./amarnathsharma">
													<h5 className="experts-person-name">
														Dr Amar Nath Sharma
													</h5>
												</a>
												<small>Soybean Value Chain</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./jagadeeshwar">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Jagadeeshwar.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./jagadeeshwar">
													<h5 className="experts-person-name">
														Dr R. Jagadeeshwar
													</h5>
												</a>
												<small>Rice Value Chain</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./snsingh">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/S-N-Singh.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./snsingh">
													<h5 className="experts-person-name">Dr S.N. Singh</h5>
												</a>
												<small>Sugarcane Value Chain</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./jkumar">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/J-Kumar.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./jkumar">
													<h5 className="experts-person-name">Dr J. Kumar</h5>
												</a>
												<small>Wheat Value Chain Expert</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./vemuriravindrababu">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Vemuri-Ravindra-Babu.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./vemuriravindrababu">
													<h5 className="experts-person-name">
														Dr. Vemuri Ravindra Babu
													</h5>
												</a>
												<small>Field Crops Value Chain Expert</small>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className={`tab-pane2 ${activeTab === "4" ? "active" : ""}`}
								id="4">
								<h3 className="pt-3 pb-3">Technology Experts</h3>
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./chackojacob">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/chacko_jacob.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./chackojacob">
													<h5 className="experts-person-name">
														Mr Chacko Jacob
													</h5>
												</a>
												<small>
													Co-founder and Chief Business Officer Mist EO
												</small>
												<small>Precision and Remote Sensing Expert</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./venkatpindipolu">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/venkat-pindipolu.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./venkatpindipolu">
													<h5 className="experts-person-name">
														Venkat Pindipolu
													</h5>
												</a>
												<small>Technology Expert </small>
												<br />
												<small>Co-founder, Carbon Mint</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./anjunayyar">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/anju_nayyar.png"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./anjunayyar">
													<h5 className="experts-person-name">Anju Nayyar</h5>
												</a>
												<small>Digital Solutions Expert </small>
												<br />
												<small>
													Senior Advisor – Business Development &amp; Alliances,
													YARA International
												</small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./ramareddykovvuri">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/rama_reddy_kovvuri.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./ramareddykovvuri">
													<h5 className="experts-person-name">
														Rama Reddy Kovvuri
													</h5>
												</a>
												<small>Technology Expert </small>
												<br />
												<small>Vice President Tyisha Technologies</small>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className={`tab-pane2 ${activeTab === "5" ? "active" : ""}`}
								id="5">
								<h3 className="pt-3 pb-3">Compliance Experts</h3>
								<div className="row">
									<div className="col-lg-4 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./ramachandraraotummala">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Ca-ramchandra.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./ramchandraraotummala">
													<h5 className="experts-person-name">
														CA Ramachandra Rao Tummala
													</h5>
												</a>
												<small>Finance, Systems &amp; Compliance Expert </small>
												<small>Founder :T R R &amp; Associates</small>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./akhilmittal">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/akhil_mittal.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./akhilmittal">
													<h5 className="experts-person-name">Akhil Mittal</h5>
												</a>
												<small>Corporate Compliances Expert</small>
												{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className={`tab-pane2 ${activeTab === "6" ? "active" : ""}`}
								id="6">
								<h3 className="pt-3 pb-3">Dairy Value Chain Expert</h3>
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./appajirao">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/T_Appaji_Rao.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./appajirao">
													<h5 className="experts-person-name">T. Appaji Rao</h5>
												</a>
												<small>Dairy Value Chain Expert </small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./mohinderkumarsalooja">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/MohinderKumarSalooja.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./mohinderkumarsalooja">
													<h5 className="experts-person-name">
														Dr. Mohinder Kumar Salooja
													</h5>
												</a>
												<small>Dairy Value Chain Expert </small>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./omveersingh">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Omveer-Singh.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./omveersingh">
													<h5 className="experts-person-name">
														Dr. Omveer Singh
													</h5>
												</a>
												<small>Dairy and Horticulture Value Chain </small>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className={`tab-pane2 ${activeTab === "7" ? "active" : ""}`}
								id="7">
								<h3 className="pt-3 pb-3">
									Fisheries &amp; Aquaculture Value Chain
								</h3>
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./prathapchandrashetty">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Prathap_Chandra_Shetty.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./prathapchandrashetty">
													<h5 className="experts-person-name">
														Prathap Chandra Shetty
													</h5>
												</a>
												<small>
													Fisheries and Aquaculture Value Chain Expert{" "}
												</small>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className={`tab-pane2 ${activeTab === "8" ? "active" : ""}`}
								id="8">
								<center>
									{" "}
									<h3 className="pt-3 pb-3">Organic Value Chain</h3>
								</center>
								<div className="row">
									<div className="col-lg-3 col-md-6">
										<div className="team-item ">
											<div
												className="overflow-hidden"
												style={{ background: "#d8f5c6" }}>
												<center>
													<a href="./pvsmgouri">
														<img
															style={{ width: "100%", height: "100%" }}
															className="img-fluid"
															src="../images/Dr.-P-V-S-M-Gouri.jpg"
															alt=""
														/>
													</a>
												</center>
											</div>
											<div style={{ background: "#d8f5c6" }}></div>
											<div
												className="text-center2 p-4"
												style={{ background: "#d8f5c6" }}>
												<a href="./pvsmgouri">
													<h5 className="experts-person-name">
														Dr. P V S M Gouri
													</h5>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<br />
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};
export default Ourteam;
