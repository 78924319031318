import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./communitystyles.css";

// Specify workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ pdfFile }) => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [scale, setScale] = useState(1.3); // Initial zoom level
	const [searchTerm, setSearchTerm] = useState("");
	const [highlights, setHighlights] = useState([]);
	const [currentHighlightIndex, setCurrentHighlightIndex] = useState(null);
	const [loading, setLoading] = useState(false);

	const pdfViewerRef = useRef(null);
	const observerRef = useRef(null);

	const increaseZoom = () => setScale((prevScale) => prevScale + 0.2);
	const decreaseZoom = () =>
		setScale((prevScale) => (prevScale > 0.2 ? prevScale - 0.2 : prevScale));

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
		setPageNumber(1);
		setSearchTerm("");
	};

	console.log(scale);

	// useEffect(() => {
	// 	if (searchTerm !== "") {
	// 		searchInPdf(searchTerm);
	// 	} else {
	// 		setHighlights([]);
	// 		setCurrentHighlightIndex(null);
	// 	}
	// }, [searchTerm, pdfFile]);

	// const searchInPdf = async (term) => {
	// 	setLoading(true);
	// 	if (searchTerm === "") setHighlights([]);
	// 	const highlightPositions = [];

	// 	const loadingTask = pdfjs.getDocument(pdfFile);
	// 	const pdf = await loadingTask.promise;

	// 	for (let i = 1; i <= pdf.numPages; i++) {
	// 		const page = await pdf.getPage(i);
	// 		const viewport = page.getViewport({ scale });
	// 		const textContent = await page.getTextContent();

	// 		textContent.items.forEach((item) => {
	// 			const text = item.str;
	// 			const itemTransform = item.transform;
	// 			const itemHeight = item.height;
	// 			const itemWidth = item.width;
	// 			const itemX = itemTransform[4];
	// 			const itemY = itemTransform[5];

	// 			let searchIndex = text.toLowerCase().indexOf(term.toLowerCase());

	// 			while (searchIndex !== -1) {
	// 				const charWidth = itemWidth / text.length;
	// 				const x = itemX + searchIndex * charWidth;
	// 				const width = charWidth * term.length;

	// 				// Adjust y position based on viewport height
	// 				const y = 75 % (viewport.height - (itemY + itemHeight));

	// 				highlightPositions.push({
	// 					page: i,
	// 					x,
	// 					y,
	// 					width,
	// 					height: itemHeight,
	// 				});

	// 				searchIndex = text
	// 					.toLowerCase()
	// 					.indexOf(term.toLowerCase(), searchIndex + term.length);
	// 			}
	// 		});
	// 	}

	// 	setHighlights(highlightPositions);
	// 	setLoading(false);

	// 	if (highlightPositions.length > 0) {
	// 		setCurrentHighlightIndex(0);
	// 		setPageNumber(highlightPositions[0].page);
	// 		setTimeout(() => {
	// 			const highlightElement = document.querySelector(
	// 				`[data-highlight-index='0']`
	// 			);
	// 			if (highlightElement) {
	// 				highlightElement.scrollIntoView({
	// 					behavior: "smooth",
	// 					block: "center",
	// 				});
	// 			}
	// 		}, 500);
	// 	}
	// };

	const searchInPdf = async (term) => {
		setLoading(true);
		if (searchTerm === "") setHighlights([]);
		const highlightPositions = [];

		const loadingTask = pdfjs.getDocument(pdfFile);
		const pdf = await loadingTask.promise;

		for (let i = 1; i <= pdf.numPages; i++) {
			const page = await pdf.getPage(i);
			const viewport = page.getViewport({ scale });
			const textContent = await page.getTextContent();

			textContent.items.forEach((item) => {
				const text = item.str;
				const [x, y, itemWidth, itemHeight] = [
					item.transform[4],
					item.transform[5],
					item.width,
					item.height,
				];

				let searchIndex = text.toLowerCase().indexOf(term.toLowerCase());

				while (searchIndex !== -1) {
					const charWidth = itemWidth / text.length;
					const highlightX = x + searchIndex * charWidth;
					const highlightWidth = charWidth * term.length;

					// Adjust Y-coordinate:
					// viewport.height - y aligns with the bottom, itemHeight is subtracted to reach the top
					const highlightY = viewport.height * 0.77 - y - itemHeight;

					highlightPositions.push({
						page: i,
						x: highlightX,
						y: highlightY,
						width: highlightWidth,
						height: itemHeight,
					});

					searchIndex = text
						.toLowerCase()
						.indexOf(term.toLowerCase(), searchIndex + term.length);
				}
			});
		}

		setHighlights(highlightPositions);
		setLoading(false);

		if (highlightPositions.length > 0) {
			setCurrentHighlightIndex(0);
			setPageNumber(highlightPositions[0].page);
			setTimeout(() => {
				const highlightElement = document.querySelector(
					`[data-highlight-index='0']`
				);
				if (highlightElement) {
					highlightElement.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
				}
			}, 500);
		}
	};

	useEffect(() => {
		if (observerRef.current) {
			observerRef.current.disconnect();
		}
		observerRef.current = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === "childList" && mutation.addedNodes.length) {
					mutation.addedNodes.forEach((node) => {
						if (node.nodeName === "A") {
							node.setAttribute("target", "_blank");
						}
						if (node.querySelectorAll) {
							node.querySelectorAll("a").forEach((link) => {
								link.setAttribute("target", "_blank");
							});
						}
					});
				}
			});
		});

		const annotationLayers = document.querySelectorAll(
			".react-pdf__Page__annotations"
		);
		annotationLayers.forEach((layer) => {
			observerRef.current.observe(layer, { childList: true, subtree: true });
		});

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, [pageNumber, numPages]);

	const handleSearch = (event) => {
		event.preventDefault();
		searchInPdf(searchTerm);
	};

	const handleNextHighlight = () => {
		if (highlights.length === 0 || currentHighlightIndex === null) return;
		const nextIndex = (currentHighlightIndex + 1) % highlights.length;
		setCurrentHighlightIndex(nextIndex);
		setPageNumber(highlights[nextIndex].page);
		setTimeout(() => {
			const highlightElement = document.querySelector(
				`[data-highlight-index='${nextIndex}']`
			);
			if (highlightElement) {
				highlightElement.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		}, 500);
	};

	const handlePreviousHighlight = () => {
		if (highlights.length === 0 || currentHighlightIndex === null) return;
		const prevIndex =
			(currentHighlightIndex - 1 + highlights.length) % highlights.length;
		setCurrentHighlightIndex(prevIndex);
		setPageNumber(highlights[prevIndex].page);
		setTimeout(() => {
			const highlightElement = document.querySelector(
				`[data-highlight-index='${prevIndex}']`
			);
			if (highlightElement) {
				highlightElement.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		}, 500);
	};

	const handleNextPage = () => {
		if (pageNumber < numPages) {
			setPageNumber((prevPageNumber) => prevPageNumber + 1);
		}
	};

	const handlePreviousPage = () => {
		if (pageNumber > 1) {
			setPageNumber((prevPageNumber) => prevPageNumber - 1);
		}
	};

	const handleLinkClick = (e) => {
		e.preventDefault();
		const link = e.target.closest("a");
		if (link) {
			window.open(link.href, "_blank");
		}
	};

	const onRenderAnnotationLayerSuccess = () => {
		const links = document.querySelectorAll(".react-pdf__Page__annotations a");
		links.forEach((link) => {
			link.addEventListener("click", handleLinkClick);
		});
	};

	return (
		<div
			className="pdf-container"
			id="pdfviewer"
			ref={pdfViewerRef}
			// onClick={(e) => {
			// 	console.log("e", e);
			// 	e.preventDefault();
			// 	window.open(e, "_blank");
			// }}
			onContextMenu={(e) => e.preventDefault()}>
			<div
				style={{
					display: "flex",
					position: "fixed",
					bottom: "10px",
					right: "10px",
					zIndex: "99",
				}}>
				<button
					className="btn btn-sm"
					style={{ backgroundColor: "#1389e1" }}
					onClick={decreaseZoom}>
					<i className="fas fa-search-minus"></i>
				</button>
				&nbsp;
				{scale !== 1.3 && (
					<Button
						onClick={() => {
							setScale(1.3);
						}}
						style={{ background: "#36BB7D", border: "none" }}
						className="highlight-nav-button">
						Reset
					</Button>
				)}
				&nbsp;
				<button
					className="btn btn-sm"
					style={{ backgroundColor: "#1389e1" }}
					onClick={increaseZoom}>
					<i className="fas fa-search-plus"></i>
				</button>
			</div>

			{/* <Button
				onClick={handlePreviousPage}
				disabled={pageNumber <= 1}
				style={{ marginRight: "5px" }}>
				Previous Page
			</Button>
			<Button
				onClick={handleNextPage}
				disabled={pageNumber >= numPages}
				style={{ marginRight: "5px" }}>
				Next Page
			</Button>
			<Button
				onClick={handlePreviousHighlight}
				disabled={currentHighlightIndex === null || highlights.length === 0}
				style={{ marginRight: "5px" }}
				className="highlight-nav-button">
				Previous Search
			</Button>
			<Button
				onClick={handleNextHighlight}
				disabled={currentHighlightIndex === null || highlights.length === 0}
				className="highlight-nav-button">
				Next Search
			</Button> */}
			<div className="navigation-controls">
				<Button
					onClick={handlePreviousPage}
					disabled={pageNumber <= 1}
					variant="success"
					// style={{ backgroundColor: "#36BB7D", border: "1px solid #36BB7D" }}
					className="nav-button btn btn-sm">
					<FaArrowLeft /> Previous Page
				</Button>
				<form onSubmit={handleSearch} style={{ margin: "20px 0" }}>
					{highlights.length > 0 && (
						<Button
							onClick={handlePreviousHighlight}
							disabled={
								currentHighlightIndex === null || highlights.length === 0
							}
							// variant="info"
							style={{ background: "#36BB7D", border: "none" }}
							className="highlight-nav-button">
							<FaArrowLeft />
						</Button>
					)}
					<input
						type="text"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						placeholder="Search in PDF"
						className="form-control-sm"
						// style={{ marginRight: "10px" }}
					/>
					{/* <button type="submit" className="btn btn-sm btn-primary search-input">

						<i className="fas fa-search"></i>
					</button> */}
					&nbsp;
					<button
						disabled={searchTerm === ""}
						type="submit"
						className="btn btn-sm"
						style={{ backgroundColor: "#1389e1" }}>
						<i className="fas fa-search"></i>
					</button>
					{highlights.length > 0 && (
						<Button
							onClick={handleNextHighlight}
							disabled={
								currentHighlightIndex === null || highlights.length === 0
							}
							// variant="info"
							style={{ background: "#36BB7D", border: "none" }}
							className="highlight-nav-button">
							<FaArrowRight />
						</Button>
					)}
				</form>
				<Button
					onClick={handleNextPage}
					disabled={pageNumber >= numPages}
					variant="success"
					// style={{ backgroundColor: "#36BB7D", border: "1px solid #36BB7D" }}
					className="nav-button btn btn-sm">
					Next Page <FaArrowRight />
				</Button>
			</div>
			<div className="page-info">
				Page {pageNumber} of {numPages}
			</div>
			{loading && (
				<div className="loading-indicator">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}
			<Document
				file={pdfFile}
				loading={
					<div className="parentDisable" width="100%">
						<div className="overlay-box">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				}
				onLoadSuccess={onDocumentLoadSuccess}>
				<Page
					pageNumber={pageNumber}
					scale={scale}
					// onRenderSuccess={() => {
					// 		".react-pdf__Page__annotations a"
					// 	);
					// 	links.forEach((link) => {
					// 		link.target = "_blank";
					// 	});
					// }}
					onRenderAnnotationLayerSuccess={onRenderAnnotationLayerSuccess}>
					{highlights
						.filter((highlight) => highlight.page === pageNumber)
						.map((highlight, index) => (
							<div
								key={index}
								className="highlight"
								data-highlight-index={index}
								style={{
									position: "absolute",
									left: `${highlight.x * scale}px`,
									top: `${highlight.y * scale}px`,
									width: `${highlight.width * scale}px`,
									height: `${highlight.height * scale}px`,
									backgroundColor: "yellow",
									opacity: 0.4,
									pointerEvents: "none", // Ensure highlights don’t interfere with interactions
								}}></div>
						))}
				</Page>
			</Document>
			{/* <div style={{ marginTop: "10px" }}>
				<span style={{ marginLeft: "10px" }}>
					Page {pageNumber} of {numPages}
				</span>
			</div> */}
		</div>
	);
};

export default PdfViewer;
