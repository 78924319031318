import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Usermanagement = () => {
	const { profile, auth } = useSelector((state) => state);
	const [userDetails, setUserDetails] = useState("");

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`getuserscount`).then((res) => {
			console.log("res getuserscount", res.data);
			setUserDetails(res.data.userdetails);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: false },
			});
		});
	}, []);

	return (
		<>
			<Adminnavbar />

			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="dashboard-text">Super Admin Dashboard</div>
					<div id="admin" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8">
									<div
										className="row format"
										style={{
											paddingTop: "20px",
											paddingBottom: "20px",
											// backgroundColor: "#A7D588",
											backgroundImage: "linear-gradient(#a7d588,#c9e6bb)",
										}}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{userDetails.totalusers}</div>
											<div className="text1">Total Users</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">{userDetails.maleusers}</div>
											<div className="text1">Male Users</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											{" "}
											<div className="text1">{userDetails.femaleusers}</div>
											<div className="text1">Female Users</div>
										</div>
									</div>
									{/* <div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">200+</div>
											<div className="text1">Digital Library</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">200+</div>
											<div className="text1">Regulatory Compliances</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Usermanagement;
