import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const VemuriRavindraBabu = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Dr. Vemuri Ravindra Babu</h2>
				</div>
			</div>

			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/Vemuri-Ravindra-Babu.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Past</b>
						<ul className="ulstyle">
							<li>
								National Director (Field crops) at Patanjali Bio-Research
								Institute, Haridwar and trained more than 40000 farmers on
								Organic Farming in 20 states.
							</li>
							<li>
								Director – Indian institute of Rice Research, ICAR, Hyderabad
								and served in different capacities as Principal Scientist, Head
								,Plant Breeding and Project coordinator.
							</li>
							<li>
								Instrumental in developing more than 10 rice varieties, which
								gained popularity among the farming community.
							</li>
							<li>
								Under rice bio-fortification program, developed DRR dhan – 45,
								the first high yielding & high zinc variety notified by Central
								Variety Release Committee.
							</li>
							<li>
								Served in different capacities for 13 years at ICAR–Central Soil
								Salinity Research Institute, Regional Research Station, Anand
								and during this tenure developed saline tolerant varieties in
								paddy, wheat, mustard and Bengal gram.
							</li>
							<li>
								He also identified salt resistant Salvador sp. and other salt
								tolerant grass sps for high salt areas of Bhal region of
								Gujarat.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Recognitions</b>
					<ul className="ulstyle">
						<li>
							As the Director, IIRR, he has monitored the progress of more than
							50 externally funded projects and 20 contractual research
							projects.
						</li>
						<li>
							Received more than 15 individual and team awards including the
							outstanding best ICAR Institute Award and CSIR award for S & T
							innovations for Rural Development.
						</li>
						<li>
							Published more than 120 research papers in peer reviewed national
							and International journals.
						</li>
						<li>
							Presently elected as President, Retired ICAR Employees
							Association, Hyderabad.
						</li>
						<li>
							He has also lead the national bio-fortification programmed on
							different crops and identified many high nutritional varieties.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>M.Sc (Ag)</li>
						<li>Ph.D. (Genetics & Plant Breeding).</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default VemuriRavindraBabu;
