import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const SudhakarGande = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="container">
				<center>
					<div>
						<h2 className="heading-p1">Mr. Sudhakar Gande</h2>
					</div>
					<div>
						<h6 className="heading-p2">Investment Banker, Tech Entrepreneur</h6>
					</div>
					<div>
						<img className="image" src="../images/sudhakar-1.jpg" alt="" />
					</div>
				</center>
			</div>
			<div className="row">
				<div className="col containerin">
					<span>
						Forner Senior Director, American Express Bank
						<br /> Former CEO, Jupiter Capital Pvt. Ltd.
						<br /> Former Vice Chairman, AxisCades Technologies Ltd.
					</span>
					<br />
					<br />
					<b>Present</b>
					<ul className="ulstyle">
						<li>
							Chairman & Managing Director of Hindustan Infrastructure ,&
							Projects Ltd
						</li>
						<li>Founder of Lexicon Finance Ltd and Lexicon Infotech Ltd.</li>
						<li>
							Co-Chairman – Aerospace & Air Defense Task Force at Federation of
							Indian Chambers of Commerce & Industry (FICCI).
						</li>
						<li>Member of FICCI National Working Committee.</li>
					</ul>
					<b>Past</b>
					<ul className="ulstyle">
						<li>Senior Director at American Express Bank Ltd</li>
						<li>MD & CEO of Global Trust Bank</li>
						<li>CEO, Jupiter Capital Pvt. Ltd.</li>
						<li>Vice Chairman, Axiscades Aerospace & Technologies Pvt Ltd</li>
						<li>Chairman at FICCI-Karnataka State Council</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Tech from Indian Institute of Technology, New Delhi and MBA from
							Faculty of Management Studies, New Delhi
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default SudhakarGande;
