import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const RameshPenmetcha = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="container">
				<center>
					<div>
						<h2 className="heading-p1">Mr. Ramesh Penmetcha</h2>
					</div>
					<div>
						<h6 className="heading-p2">Information Technology Specialist</h6>
					</div>
					<div>
						<img className="image" src="../images/Ramesh.jpg" alt="" />
					</div>
				</center>
			</div>
			<div className="row">
				<div className="col containerin">
					<span>
						Former MD, New York Stock Exchange. Held Senior Leadership roles for
						over three decades
					</span>
					<br />
					<br />
					<b>Professional Experience (1990-2024)</b>
					<ul className="ulstyle">
						<li>
							Worked extensively on cutting-edge infrastructure software
							development projects for the New York Stock Exchange (NYSE) over
							three decades
						</li>
						<li>
							Held senior leadership roles, including Senior Systems Analyst,
							Senior Architect, and Managing Director.
						</li>
						<li>
							Key projects included implementing state-of-the-art messaging
							systems using Pub/Sub technology for core NYSE application
							interconnectivity, designing high-throughput, low-latency trading
							platforms, and developing operational monitoring systems to ensure
							performance and alerting for critical applications.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Master of Science (MS) in Computer Science, IIT Bombay, India
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default RameshPenmetcha;
