import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

export default function AddExistingReferences(props) {
	console.log("props add existing files", props);
	const [showExistingReferencesModel, setShowExistingReferencesModel] =
		useState(false);
	const [courses, setCourses] = useState([]);
	const [enablecheckeditems, setEnableCheckeditems] = useState([]);
	const [enablesectioncheckeditems, setEnableSectionCheckeditems] = useState(
		[]
	);
	const [references, setReferences] = useState([]);

	useEffect(() => {
		setCourses(props.courses);
	}, []);

	useEffect(() => {
		let tempReferences = [];
		let tempReferencesNames = [];
		if (courses.length !== 0) {
			courses.forEach((course) => {
				course.referencelist.forEach((value) => {
					if (!tempReferencesNames.includes(value.name)) {
						tempReferences.push(value);
						tempReferencesNames.push(value.name);
					}
				});
			});
		}
		console.log("tempReferences", tempReferences);
		setReferences(tempReferences);
	}, [courses]);

	const handlePushItemIntoTextlistArray = () => {
		let finalCourses = [];
		courses.forEach((course) => {
			course.referencelist.forEach((item) => {
				enablecheckeditems.forEach((value) => {
					if (item.name === value) {
						var duplicate = false;
						finalCourses.forEach((course) => {
							if (course.name === value) {
								duplicate = true;
							}
						});
						if (duplicate === false) {
							finalCourses.push(item);
						}
					}
				});
			});
		});
		console.log("finalcourses", finalCourses);
		props.addFiles(finalCourses);
		setShowExistingReferencesModel(false);
	};

	const handleEnableSectionCheckbox = (event) => {
		const { checked, value } = event.currentTarget;
		console.log("value", value);
		setEnableSectionCheckeditems((prev) =>
			checked ? [...prev, value] : prev.filter((val) => val !== value)
		);

		// courses.forEach((course) => {
		// 	course.textlist.forEach((item) => {
		// 		if (item.section === value) {
		// 			setEnableCheckeditems((prev) =>
		// 				checked
		// 					? [...prev, item.name]
		// 					: prev.filter((val) => val !== item.name)
		// 			);
		// 		}
		// 	});
		// });
	};

	const handleEnableCheckbox = (event) => {
		const { checked, value } = event.currentTarget;
		console.log("value", value);

		setEnableCheckeditems((prev) =>
			checked ? [...prev, value] : prev.filter((val) => val !== value)
		);

		// if (!checked) {
		// 	courses.forEach((course) => {
		// 		course.textlist.forEach((item) => {
		// 			if (item.name === value) {
		// 				setEnableSectionCheckeditems((prev) =>
		// 					prev.filter((val) => val !== item.section)
		// 				);
		// 			}
		// 		});
		// 	});
		// }
	};

	useEffect(() => {
		console.log(
			"enableCheckeditems",
			enablecheckeditems,
			enablesectioncheckeditems
		);
	}, [enablecheckeditems, enablesectioncheckeditems]);

	return (
		<>
			<Modal
				show={showExistingReferencesModel}
				onHide={() => setShowExistingReferencesModel(false)}
				dialogClassName="modal-sm-30px"
				size="lg"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Existing References
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setShowExistingReferencesModel(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>

					<Table bordered style={{ textAlign: "center", marginTop: "10px" }}>
						<thead>
							<tr>
								<th></th>
								<th>S.No</th>
								{/* <th>Subject</th> */}
								<th>Document Name</th>
								<th>Source</th>
								<th>Link</th>
								<th>Document upload</th>
							</tr>
						</thead>
						<tbody>
							{references.length > 0 &&
								references.map((ref, index) => {
									return (
										<tr key={index}>
											<td>
												{" "}
												<input
													type="checkbox"
													value={ref.name}
													checked={enablecheckeditems.some(
														(val) => val === ref.name
													)}
													onChange={handleEnableCheckbox}
													// checked={disableallChecked}
													// onChange={handleAllDisableCheckbox}
												/>
											</td>
											<td>{index + 1}</td>
											{/* <td>{ref?.title?.name}</td> */}
											<td>{ref?.name}</td>
											<td>
												{ref.source !== undefined && ref.source !== "" && (
													<div>{ref.source}</div>
												)}
											</td>
											<td>
												{ref.link !== undefined && ref.link !== "" && (
													<div>
														<a
															href={
																(ref.link?.includes("https://")
																	? ""
																	: "http://") + ref.link
															}
															rel="no-referer noreferrer"
															target="_blank">
															{/* {ref.link} */}
															Click Here
														</a>
													</div>
												)}
											</td>
											<td>
												{ref.file !== undefined && ref.file !== "" && (
													<div>
														<span
															onClick={() => props.showPdf(ref?.file)}
															style={{ cursor: "pointer" }}>
															<img
																alt="pdf"
																style={{
																	width: "40px",
																	height: "50px",
																}}
																src="../images/PDF.svg"></img>
														</span>
													</div>
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>

					<div style={{ textAlign: "center", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							// disabled={courseName === "" || courseDescription === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoTextlistArray();
							}}>
							Add
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<span
				onClick={() => setShowExistingReferencesModel(true)}
				style={{ cursor: "pointer" }}>
				<img
					alt="icon"
					style={{
						width: "18px",
						height: "18px",
						background: "#20ce8f",
						borderRadius: "20px",
					}}
					src="../images/Icon.png"></img>
				&nbsp;
				<span
					className="f-fm fm-w6-s16"
					style={{
						color: "#20ce8f",
						verticalAlign: "middle",
					}}>
					Add Existing References
				</span>
			</span>
		</>
	);
}
