import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Groupmanagement = () => {
	const { profile, auth } = useSelector((state) => state);
	const [dlDetails, setDlDetails] = useState("");
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [name, setname] = useState("");
	const [number, setnumber] = useState("");
	const [email, setemail] = useState("");
	const [password, setPassword] = useState("");
	const [dropdown, setdropdown] = useState([]);
	const [description, setDescription] = useState(false);
	const [pendingUsers, setPendingUsers] = useState([]);

	const dispatch = useDispatch();

	const [groups, setGroups] = useState([]);

	const handleSubmit = async (e) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		e.preventDefault();
		// console.log({ name, number, email, dropdown, password });

		try {
			const res = await postDataAPI("creategroup", {
				name,
				description,
			});
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for creating this group");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: false },
		});
	};

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		if (auth.token) {
			getDataAPI(`getAllGroups`, auth.token).then((res) => {
				console.log("res getgroups", res.data);
				setGroups(res.data.groups);
			});

			getDataAPI(`getAllPendingGroupAdmins`, auth.token).then((res) => {
				console.log("res get pending users", res.data);
				setPendingUsers(res.data.groupAdmins);
			});
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}
	}, [auth.token]);

	// const onSelect = (option) => {
	// 	console.log("option", option);

	// 	setdropdown(option);
	// };

	// const onRemove = (option) => {
	// 	console.log("option", option);

	// 	setdropdown(option);
	// };

	const handleAcceptClick = async (user) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI(
				"updategroupAdmins",
				{
					id: user.id,
					status: "accepted",
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// alert("Thank you for creating this group");
				// window.location.reload();
				const res = await postDataAPI(
					"addmemberingroup",
					{
						groupid: user.group.id,
					},
					auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					// alert("Thank you for creating this group");
					window.location.reload();
				}
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handleDeclineClick = async (user) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI(
				"updategroupAdmins",
				{
					id: user.id,
					status: "declined",
				},
				auth.token
			);

			console.log("res", res);
			if (res.status === 200) {
				// alert("Thank you for creating this group");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<div className="js">
					<div
						className="x-style"
						onClick={handleClose}
						style={{ fontSize: "" }}>
						{" "}
						<span style={{ cursor: "pointer" }}>X</span>
					</div>
					<div className="js-boxIn">
						<div className="js-boxIn-content">
							<div className="ai-greenbox">
								<div>
									<h4 className="js-greenbox-heading">Add Group</h4>
								</div>
								<div>
									<form>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													placeholder="Full Name"
													onChange={(e) => setname(e.target.value)}
												/>
											</span>
											{/* <div className="js-Icon">
												<FontAwesomeIcon icon={faUser} />
											</div> */}
										</div>
										{/* <div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													placeholder="Email"
													onChange={(e) => setemail(e.target.value)}
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faPaperPlane} />
											</div>
										</div> */}
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													placeholder="description"
													onChange={(e) => setDescription(e.target.value)}
												/>
											</span>
											{/* <div className="js-Icon">
												<FontAwesomeIcon icon={faPhone} />
											</div> */}
										</div>
										{/* <div className="js-inputbox">
											<span className="js-inputbox-input">
												<Multiselect
													className=""
													options={coursesArray}
													onSelect={(e) => onSelect(e)}
													onRemove={(e) => onRemove(e)}
													displayValue="coursetitle"
													isObject={true}></Multiselect>
											</span>
											<div className="js-Icon"></div>
										</div> */}
										{/* <div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													placeholder="Password"
													type={showPassword ? "text" : "password"}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</span>
											<span
												className="toggle-password d-flex align-items-center"
												onClick={() => setShowPassword(!showPassword)}>
												{showPassword ? (
													<>
														<FaEyeSlash className="me-1" />
													</>
												) : (
													<>
														<FaEye className="me-1" />
													</>
												)}
											</span>
										</div> */}
										<button
											type="submit"
											className="js-submitbtn"
											onClick={handleSubmit}>
											SUBMIT
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Adminnavbar />

			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="button-format">
						<div className="dashboard-text">Super Admin Dashboard</div>

						<button className="addinstructor-btn" onClick={handleShow}>
							+ ADD GROUP
						</button>
					</div>
					<div id="" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8 " style={{}}>
									<div
										className="row format"
										style={{
											paddingTop: "20px",
											paddingBottom: "20px",
											backgroundColor: "#A7D588",
										}}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{groups.length}</div>
											<div className="text1">Groups</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">{pendingUsers.length}</div>
											<div className="text1">Pending Users</div>
										</div>{" "}
										{/* <div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Courses</div>
										</div> */}
									</div>
									{/* <div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">200+</div>
											<div className="text1">Digital Library</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">200+</div>
											<div className="text1">Regulatory Compliances</div>
										</div>{" "}
										<div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											{" "}
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div>
									</div> */}
								</div>
							</div>

							<div className="row" style={{ padding: "100px" }}>
								<div className="col">
									<h4 className="instructorlist-text">Instructors List</h4>
									<Table>
										<thead id="pl">
											<tr>
												<th>S.No</th>
												<th>Name</th>
												<th>Description</th>
												{/* <th>Phone No</th> */}
											</tr>
										</thead>
										<tbody>
											{groups.map((group, index) => (
												<tr key={group.id}>
													<td>{index + 1}</td>
													<td>{group.name}</td>
													<td>{group.description}</td>
													{/* <td>{group.mobile}</td> */}
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</div>

							<div className="row" style={{ padding: "100px" }}>
								<div className="col">
									<h4 className="instructorlist-text">Pending Users</h4>
									<Table>
										<thead id="pl">
											<tr>
												<th>S.No</th>
												<th>Name</th>
												<th>Group name</th>
												<th>Email</th>
												<th>Gender</th>
												<th>Topics of Interest</th>
												<th>Mobile</th>
												<th>WhatsApp</th>
												<th>Area of Expertise</th>
												<th>Years of Experience</th>
												<th>Food safety standard interested</th>
												<th>Action</th>
											</tr>
										</thead>
										{pendingUsers.map((user, index) => (
											<tr key={user.id}>
												<td>{index + 1}</td>
												<td>{user.name}</td>
												<td>{user.group.name}</td>
												<td>{user.email}</td>
												<td>{user.gender}</td>
												<td>{user.topics_of_interest}</td>
												<td>{user.mobile}</td>
												<td>{user.whatsapp}</td>
												<td>{user.expertise}</td>
												<td>{user.experience}</td>
												<td>{user.f_s_s_intersted}</td>
												<td>
													<button
														onClick={() => handleAcceptClick(user)}
														className="btn btn-success bg-success">
														Accept
													</button>
													<button
														onClick={() => handleDeclineClick(user)}
														className="btn btn-danger bg-danger">
														Decline
													</button>
												</td>
											</tr>
										))}
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Groupmanagement;
