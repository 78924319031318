import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Employerjob() {
	const [job, setJob] = useState({});
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`getJobsById/${window.location.pathname.split("/")[2]}`).then(
			(res) => {
				console.log("res getAllJobsById", res.data);
				setJob(res.data.job);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			}
		);
	}, []);

	const handleDelete = async (reg) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("doc", reg);
		getDataAPI(`inactivejobs/${job.id}`).then((res, err) => {
			console.log("res inactivedoc", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				history.push({
					pathname: "/listofjobseeker",
					state: { email: job.employer.email },
				});
			}
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			// setMycommunitiesArray(res.data.community);
		});
	};

	return (
		<>
			<Navbar />

			<div key={job.id} className="col-12" style={{ padding: "10px" }}>
				<div className="card" style={{ padding: "5px" }}>
					<div className="row">
						<div className="col-9 listofcourses-format">
							<div
								style={{
									fontSize: "20px",
									fontWeight: "500",
									minHeight: "36px",
								}}>
								<label>{job.title}</label>
							</div>
							<div style={{ fontSize: "15px" }}>
								<div>
									Food Safety Standard Interested: <b>{job.f_s_s_intersted}</b>
								</div>
								<div>
									Employment Type: <b>{job.employmenttype}</b>
								</div>
								<div>
									Job Type: <b>{job.jobtype}</b>
								</div>
								<div>
									Job Description <b>{job.description}</b>
								</div>
								<div>
									Work location: <b>{job.city + ", " + job.country}</b>
								</div>
								<div>
									Salary Range: <b>{job.salaryrange}</b>
								</div>
								<div>
									Description: <b>{job.description}</b>
								</div>
								<div>
									Skills: <b>{job.skills}</b>
								</div>
								<div>
									Experience Level: <b>{job.experiencelevel}</b>
								</div>
								<div>
									Deadline: <b>{job.deadline}</b>
								</div>
								<div>
									Submission link: <b>{job.submissionlink}</b>
								</div>
								<div>
									Additional Information: <b>{job.additionalinformation}</b>
								</div>
							</div>
						</div>
						<div className="col-3 listofcourses-format">
							<button
								onClick={() => handleDelete()}
								className="btn btn-danger"
								style={{
									width: "150px",
								}}>
								Delete Job
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
