import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const Cancellationrefundpolicy = () => {
	return (
    <>
      <Navbar />
      <Homecarousel />
      {/* <div className="container-fluid bg-primary py-5 mb-5 page-header">
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-10 text-center">
							<h1 className="display-3 text-white animated slideInDown">
								Cancellation &amp; Refund Policy
							</h1>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb justify-content-center">
									<li className="breadcrumb-item">
										<a className="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										className="breadcrumb-item text-white active"
										aria-current="page">
										Cancellation &amp; Refund Policy
									</li>
								</ol>
								<a href="index.php" className="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div> */}
      <div className="container pt-pb">
        <p className="mb-4" style={{ textAlign: "justify" }}>
          No cancellations &amp; Refunds are entertained.
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Cancellationrefundpolicy;
