import React from "react";

import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Abrahamverghese = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />

			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Dr. Abraham Verghese</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/DrAbrahamVerghese.png"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb" style={{ paddingRight: "25px" }}>
						<b>Present</b>
						<ul className="ulstyle">
							<li>
								Chairman, International Phytosanitary Research & Services.
							</li>
							<li>Editor-in-Chief, Insect Environment.</li>
							<li>
								Asian Representative, Internal Steering Committee on Fruit Fly.
							</li>
						</ul>
						<b>Past</b>
						<ul className="ulstyle">
							<li>
								Director, ICAR-National Bureau of Agricultural Insect Resources
								(NBAIR), Bangalore
							</li>
							<li>
								Principal Scientist & Head, Entomology ICAR-Indian Institute of
								Horticultural Research Bangalore.
							</li>
							<li>Chief Editor, Pest Management in Horticultural Ecosystem.</li>
							<li>
								National Project Coordinator, India–UK-Integrated Management of
								Fruit Flies in India.
							</li>
							<li>
								Member of FAO Asia Pacific Phytosanitary Committee, Bangkok,
								Rome.
							</li>
							<li>
								IPM Consultant, for Agriculture and Processed Food Process
								Export Development Authority (APEDA).
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Awards</b>
					<ul className="ulstyle">
						<li>
							Life Time Achievement Award at the WORLD BIODIVERSITY CONGRESS,
							University of Sri Lanka.
						</li>
						<li>
							Life Time Achievement Award, by Institution of Agricultural
							Technology, Bangalore-2019.
						</li>
						<li>
							Biotech Product and Process Development and Commercialization
							Award by Department of Biotechnology.
						</li>
						<li>
							AusAID International Award from Australia for his work in grape
							trips management.
						</li>
						<li>
							International Biodiversity and Environmental Award in Professional
							Category by International Congress on Biodiversity, Serbia.
						</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>M.Sc (Agri)</li>
						<li>
							Ph.D, FRES (UK), Post-doc training at Imperial College London, UK
						</li>
						<li>Gold Medal for first Rank in both M.Sc. and Ph.D.</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Abrahamverghese;
