import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const ShashiSareen = () => {
	return (
		<>
			<Navbar /> <Homecarousel />
			<div className="container">
				<center>
					<div>
						<h2 className="heading-p1">Ms. Shashi Sareen</h2>
					</div>
					<div>
						<h6 className="heading-p2">
							Export Compliance and Food Safety Standards Expert
						</h6>
					</div>
					<div>
						<img
							className="image"
							src="../images/Ms-Shashi-Sareen.jpg"
							alt=""
						/>
					</div>
				</center>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>
						Ex CEO, Export Inspection Council, Ex Director, Bureau of Indian
						Standards.
					</b>
					<br />
					<br />
					<b>Present</b>
					<ul className="ulstyle">
						<li>
							International Advisor/ Consultant in the areas of food safety ,
							quality, nutrition and one-health associated with various
							international organizations (STDF, World Bank, FAO, UNESCWA,
							UNESCAP, WHO, IFC, etc.) and national (NABCB, India for
							Accreditation).
						</li>
					</ul>
					<b>Past</b>
					<br />
					<span>
						CEO and Director, Export Inspection Council of India for 8 yrs
					</span>
					<br />
					<br />
					<ul className="ulstyle">
						<li>
							Responsible for entire administration, technical, manpower
							aspects, assuring quality for exports of all products notified by
							Govt of India based on requirements of importing governments
							covering marine, poultry, egg, dairy, honey, spices, rice, etc. A
							major area addressed was establishing and implementing food
							control systems in these sectors including contaminant and residue
							monitoring and control programs to meet SPS requirements of
							importing countries like EU, US, Japan, Australia, etc.
							Strengthened labs to bring them up to world class with
							accreditation of these
						</li>
						<li>Director, Bureau of Indian Standards</li>
						<li>Developing standards and implementing certification schemes</li>
						<li>
							Senior Food Safety and Nutrition Officer, FAO Regional Office for
							Asia and the Pacific, United Nations
						</li>
						<li>
							BInternational experience of more than 7 years with FAO as
							responsible for capacity development and advise to governments and
							support country capacity strengthening in the area of food
							safety/quality/ nutrition; responsible for 24 countries. Assessed
							countries capacity on various aspects of food control system and
							supported them to meet their SPS compliance under WTO Agreement.
							Developed and implemented cooperation programmes in the area of
							food safety, quality and nutrition over the last 7-8 years with
							international organizations namely FAO in collaboration with EU,
							UNICEF, World Bank, WHO, etc.
						</li>
					</ul>
					<b>Developing Quality Control & Certification Schemes for export</b>
					<ul className="ulstyle">
						<li>
							Designed, developed and implemented quality control and
							certification schemes at the national level for various sectors
							(Fish & fishery products, Dairy products, Poultry & egg products,
							Honey, Basmati rice, Black pepper, Coffee, Instant foods, Cold
							storages, animal feeds) for the purpose of export incorporating
							the international requirements in terms of GMP/GHP/HACCP,
							traceability, raw material controls, recall systems, etc.
						</li>
					</ul>
					<b>Good Agricultural Practices</b>
					<ul className="ulstyle">
						<li>
							Supported SAARC, ASEAN and Arab regions to implement GAP in fruits
							and vegetables sector. Also developed and trained countries on
							implementing, a scheme and training manual on GAP for fruits and
							vegetables, a training manual on GAP for fruit and vegetable
							sector for Arab region and training manual for implementing
							ASEANGAP.
						</li>
					</ul>
					<b>Vice President – Quality, Aditya Birla Retail Limited</b>
					<ul className="ulstyle">
						<li>
							Overall in charge of quality in one of the largest retail chains
							in India, having more than 600 retail outlets in the form of Super
							markets and Hyper-markets.
						</li>
					</ul>
					<b>Professional Qualifications</b>
					<ul className="ulstyle">
						<li>
							Quality Management Systems auditor and trainer as per ISO 9000.
						</li>
						<li>
							Lead auditors Course on ISO 14001 Environmental Management
							Systems.
						</li>
						<li>Auditor for HACCP (QAS, Australia; NSF, USA).</li>
						<li>IRCA certified ISO 22000 FSMS Lead Auditor.</li>
						<li>IRCA certified ISO 22000 FSMS Lead Auditor.</li>
					</ul>
					<b>Education</b>
					<ul className="ulstyle">
						<li>
							Master’s Degree in Foods and Nutrition from Delhi University with
							first division and second position in Delhi University.
						</li>
						<li>
							Master’s Degree in Human Resource and Organizational Development
							from Delhi University (Delhi School of Economics) with first
							division.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default ShashiSareen;
