import {
	faPaperPlane,
	faPhone,
	faSuitcase,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "country-flag-icons/react/3x2";
import React, { useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import "../Menu.css";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Employer = () => {
	const dropdownRef = useRef(null);
	const dropdownRef1 = useRef(null);
	const dropdownRef2 = useRef(null);

	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenCer, setIsOpenCer] = useState(false);
	const [isOpenDesig, setIsOpenDesig] = useState(false);

	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [selectedOption, setSelectedOption] = useState("Business Type");
	const [selectedCerOption, setSelectedCerOption] = useState("Certifications");
	const [selectedDesigOption, setSelectedDesigOption] = useState("Designation");

	const initialState = {
		name: "",
		website: "",
		address: "",
		email: "",
		fullname: "",
		password: "",
		mobile: "",
	};
	const [userData, setUserData] = useState(initialState);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const toggleDropdownCer = () => {
		setIsOpenCer(!isOpenCer);
	};

	const toggleDropdownDesig = () => {
		setIsOpenDesig(!isOpenDesig);
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setUserData({ ...userData, businesstype: option });
		setIsOpen(false);
	};

	const handleOptionCerClick = (option) => {
		setSelectedCerOption(option);
		setUserData({ ...userData, certification: option });
		setIsOpenCer(false);
	};

	const handleOptionDesigClick = (option) => {
		setSelectedDesigOption(option);
		setUserData({ ...userData, designation: option });
		setIsOpenDesig(false);
	};

	const handlePhoneChange = (value) => {
		// setPhone(value);
		setUserData({ ...userData, mobile: value });
		// console.log("Phone number:", value); // Full number with country code
	};

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};
	const handlePasswordChange = (e) => {
		const value = e.target.value;
		setPassword(value);
		setUserData({ ...userData, password: value });
	};
	const handleRegister = async () => {
		console.log("userdata", userData);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		try {
			const res = await postDataAPI("createemployer", {
				...userData,
				organisationname: userData.name,
				employername: userData.fullname,
			});
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering");
				window.location.href = "./employerlogin";
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}
	};
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="emp">
				<div className="emp-box">
					<div className="">
						<h4>
							To access the job seekers profiles you need to Register or If you
							are already registered <a href="/employerlogin"> Login Here!</a>
						</h4>
					</div>
					<br />
					<div className="emp-heading">
						<h2>Employer Registration</h2>
					</div>
					<div className="emp-boxIn">
						<div className="emp-greenbox">
							<div>
								<h4 className="emp-greenbox-heading">
									1. Organization information
								</h4>
							</div>
							<div>
								{/* <form> */}
								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Name"
											name="name"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faUser} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdown}>
													{selectedOption}
													<span
														className={`js-dropdown-symbol ${
															isOpen ? "open" : ""
														}`}
													/>
												</div>

												{isOpen && (
													<ul className="js-dropdown-list">
														{[
															"Business Type",
															"Agriculture",
															"Food Processing",
															"Food Retail",
															"Food Export",
															"Certification Body",
															"Others",
														].map((option, index) => (
															<li
																key={index}
																className="js-dropdown-item"
																onClick={() => handleOptionClick(option)}>
																{option}
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Employer Website"
											name="website"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faPaperPlane} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Industry"
											name="address"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faUser} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef1}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdownCer}>
													{selectedCerOption}
													<span
														className={`js-dropdown-symbol ${
															isOpenCer ? "open" : ""
														}`}
													/>
												</div>

												{isOpenCer && (
													<ul className="js-dropdown-list">
														{[
															"Certifications",
															"GlobalG.A.P. USDA Organic",
															"Organic NPOP",
															"ISO 22000",
															"FSSC 22000",
															"Others",
														].map((option, index) => (
															<li
																key={index}
																className="js-dropdown-item"
																onClick={() => handleOptionCerClick(option)}>
																{option}
															</li>
														))}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<div>
									<h4 className="emp-greenbox-heading">
										2. Primary Contact Information
									</h4>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											onChange={handleChangeInput}
											type="text"
											placeholder="Full Name"
											name="fullname"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faUser} />
									</div>
								</div>

								<div className="js-inputbox">
									<span className="js-inputbox-input">
										<div className="js-dropdown-container" ref={dropdownRef2}>
											<div className="js-dropdown">
												<div
													className="js-dropdown-header"
													onClick={toggleDropdownDesig}>
													{selectedDesigOption}
													<span
														className={`js-dropdown-symbol ${
															isOpenDesig ? "open" : ""
														}`}
													/>
												</div>

												{isOpenDesig && (
													<ul className="js-dropdown-list">
														{["Designation", "CEO", "HR Manager"].map(
															(option, index) => (
																<li
																	key={index}
																	className="js-dropdown-item"
																	onClick={() =>
																		handleOptionDesigClick(option)
																	}>
																	{option}
																</li>
															)
														)}
													</ul>
												)}
											</div>
										</div>
									</span>
									<div className="js-Icon">
										<FontAwesomeIcon icon={faSuitcase} />
									</div>
								</div>

								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										<input
											type="text"
											onChange={handleChangeInput}
											placeholder="Email"
											name="email"
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faPaperPlane} />
									</div>
								</div>
								<div className="emp-inputbox">
									<span className="js-inputbox-input">
										<input
											placeholder="Password"
											type={showPassword ? "text" : "password"}
											onChange={handlePasswordChange}
										/>
									</span>
									<div
										className="toggle-password d-flex align-items-center emp-Icon"
										onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? (
											<>
												<FaEyeSlash className="me-1" />
											</>
										) : (
											<>
												<FaEye className="me-1" />
											</>
										)}
									</div>
								</div>
								<div className="emp-inputbox">
									<span className="emp-inputbox-input">
										{/* <input
                      type="text"
                      placeholder="Phone Number"
                      onChange={handleChangeInput}
                      name="mobile"
                    /> */}
										<PhoneInput
											country={"in"} // Default country
											value={userData.phone}
											onChange={handlePhoneChange}
											inputStyle={{
												width: "100%",
											}}
										/>
									</span>
									<div className="emp-Icon">
										<FontAwesomeIcon icon={faPhone} />
									</div>
								</div>

								<button onClick={handleRegister} className="emp-submitbtn">
									SUBMIT
								</button>
								{/* </form> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Employer;
