import {
	faPaperPlane,
	faPhone,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import "../Menu.css";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const JobSeeker = () => {
	const [formData, setFormData] = useState({
		name: "",
		gender: "",
		profilepic: "",
		email: "",
		mobile: "",
		location: "",
		linkedin_profile: "",
		education: "",
		expertise: "",
		experience: "",
		job_preference: "",
		f_s_s_intersted: "Food Safety Standard Interested",
		availability: "Availability",
		resume: "",
	});

	const f_s_s_interstedRef = useRef(null);
	const availabilityRef = useRef(null);
	const dispatch = useDispatch();

	const [isf_s_s_interstedOpen, setIsf_s_s_interstedOpen] = useState(false);
	const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false);
	const [jobseekersCount, setJobseekersCount] = useState("");

	const f_s_s_interstedOptions = [
		"Food Safety Standard Interested",
		"On Farm Production",
		"Post Harvest",
		"Sustainability",
		"FSSAI",
		"USDA NOP",
		"USFDA PCQI",
	];

	const availabilityOptions = ["Availability", "Immediate", "Months of Notice"];

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`getJobseekersCount`).then((res) => {
			console.log("res getJobseekersCount", res.data);
			setJobseekersCount(res.data.jobseekersdetails.total);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: false },
			});
		});
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				f_s_s_interstedRef.current &&
				!f_s_s_interstedRef.current.contains(event.target)
			) {
				setIsf_s_s_interstedOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				availabilityRef.current &&
				!availabilityRef.current.contains(event.target)
			) {
				setIsAvailabilityOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleGenderChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			gender: e.target.value,
		}));
	};

	const handleFileChange = (event) => {
		// setFormData((prevState) => ({
		// 	...prevState,
		// 	profilepic: e.target.files[0],
		// }));
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				// setCourseImage(e.target.result);
				setFormData((prevState) => ({
					...prevState,
					profilepic: e.target.result,
				}));
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const togglef_s_s_interstedDropdown = () => {
		setIsf_s_s_interstedOpen(!isf_s_s_interstedOpen);
	};

	const handlef_s_s_interstedSelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			f_s_s_intersted: option,
		}));
		setIsf_s_s_interstedOpen(false);
	};

	const toggleAvailabilityDropdown = () => {
		setIsAvailabilityOpen(!isAvailabilityOpen);
	};

	const handleAvailabilitySelect = (option) => {
		setFormData((prevState) => ({
			...prevState,
			availability: option,
		}));
		setIsAvailabilityOpen(false);
	};

	const handleResumeChange = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			// const url = URL.createObjectURL(file);
			// console.log(url);

			// setObtainingFile(url);
			setFormData((prevState) => ({
				...prevState,
				resume: file,
			}));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("formData", formData);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		const sendData = new FormData();
		if (formData.resume !== "") {
			sendData.append("files", formData.resume);
		}
		for (var key in formData) {
			sendData.append(
				key,
				typeof formData[key] === "object"
					? JSON.stringify(formData[key])
					: formData[key]
			);
		}

		try {
			const res = await postDataAPI("createjobseekers", sendData);
			console.log("res", res);
			if (res.status === 200) {
				alert("Thank you for registering. We will get back to you soon");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="js">
				<div className="js-box">
					<div className="js-heading">
						<h2>Job Seeker</h2>
					</div>
					<br />
					<div className="js-heading">
						<h3>Total Applications: {jobseekersCount}</h3>
					</div>
					<div className="js-boxIn">
						<div className="js-boxIn-content">
							<div className="js-greenbox">
								<div>
									<h4 className="js-greenbox-heading">Fill the form</h4>
								</div>
								<div>
									<form onSubmit={handleSubmit}>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="name"
													value={formData.name}
													onChange={handleInputChange}
													placeholder="Full Name"
													required
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faUser} />
											</div>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<div>
													<span style={{ fontSize: "16px" }}>Gender:</span>
													<label style={{ marginLeft: "5px" }}>
														<input
															type="radio"
															value="Male"
															checked={formData.gender === "Male"}
															onChange={handleGenderChange}
														/>
														Male
													</label>
													<label style={{ marginLeft: "5px" }}>
														<input
															type="radio"
															value="Female"
															checked={formData.gender === "Female"}
															onChange={handleGenderChange}
														/>
														Female
													</label>
												</div>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<span>profilepic(Optional):</span>
												<input
													style={{ paddingLeft: "0px" }}
													type="file"
													onChange={(e) => handleFileChange(e)}
													accept="image/*"
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="email"
													name="email"
													value={formData.email}
													onChange={handleInputChange}
													placeholder="Email"
													required
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faPaperPlane} />
											</div>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="tel"
													name="mobile"
													value={formData.mobile}
													onChange={handleInputChange}
													placeholder="Phone Number"
												/>
											</span>
											<div className="js-Icon">
												<FontAwesomeIcon icon={faPhone} />
											</div>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="location"
													value={formData.location}
													onChange={handleInputChange}
													placeholder="Location"
													required
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="linkedin_profile"
													value={formData.linkedin_profile}
													onChange={handleInputChange}
													placeholder="LinkedIn Profile"
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="education"
													value={formData.education}
													onChange={handleInputChange}
													placeholder="Education"
													required
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<div
													className="js-dropdown-container"
													ref={f_s_s_interstedRef}>
													<div className="js-dropdown">
														<div
															className="js-dropdown-header"
															onClick={togglef_s_s_interstedDropdown}>
															{formData.f_s_s_intersted}
															<span
																className={`js-dropdown-symbol ${
																	isf_s_s_interstedOpen ? "open" : ""
																}`}
															/>
														</div>

														{isf_s_s_interstedOpen && (
															<ul className="js-dropdown-list">
																{f_s_s_interstedOptions.map((option, index) => (
																	<li
																		key={index}
																		className="js-dropdown-item"
																		onClick={() =>
																			handlef_s_s_interstedSelect(option)
																		}>
																		{option}
																	</li>
																))}
															</ul>
														)}
													</div>
												</div>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="expertise"
													value={formData.expertise}
													onChange={handleInputChange}
													placeholder="Area of Expertise"
													required
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="experience"
													value={formData.experience}
													onChange={handleInputChange}
													placeholder="Years of Expertise"
													required
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<input
													type="text"
													name="job_preference"
													value={formData.job_preference}
													onChange={handleInputChange}
													placeholder="Job Preference"
													required
												/>
											</span>
										</div>

										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<div
													className="js-dropdown-container"
													ref={availabilityRef}>
													<div className="js-dropdown">
														<div
															className="js-dropdown-header"
															onClick={toggleAvailabilityDropdown}>
															{formData.availability}
															<span
																className={`js-dropdown-symbol ${
																	isAvailabilityOpen ? "open" : ""
																}`}
															/>
														</div>

														{isAvailabilityOpen && (
															<ul className="js-dropdown-list">
																{availabilityOptions.map((option, index) => (
																	<li
																		key={index}
																		className="js-dropdown-item"
																		onClick={() =>
																			handleAvailabilitySelect(option)
																		}>
																		{option}
																	</li>
																))}
															</ul>
														)}
													</div>
												</div>
											</span>
										</div>
										<span>Note: Upload resume in pdf format</span>
										<div className="js-inputbox">
											<span className="js-inputbox-input">
												<span>Resume:</span>
												<input
													style={{ paddingLeft: "0px" }}
													type="file"
													onChange={(e) => handleResumeChange(e)}
													accept="application/pdf"
												/>
											</span>
										</div>

										<button type="submit" className="js-submitbtn">
											SUBMIT
										</button>
									</form>
								</div>
							</div>
						</div>
						<div className="js-boxIn-Image">
							<div className="js-boxIn-ImageIn">
								<img src="../images/job.png" alt="job" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default JobSeeker;
