import { React, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Superadmin = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [name, Setname] = useState("");
	const [order, Setorder] = useState("");
	const [document, Setdocument] = useState("");
	const [complience, SetComplience] = useState([]);

	const handleSubmit = async (e) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		e.preventDefault();
		console.log({ name, order, document });

		const sendData = new FormData();
		if (document !== "") {
			sendData.append("files", document);
		}
		sendData.append("name", name);
		sendData.append("order", order);

		try {
			const res = await postDataAPI("createregulatory", sendData, auth.token);
			console.log("res", res);
			if (res.status === 200) {
				alert("Upload Successfull!!!");
				window.location.reload();
			}
		} catch (err) {
			console.log("err", err);
			// dispatch({
			// 	type: GLOBALTYPES.ALERT,
			// 	payload: { error: err.response.data.msg },
			// });
		}
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
	};

	const handleChange = (e) => {
		let file = e.target.files[0];
		console.log("file", file);
		Setdocument(file);
	};

	// const complience = [
	// 	{
	// 		id: "1",
	// 		name: "Sarkar",
	// 		order: "1",
	// 		document: "Pdf",
	// 	},
	// 	{
	// 		id: "2",
	// 		name: "Business",
	// 		order: "1",
	// 		document: "Pdf",
	// 	},
	// 	{
	// 		id: "3",
	// 		name: "Global",
	// 		order: "1",
	// 		document: "Pdf",
	// 	},
	// ];

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`getregulatories`).then((res) => {
			console.log("res getregulatories", res.data);
			SetComplience(res.data.regulatories);
		});
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
	}, []);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	const handleDelete = async (reg) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("doc", reg);
		getDataAPI(`inactiveregulatory/${reg.id}`, auth.token).then((res, err) => {
			console.log("res inactivedoc", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				window.location.reload();
			}
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			// setMycommunitiesArray(res.data.community);
		});
	};

	return (
		<>
			<Adminnavbar />
			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="button-format">
						<div className="dashboard-text">
							Regulory Compliances Management
						</div>
						<button className="addinstructor-btn" onClick={handleShow}>
							+ ADD
						</button>
						<Modal show={show} onHide={handleClose}>
							<div className="js">
								<div
									className="x-style"
									onClick={handleClose}
									style={{ fontSize: "" }}>
									{" "}
									<span style={{ cursor: "pointer" }}>X</span>
								</div>
								<div className="js-boxIn">
									<div className="js-boxIn-content">
										<div className="ai-greenbox">
											<div>
												<h4 className="js-greenbox-heading">
													Regulatory Compliances
												</h4>
											</div>
											<div>
												<form>
													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="text"
																placeholder="Name"
																onChange={(e) => Setname(e.target.value)}
															/>
														</span>
													</div>

													<div className="js-inputbox">
														<span className="js-inputbox-input">
															<input
																type="number"
																placeholder="Order"
																onChange={(e) => Setorder(e.target.value)}
															/>
														</span>
													</div>
													<b>
														<span>Note: Please upload file in pdf format</span>
													</b>
													<div className="js-inputbox">
														<span className="scclass-inputbox-input">
															<label>Document: </label>{" "}
															<input
																type="file"
																placeholder="document"
																onChange={handleChange}
															/>
														</span>
													</div>

													<button
														type="submit"
														className="js-submitbtn"
														onClick={handleSubmit}>
														SUBMIT
													</button>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Modal>
					</div>
					<div id="" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div className="col-8 " style={{}}>
									<Table>
										<thead id="pl">
											<tr
												style={{
													backgroundColor: "rgb(167, 213, 136)",
													fontSize: "15px",
													fontWeight: "700",
													letterSpacing: "0.5px",
													textAlign: "center",
												}}>
												<th>S.No</th>
												<th>Name</th>
												<th>Order</th>
												<th>Document </th>
												<th>Action </th>
											</tr>
										</thead>
										<tbody>
											{complience.length > 0 ? (
												complience.map((val, index) => {
													return (
														<tr
															key={index}
															style={
																index % 2 === 0 ? styles.evenRow : styles.oddRow
															}>
															<td
																style={{
																	fontSize: "20px",
																	textAlign: "center",
																	fontFamily: "Roboto, sans-serif",
																}}>
																{index + 1}
															</td>
															<td
																style={{
																	fontSize: "20px",
																	textAlign: "center",
																	fontFamily: "Roboto, sans-serif",
																}}>
																{val.name}
															</td>
															<td
																style={{
																	fontSize: "20px",
																	textAlign: "center",
																	fontFamily: "Roboto, sans-serif",
																}}>
																{val.order}
															</td>
															<td
																style={{
																	fontSize: "20px",
																	textAlign: "center",
																	fontFamily: "Roboto, sans-serif",
																}}>
																{/* {val.document} */}
																<Button
																	onClick={() => {
																		fileConversion(val.source);
																	}}
																	style={{
																		backgroundColor: "#00ccff",
																		border: "none",
																		borderRadius: "4px",
																		margin: "5px",
																		color: "#fff",
																		// height: "30px",
																	}}>
																	View File
																</Button>
															</td>

															<td
																style={{
																	fontSize: "20px",
																	textAlign: "center",
																	fontFamily: "Roboto, sans-serif",
																}}>
																{/* {val.document} */}
																{/* <Button
															style={{
																// background:
																// 	"#E23F1C",
																fontSize: "13px",
																borderRadius: "4px",
																border: "none",
															}}
															className="btn btn-warning mt-2 col-6"
															onClick={() => handleEdit(val)}>
															<i className="fas fa-edit"></i>
															Edit doc
														</Button> */}
																<Button
																	style={{
																		background: "#E23F1C",
																		fontSize: "13px",
																		borderRadius: "4px",
																		border: "none",
																	}}
																	className="btn btn-danger mt-2"
																	onClick={() => handleDelete(val)}>
																	<i
																		className="fa fa-trash"
																		aria-hidden="true"></i>
																	{/* Delete doc */}
																</Button>
															</td>
															{/* <td>
																<hr></hr>
															</td> */}
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan="4" style={{ textAlign: "center" }}>
														No data available
													</td>

													{/* <h3>No data</h3> */}
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
const styles = {
	evenRow: {
		backgroundColor: "#f9f9f9",
	},
	oddRow: {
		backgroundColor: "#e9e9e9", // Slightly darker color for odd rows
	},
};
export default Superadmin;
