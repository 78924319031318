import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Menu.css"; // Import the custom CSS
import { logout } from "../redux/actions/authAction";

const Adminnavbar = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(null);

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	const toggleDropdown = (index) => {
		setDropdownOpen(dropdownOpen === index ? null : index);
	};
	const notifications = [
		{
			id: "1",
			massage: "you got one notification",
		},
		{
			id: "2",
			massage: "you got one notification",
		},
		{
			id: "3",
			massage: "you got one notification",
		},
		{
			id: "4",
			massage: "you got one notification",
		},
	];

	return (
		<div className="menu-bar v2">
			{/* Menu Bar Start */}
			<div>
				<div className="">
					<div className="menu-bar-content">
						{/* Logo Section */}
						<div className="menu-logo">
							<a href="/">
								<img
									href="/"
									src="../images/LOGO.png"
									alt="logo"
									height={100}
								/>
							</a>

							<div className="logo-text-container">
								<span className="logo-divider"></span>
								<a
									className="logo-text nav-links"
									href="/"
									style={{ padding: "0" }}>
									GLOBAL ACADEMY <br /> ON FOOD SAFETY AND <br />
									SUSTAINABLE STANDARDS
								</a>
							</div>
						</div>

						{/* Navigation Menu */}
						<nav className="main-menu">
							<ul className="menu-list">
								<li className="menu-item nav-item">
									<a title="Home" href="/home" className="nav-links">
										Home
									</a>
								</li>
								{/* <li className="menu-item nav-item">
                  <div id="" className="">
                    <div className="nav-links" onClick={handleShow}>
                      Notifications
                    </div>
                    <div id="">
                      <Modal
                        id="modalviewer"
                        show={show}
                        onHide={handleClose}
                        style={{ margin: "0", float: "inline-end" }}
                      >
                        <div
                          className="x-style1"
                          onClick={handleClose}
                          style={{ fontSize: "" }}
                        >
                          {" "}
                          <span style={{ cursor: "pointer" }}>X</span>
                        </div>
                        <div id="modalsetup" className="js-boxIn">
                          <div className="js-boxIn-content">
                            <div className="ai-greenbox1">
                              <div>
                                <h4 className="js-greenbox-heading">
                                  Notifications
                                </h4>
                              </div>
                              <div id="notifyalign" className="col">
                                <div className="">
                                  {notifications.map((k) => {
                                    return (
                                      <>
                                        <div className="notifyfont">
                                          {k.massage}
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a href="/allnotifications" alt="">
                          <div
                            className="notifyviewall"
                            style={{ padding: "10px 0px" }}
                          >
                            {" "}
                            View All
                          </div>
                        </a>
                      </Modal>
                    </div>
                  </div>
                </li> */}

								<li className="menu-item nav-item">
									{auth.user === undefined ? (
										<a title="Log In" href="/login" className="nav-links">
											<i
												className="fa fa-user"
												aria-hidden="true"
												style={{ marginRight: "8px" }}></i>
											Log In
										</a>
									) : (
										<>
											<a
												title="Logout"
												href="/login"
												onClick={() => dispatch(logout())}
												className="nav-links">
												<i
													className="fa fa-sign-out"
													aria-hidden="true"
													style={{ marginRight: "8px" }}></i>
												Logout
											</a>
										</>
									)}
								</li>
							</ul>
						</nav>

						{/* Mobile Menu Button */}
						<button
							className="mobile-menu-btn"
							onClick={toggleMobileMenu}
							aria-label="Toggle mobile menu">
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
				</div>
			</div>
			{/* Menu Bar End */}
		</div>
	);
};

export default Adminnavbar;
