import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Adminnavbar from "./adminnavbar";
import Footer from "./footer";
import LoginRequest from "./loginRequest";
import Sidenavbar from "./sidenav";

const Classroommanagement = () => {
	const { profile, auth } = useSelector((state) => state);
	const [allClasses, setAllClasses] = useState([]);
	const [totalParticipants, setTotalParticipants] = useState(0);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		const fetchAllClasses = async () => {
			try {
				const res = await getDataAPI("getAllClasses", auth.token);
				console.log("res", res.data);
				setAllClasses(res.data.scheduleClasses);
				setTotalParticipants(res.data.totalparticipants);
			} catch (err) {
				console.error("Error fetching classes:", err);
			}
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: false },
			});
		};

		if (auth.token) {
			fetchAllClasses();
		}
	}, [auth.token]);

	const todayDate = moment().startOf("day");
	const previousDate = moment().subtract(1, "days").startOf("day");
	const upcomingDate = moment().add(1, "days").startOf("day");

	const getClassCategory = (classDate) => {
		const classMoment = moment(classDate, "DD/MM/YYYY h:mm A").startOf("day");

		if (classMoment.isSame(todayDate, "day")) {
			return "present";
		} else if (classMoment.isBefore(todayDate, "day")) {
			return "previous";
		} else if (classMoment.isAfter(todayDate, "day")) {
			return "upcoming";
		}
		return "";
	};
	console.log(allClasses);

	const handleDelete = async (reg) => {
		console.log("doc", reg);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

		getDataAPI(`inActivescheduleclass/${reg}`, auth.token).then((res, err) => {
			console.log("res inactivedoc", res.data);
			if (err) {
				console.error("Error fetching classes:", err);
				// dispatch({
				// 	type: GLOBALTYPES.ALERT,
				// 	payload: { error: err.response.data.msg },
				// });
			}
			if (res.status === 200) {
				window.location.reload();
			}
			// setMycommunitiesArray(res.data.community);
		});
	};

	return (
		<>
			<Adminnavbar />
			{/* <Homecarousel /> */}
			{auth.user ? (
				<div
					className=""
					style={{ backgroundColor: "#ffffff", padding: "100px 30px" }}>
					<div className="button-format">
						<div className="dashboard-text">Super Admin Dashboard</div>
						<a href="/schedule-a-class">
							<button className="addinstructor-btn">+ SCHEDULE A CLASS</button>
						</a>
					</div>
					<div id="" className="" style={{}}>
						<div className="">
							<div className="row format">
								<Sidenavbar />
								<div
									className="col-8 card-format-analatics"
									style={{ backgroundColor: "#A7D588" }}>
									<div
										className="row format"
										style={{ paddingTop: "20px", paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#24A2B3" }}>
											<div className="text1">{allClasses.length}</div>
											<div className="text1">Total Classes</div>
										</div>
										<div
											className="col text-format"
											style={{ backgroundColor: "#E96CB4" }}>
											<div className="text1">
												{
													allClasses.filter(
														(scheduleClasses) =>
															getClassCategory(
																scheduleClasses.formattedDateTime
															) === "previous"
													).length
												}
											</div>
											<div className="text1">Previous Classes</div>
										</div>
										<div
											className="col text-format"
											style={{ backgroundColor: "#7F2DA2" }}>
											<div className="text1">
												{
													allClasses.filter(
														(scheduleClasses) =>
															getClassCategory(
																scheduleClasses.formattedDateTime
															) === "present"
													).length
												}
											</div>
											<div className="text1">Present Classes</div>
										</div>
									</div>
									<div className="row format" style={{ paddingBottom: "20px" }}>
										<div
											className="col text-format"
											style={{ backgroundColor: "#C78B62" }}>
											<div className="text1">
												{
													allClasses.filter(
														(scheduleClasses) =>
															getClassCategory(
																scheduleClasses.formattedDateTime
															) === "upcoming"
													).length
												}
											</div>
											<div className="text1">Upcoming Classes</div>
										</div>
										<div
											className="col text-format"
											style={{ backgroundColor: "#4C87B8" }}>
											<div className="text1">{totalParticipants}</div>
											<div className="text1">Total Participants</div>
										</div>
										{/* <div
											className="col text-format"
											style={{ backgroundColor: "#8572D6" }}>
											<div className="text1">200+</div>
											<div className="text1">Placements</div>
										</div> */}
									</div>
								</div>
							</div>

							<div className="row" style={{ padding: "100px" }}>
								<h4 className="instructorlist-text">
									List Of Schedule Classes
								</h4>
								<div id="listclasses" className="col">
									<Tabs defaultActiveKey="second">
										<Tab eventKey="first" title="<<Previous Classes">
											<div className="" style={{ paddingTop: "50px" }}>
												<Table bordered striped style={{ textAlign: "center" }}>
													<thead id="pl">
														<tr>
															<th>S.No</th>
															{/* <th>Class Name</th> */}
															<th>Organisation Name</th>
															<th>Date and Time</th>
															<th>Course Name</th>
															<th>Instructor</th>
															<th>No. Of Numbers</th>
															<th>Class URL</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{allClasses && allClasses.length > 0 ? (
															allClasses
																.filter(
																	(scheduleClasses) =>
																		getClassCategory(
																			scheduleClasses.formattedDateTime
																		) === "previous"
																)
																.map((scheduleClasses, index) => (
																	<tr key={index}>
																		<td>{index + 1}</td>

																		{/* <td>{scheduleClasses.className}</td> */}
																		<td>{scheduleClasses.organisationName}</td>
																		<td>{scheduleClasses.formattedDateTime}</td>
																		<td>{scheduleClasses.courseName}</td>
																		<td>
																			{scheduleClasses.instructor !== "" &&
																				JSON.parse(scheduleClasses.instructor)
																					.name}
																		</td>
																		<td>{scheduleClasses.noOfMembers}</td>
																		<td>
																			<a href={scheduleClasses.classURL}>
																				{scheduleClasses.classURL}
																			</a>
																		</td>
																		<td
																			style={{
																				fontSize: "20px",
																				textAlign: "center",
																				fontFamily: "Roboto, sans-serif",
																			}}>
																			{/* {val.document} */}
																			<Button
																				onClick={() => {
																					// fileConversion(val.source);
																					window.location.href =
																						"/attendancemanagement/" +
																						scheduleClasses.classId;
																				}}
																				style={{
																					backgroundColor: "#00ccff",
																					border: "none",
																					borderRadius: "4px",
																					margin: "5px",
																					color: "#fff",
																					// height: "30px",
																				}}>
																				View
																			</Button>
																			<Button
																				style={{
																					background: "#E23F1C",
																					fontSize: "13px",
																					borderRadius: "4px",
																					border: "none",
																				}}
																				className="btn btn-danger mt-2 col-6"
																				onClick={() =>
																					handleDelete(scheduleClasses.classId)
																				}>
																				<i
																					className="fa fa-trash"
																					aria-hidden="true"></i>
																				{/* Delete doc */}
																			</Button>
																		</td>
																	</tr>
																))
														) : (
															<tr>
																<td colSpan="7">
																	No previous classes available at the moment.
																</td>
															</tr>
														)}
													</tbody>
												</Table>
											</div>
										</Tab>

										<Tab eventKey="second" title="Present Classes">
											<div className="" style={{ paddingTop: "50px" }}>
												<Table bordered striped style={{ textAlign: "center" }}>
													<thead id="pl">
														<tr>
															<th>S.No</th>
															{/* <th>Class Name</th> */}
															<th>Organisation Name</th>
															<th>Date and Time</th>
															<th>Course Name</th>
															<th>Instructor</th>
															<th>No. Of Numbers</th>
															<th>Class URL</th>
															<th>Action</th>
														</tr>
													</thead>

													{/* Filter and show only present classes */}
													<tbody>
														{allClasses && allClasses.length > 0 ? (
															allClasses
																.filter(
																	(scheduleClasses) =>
																		getClassCategory(
																			scheduleClasses.formattedDateTime
																		) === "present"
																)
																.map((scheduleClasses, index) => (
																	<tr key={index}>
																		<td>{index + 1}</td>
																		{/* <td>{scheduleClasses.className}</td> */}{" "}
																		<td>{scheduleClasses.organisationName}</td>
																		<td>{scheduleClasses.formattedDateTime}</td>
																		<td>{scheduleClasses.courseName}</td>
																		<td>
																			{scheduleClasses.instructor !== "" &&
																				JSON.parse(scheduleClasses.instructor)
																					.name}
																		</td>
																		<td>{scheduleClasses.noOfMembers}</td>
																		<td>
																			<a href={scheduleClasses.classURL}>
																				{scheduleClasses.classURL}
																			</a>
																		</td>
																		<td
																			style={{
																				fontSize: "20px",
																				textAlign: "center",
																				fontFamily: "Roboto, sans-serif",
																			}}>
																			{/* {val.document} */}
																			<Button
																				onClick={() => {
																					// fileConversion(val.source);
																					window.location.href =
																						"/attendancemanagement/" +
																						scheduleClasses.classId;
																				}}
																				style={{
																					backgroundColor: "#00ccff",
																					border: "none",
																					borderRadius: "4px",
																					margin: "5px",
																					color: "#fff",
																					// height: "30px",
																				}}>
																				View
																			</Button>
																			<Button
																				style={{
																					background: "#E23F1C",
																					fontSize: "13px",
																					borderRadius: "4px",
																					border: "none",
																				}}
																				className="btn btn-danger mt-2 col-6"
																				onClick={() =>
																					handleDelete(scheduleClasses.classId)
																				}>
																				<i
																					className="fa fa-trash"
																					aria-hidden="true"></i>
																				{/* Delete doc */}
																			</Button>
																		</td>
																	</tr>
																))
														) : (
															<tr>
																<td colSpan="7">
																	No present classes available at the moment.
																</td>
															</tr>
														)}
													</tbody>
												</Table>
											</div>
										</Tab>

										<Tab eventKey="third" title="Upcoming Classes>>">
											<div className="" style={{ paddingTop: "50px" }}>
												<Table bordered striped style={{ textAlign: "center" }}>
													<thead id="pl">
														<tr>
															<th>S.No</th>
															{/* <th>Class Name</th> */}{" "}
															<th>Organisation Name</th>
															<th>Date and Time</th>
															<th>Course Name</th>
															<th>Instructor</th>
															<th>No. Of Numbers</th>
															<th>Class URL</th>
															<th>Action</th>
														</tr>
													</thead>

													{/* Filter and show only upcoming classes */}
													<tbody>
														{allClasses && allClasses.length > 0 ? (
															allClasses
																.filter(
																	(scheduleClasses) =>
																		getClassCategory(
																			scheduleClasses.formattedDateTime
																		) === "upcoming"
																)
																.map((scheduleClasses, index) => (
																	<tr key={index}>
																		<td>{index + 1}</td>
																		{/* <td>{scheduleClasses.className}</td> */}{" "}
																		<td>{scheduleClasses.organisationName}</td>
																		<td>{scheduleClasses.formattedDateTime}</td>
																		<td>{scheduleClasses.courseName}</td>
																		<td>
																			{scheduleClasses.instructor !== "" &&
																				JSON.parse(scheduleClasses.instructor)
																					.name}
																		</td>
																		<td>{scheduleClasses.noOfMembers}</td>
																		<td>
																			<a href={scheduleClasses.classURL}>
																				{scheduleClasses.classURL}
																			</a>
																		</td>
																		<td
																			style={{
																				fontSize: "20px",
																				textAlign: "center",
																				fontFamily: "Roboto, sans-serif",
																			}}>
																			{/* {val.document} */}
																			<Button
																				onClick={() => {
																					// fileConversion(val.source);
																					window.location.href =
																						"/attendancemanagement/" +
																						scheduleClasses.classId;
																				}}
																				style={{
																					backgroundColor: "#00ccff",
																					border: "none",
																					borderRadius: "4px",
																					margin: "5px",
																					color: "#fff",
																					// height: "30px",
																				}}>
																				View
																			</Button>
																			<Button
																				style={{
																					background: "#E23F1C",
																					fontSize: "13px",
																					borderRadius: "4px",
																					border: "none",
																				}}
																				className="btn btn-danger mt-2 col-6"
																				onClick={() =>
																					handleDelete(scheduleClasses.classId)
																				}>
																				<i
																					className="fa fa-trash"
																					aria-hidden="true"></i>
																				{/* Delete doc */}
																			</Button>
																		</td>
																	</tr>
																))
														) : (
															<tr>
																<td colSpan="7">
																	No upcoming classes available at the moment.
																</td>
															</tr>
														)}
													</tbody>
												</Table>
											</div>
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
			<Footer />
		</>
	);
};
export default Classroommanagement;
