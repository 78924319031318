import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const SanjayDave = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="container">
				<center>
					<div>
						<h2 className="heading-p1">Mr. Sanjay Dave</h2>
					</div>
					<div>
						<h6 className="heading-p2">
							Senior International Expert on Food Safety, FAO and WHO
						</h6>
					</div>
					<div>
						<img className="image" src="../images/MrSanjayDave.png" alt="" />
					</div>
				</center>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Present</b>
					<ul className="ulstyle">
						<li>
							Senior International Expert to the Food and Agricultural
							Organization, World Health Organization, ITC, IAEA and Asian
							Development Bank on various Food Safety related capacity building
							programmes.
						</li>
					</ul>
					<b>Past</b>
					<ul className="ulstyle">
						<li>
							Global Chairperson of Codex Alimentarius Commission (a UN body
							jointly under Food and Agricultural Organization and World Health
							Organization) and Advisor to the Food Safety and Standards
							Authority of India.
						</li>
						<li>
							Served as a Diplomat in the Mission of India to the European Union
							at Brussels.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default SanjayDave;
