import "font-awesome/css/font-awesome.min.css";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import "../Menu.css";

import Carousel1 from "./carousel1";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Home = () => {
	return (
		<>
			<Navbar />

			<div style={{ backgroundColor: "#ffffff" }}>
				<div className="" style={{}}>
					<Homecarousel />
				</div>
				<div className="container">
					<div className="row pt-pb">
						<div className="col">
							<div className="">
								<h6 className="section1-tittle1">WHO WE ARE </h6>
								<h2 className="section1-tittle2">
									Enabling safe and sustainable food production through
									standards compliance
								</h2>{" "}
								<p className="section1-tittle3">
									The Global Academy on Food Safety and Sustainability Standards
									empowers stakeholders across the food supply chain through
									cutting-edge digital learning and innovative solutions. We
									bridge the gap between knowledge and practice, driving
									compliance with international standards to enhance
									productivity, increase incomes, foster sustainability, promote
									climate resilience, and unlock access to carbon markets.
								</p>
								<p className="section1-tittle3">
									Your Trusted Digital Partner in Advancing Global Food Safety
									and Sustainability Standards.
								</p>
							</div>
							<div className="section1-button">
								<a
									href="/keyobjectives"
									target="_self"
									rel="nofollow"
									className="link-anime v2">
									Read More{" "}
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="container page-gap justify-content-center">
					<div className="row">
						<div className="col-3"></div>

						<div className="col-3">
							<a href="./skilldevelopement">
								<div
									className=""
									style={{
										backgroundColor: "#BBE4A3",
										paddingBottom: "60px",
										paddingTop: "60px",
										display: "flex",
										justifyContent: "space-evenly",
										alignItems: "center",
										position: "relative",
									}}>
									<div
										className="section-iconsize"
										style={{
											display: "flex",
											position: "absolute",
											top: "-76px",
											padding: "50px 50px",
											background: "#2d947a",
											borderRadius: "10px",
										}}>
										<i style={{ color: "#ffffff" }} className="fa fa-users"></i>
									</div>
									<div
										className=""
										style={{ display: "flex", flexDirection: "column" }}>
										<span className="econamy-number">328</span>

										<p className="econamy-text" style={{}}>
											Skill Development
										</p>
									</div>
								</div>
							</a>
						</div>

						{/* <div className="col-3">
							<div
								className=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									className="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i
										style={{ color: "#ffffff" }}
										className="fa fa-graduation-cap"></i>
								</div>
								<div
									className=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Placement Opportunities
									</p>
								</div>
							</div>
						</div> */}
						<div className="col-3">
							<div
								className=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									className="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i
										style={{ color: "#ffffff" }}
										className="fa fa-folder-open"></i>
								</div>
								<div
									className=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Digital Library
									</p>
								</div>
							</div>
						</div>
						{/* <div className="col-3">
							<div
								className=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									className="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i style={{ color: "#ffffff" }} className="fa fa-handshake"></i>
								</div>
								<div
									className=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Client Review
									</p>
								</div>
							</div>
						</div> */}
					</div>
				</div>
				<div className="pt-pb " style={{ backgroundColor: "#BBE4A3" }}>
					<div className="row ">
						<div className="col section2-tittle-flex">
							<h2 className="section1-tittle2">What Global Academy does </h2>
							<p
								className="section1-tittle3"
								style={{ textAlign: "center", margin: "0px" }}>
								Bridging the gap between knowledge and practice and empowering
								farmers to <br /> reduce their carbon footprint by adhering to
								these standards.
							</p>
						</div>
					</div>
					<div
						className="row  section3-margin  fadeInUp-animation homecard-flex"
						style={{}}>
						<div className="col-4 buttonshow pd-col">
							<div
								className=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div className="section-iconsize">
										<i className="fa fa-book"></i>
									</div>
									<div className="">
										<div className="section3-text1">Courses</div>
										<p className="section3-text2">
											Skill Development Courses on Global Food Safety and
											Sustainability Standards to strengthen your expertise.
										</p>
									</div>
								</div>
								<div className="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/Coursesbanner.jpg"
											alt=""
										/>
									</div>
									<div
										className="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="./courses/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af"
											target=""
											rel=""
											className="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												className="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-4 buttonshow pd-col">
							<div
								className=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div className="section-iconsize">
										<i aria-hidden="true" className="fa fa-folder-open"></i>
									</div>
									<div className="">
										<div className="section3-text1">Digital Library</div>
										<p className="section3-text2">
											Collection of videos on good agricultural practices in the
											implementation of On Farm Production related Standards.
										</p>
									</div>
								</div>
								<div className="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/digitalbanner.jpg"
											alt=""
										/>
									</div>
									<div
										className="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af"
											target=""
											rel=""
											className="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												className="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-4 buttonshow pd-col">
							<div
								className=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div className="section-iconsize">
										<i aria-hidden="true" className="fa fa-users"></i>
									</div>
									<div className="">
										<div className="section3-text1">Discussion Board</div>
										<p className="section3-text2">
											Join the discussion Board to engage with peers and
											professionals in the food safety and sustainability
											sector.
										</p>
									</div>
								</div>
								<div className="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/discusionboardbanner.jpg"
											alt=""
										/>
									</div>
									<div
										className="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										{/* //------------------------------- */}
										<a
											href="/discussionboard"
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											target=""
											rel=""
											className="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												className="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-4 buttonshow pd-col">
							<div
								className=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div className="section-iconsize">
										<i className="fa fa-graduation-cap"></i>
									</div>
									<div className="">
										<div className="section3-text1">
											Placement Opportunities
										</div>
										<p className="section3-text2">
											Opportunities for for food safety professionals and
											graduates across the food supply chain.
										</p>
									</div>
								</div>
								<div className="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/jobsnewbanner.jpg"
											alt=""
										/>
									</div>
									<div
										className="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="/job-placement"
											target=""
											rel=""
											className="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												className="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-4 buttonshow pd-col">
							<div
								className=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div className="section-iconsize">
										<i className="fa fa-gears"></i>
									</div>
									<div className="">
										<div className="section3-text1">Regulatory Compliances</div>
										<p className="section3-text2">
											Ensuring compliance at every stage of the food chain is
											essential for food safety, quality, and market access.
										</p>
									</div>
								</div>
								<div className="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/compliancebanner.jpg"
											alt=""
										/>
									</div>
									<div
										className="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="./regulatorycompliances"
											target=""
											rel=""
											className="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												className="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container pt-pb1">
					<div>
						<h6 className="section1-tittle1">CLIENTS REVIEW </h6>
						<h2 className="section1-tittle2">
							Expert Guidance For Your <br /> Journey
						</h2>{" "}
					</div>
				</div>
				<div className="" style={{ paddingBottom: "100px" }}>
					<Carousel1 />
				</div>
			</div>
			<Footer />
		</>
	);
};
export default Home;
