import React, { useState, useEffect } from "react";

function Counter() {
  //   const [count, setCount] = useState(0);
  //   const digits = count.toString().split("");
  //  const digits = count.split("");

  //   useEffect(() => {
  //     const storedCount = localStorage.getItem("pageVisits");
  //     const initialCount = storedCount ? Number(storedCount) : 0;
  //     setCount(initialCount + 1);
  //     localStorage.setItem("pageVisits", initialCount + 1);
  //   }, []);

  return (
    <>
      <div className="row">
        <div className="" style={{ textAlign: "center" }}>
          Visitor Counter:
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <>
          <a href="https://www.hitwebcounter.com" target="_blank">
            <img
              src="https://hitwebcounter.com/counter/counter.php?page=20095542&style=0001&nbdigits=7&type=page&initCount=0"
              title="Counter Widget"
              Alt="Visit counter For Websites"
              border="0"
            />
          </a>
        </>
        {/* {digits.map((val) => (  */}
        {/* <div
          style={{
            fontSize: 42,

            background: "#006436",
            color: "white",
            padding: "0px 10px 0px 10px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {count}
        </div> */}
        {/* ))} */}
      </div>
    </>
  );
}
export default Counter;
