import React from "react";
import "../Menu.css";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const MohanMKulkarni = () => {
	return (
		<>
			<Navbar />
			<Homecarousel />
			<div className="row">
				<div className="col">
					<h2 className="headingp1sb">Mr. Mohan M. Kulkarni</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div>
						<img
							className="imagesb"
							src="../images/Mohan_M_Kulkarni.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="col-9">
					<div className="containerin2sb">
						<b>Present</b>
						<ul className="ulstyle">
							<li>
								Consulting and training on Quality and Environment and Energy
								Management Systems.
							</li>
						</ul>
						<b>Past</b>
						<ul className="ulstyle">
							<li>
								Audit and Consultancy in ISO-14001 and 45001, EnMs 50001, PAS99,
								ISO14064 (Carbon Footprint), ISO14046 (Water Footprint).
							</li>
							<li>
								{" "}
								Leading the AIM ENVIRO group, having foot hold in Energy,
								Environment, Occupational Health & Safety.
							</li>
							<li>
								{" "}
								Bio-Medical Waste Management and “Green Audit” A novel program
								for waste reduction.Ozone depletion and Climate Change (Global
								warming, CDM & Carbon Trading).
							</li>
							<li>
								EHS Legal compliance audit and training, Environmental liability
								assessment, Environmental Due diligence, ‘Environment Knowledge
								Centre’– Acknowledge based environmental social initiative.
							</li>
							<li>
								Over 30 years of industrial and consulting experience including
								reputed industrial organizations in various responsible
								capacities, viz. Kirloskar, Birla, Bosch.
							</li>
							<li>Working as Consultant in various fields for over 30years.</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col containerin">
					<b>Areas of Special Interest</b>
					<ul className="ulstyle">
						<li>
							Environment Impact Assessment studies & Environmental Risk
							Assessment Mathematical modeling & Computer simulation for
							Environmental Assessment.
						</li>
						<li>Design of Water, Air & Noise Pollution Control Systems.</li>
						<li>
							Reduction in Green House Gas emissions (including Energy & Natural
							resources conservation), Carbon Footprint, Water footprint, Carbon
							credits & Carbon trading.
						</li>
						<li>
							Reduction in Green House Gas emissions (including Energy & Natural
							resources conservation), Carbon Footprint, Water footprint, Carbon
							credits & Carbon trading.
						</li>
						<li>
							Contribution on White paper on Endocrine descriptors for EU
							legislations.
						</li>
						<li>
							Waste minimization (including Hazardous Waste Audit),Bio-medical
							waste & Municipal solid waste management and Application of
							Biotechnology.
						</li>
					</ul>
					<b>Professional Qualifications</b>

					<ul className="ulstyle">
						<li>Lead Auditor: ISO14001, OHSAS18001, EnMS, HACCP.</li>
					</ul>
					<b>Professional Memberships</b>
					<ul className="ulstyle">
						<li>Member, The Institution of Engineers (India).</li>
						<li>Fellow, Institution of Valuers.</li>
						<li>
							Founder Member, Practicing Valuers Association (India) Member,
							Indian Institute of Metals: M.I.I.M.
						</li>
						<li>
							Member, Indian Council of Arbitration :M.I.C.A. ;Panel Arbitrator.
						</li>
						<li>
							Member, Action Committee, Upper Godavari Global Water Partnership
						</li>
					</ul>
					<b>Educational Qualifications</b>
					<ul className="ulstyle">
						<li>
							B.E., M.M.S., C. Eng. (I), M.I.E., F.I.V., M.I..I.M., M.I.C.A.,
							S.L.A.
						</li>
					</ul>
					<b>ISO & Other Management Systems</b>
					<ul className="ulstyle"></ul>
					<b>Visiting Faculty/ Lectures</b>
					<ul className="ulstyle">
						<li>
							Visiting faculty to many educational, professional and training
							institutions viz;
						</li>
						<li>
							Various institutes of Engineering & Technology and Management,
							Symbiosis–SIOM and SCMHRD, Mahindra Institute of Quality (MIQ &
							MMDC), Maharashtra Jeevan Pradhikaran, Upper Godavari Water
							Partnership (Global Water Partnership),India Security Press.
						</li>
						<li>
							The Institution of Engineers, Industries & Manufacturers
							’Association (NIMA), The Indian Institute of Architects,
							Practicing Valuers Association (India), Institution of Valuers
						</li>
					</ul>
					<b>Propagation OF Innovative Concepts</b>
					<ul className="ulstyle">
						<li>
							ELV, WEEE & RoHS, REACH, Carbon foot print, Carbon neutrality,
							Water Footprint & Inventory of Green House Gases (GWP),
							Environmental Product Declaration (EPD).
						</li>
						<li>
							Green building: IGBC-LEED & GRIHA, Green concepts for
							developmental projects, Environmental Site Assessment, Due
							Diligence, Environmental liability assessment.
						</li>
						<li>
							‘HRTS’– High Rate Transpiration System for disposal of treated
							effluent ‘CTW’ Constructed Wet land and ‘Bio Filter’ for treatment
							of canteen/ domestic waste water.
						</li>
						<li>
							Water audit, Energy audit & Waste Audit, Zero waste to landfill,
							Industrial Ecology & Circular economy, Extended Producer
							responsibility (EPR), GRS, Ocean Bound Plastic (OBP).
						</li>
					</ul>
				</div>
			</div>
			<Footer />{" "}
		</>
	);
};
export default MohanMKulkarni;
